import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { keywords } from './keywords/keywords';
@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  keyword = '';
  data = keywords;
  result: any = [];
  services = [
    '/service-aesthetic',
    '/service-doctor-home-visit',
    '/service-general',
    '/service-health-screening',
    '/service-sexual-health',
    '/service-slimming',
    '/service-urinary-incontinence',
    '/service-wellness',
  ]

  constructor(private appService: AppService) {
    this.appService.keyword.subscribe((keyword) => {
      if(keyword) {
        this.searchKeyword(keyword);
        this.keyword = keyword;
      }
    })
  }

  ngOnInit(): void {
  }

  searchKeyword(search: string) {
    this.result = [];
    
    for(let i=0; i<this.data.length; i++) {
      for (let j=0; j<this.data[i].keywords.length; j++) {

        if((this.data[i].keywords[j].toLowerCase()).includes(search.toLowerCase())) {
          this.result.push({
            url: this.data[i].url,
            page: this.data[i].page,
            keywords: this.data[i].keywords[j],
            date: this.data[i].date
          })
        }

      }
    }

    console.log(this.result);
  }
}
