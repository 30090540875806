<div class="wrapper-services">
    <header class="py-5 inner-header" style="background:url('../../../assets/images/services.png')no-repeat center center;">
        <div class="header-overlay"></div>
        <div class="container py-4 px-5">
           <div class="row align-items-center justify-content-center">
              <div class="col-lg-12">
                 <div class="text-center">
                    <h1 class="fw_700 text-white">Wellness</h1>
                    <p class="fw-normal text-white-50 mb-0"><a class="text-white" href="index.html">Home</a> / <a class="text-white">Services</a> / Wellness</p>
                 </div>
              </div>
           </div>
        </div>
      </header>
      <div class="history-section bg-gray py-5">
        <div class="container p-5">
            <div class="row">
                <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/wellness.jpg" >
                </div>
                <div class="col-md-6">
                    <div class="pl-5">
                        <p class="text-uppercase text_blue_1 mb-2">Our Services</p>
                        <h1 class="fw-700 mb-3 text-black">Wellness</h1>
                        <p class="fs-6 fw-light text-muted mb-0 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><br>
                        
                      </div>
                 </div>
                </div>
            </div>
        </div>
        
        
        <div class="section-services py-5">
            <div class="container p-5">
                <div class="title-area">
                    
                    <h1 class="title fw_600 text-dark mt-3">
                        Cellular Therapy
                    </h1>
                    <h6 class="fw_400 mt-3 desc">
                        Cellular Health is a concept that explains how your overall health is dependent on the cells in our body and how they are functioning. This includes ensuring cells get adequate nutrients, are protected from external stressors and are functioning to their fullest potential. 
                    </h6>
                    <h6 class="fw_400 mt-3 desc">
                        As you age, your CELLS AGE too!
                    </h6>
                    <h6 class="fw_400 mt-3 desc">
                        Over time our cells become more fragile and don’t work as efficiently. This breakdown in our cells causes aging, and this process can leave us feeling tired or like we’re not quite at our best.
                    </h6>
                    
                
                    <p class="text-uppercase text_blue_1 mb-2">Cellular therapy with</p>
                    <h4 class="title fw_600 text-dark mt-3">
                        Masenchymal Stem cells (MSCs)
                    </h4>
                    <h6 class="fw_400 mt-3 desc">
                        A form of regenerative medicine designed to repair damaged cells within the body. Umbilical cord tissue is a popular source of MSCs for regenerative and anti-aging therapy. Mesenchymal stem cells do this by influencing tissue repair via paracrine effects (cell signaling in order to change the behaviour of existing cells) or direct cell-to-cell contact.
                    </h6>
                
                    <p class="text-uppercase text_blue_1 mb-2">Studies have shown the POWER of MSCs:</p>
                    
                    <h6 class="fw_400 mt-3 desc">
                        <ul>
                            <li>Regenerate damaged/diseased tissues. </li>
                            <li>Reduce inflammation </li>
                            <li>Modulate the immune system </li>
                            <li>Promoting better health & quality of life</li>
                        </ul>
                    </h6>
                
                    <h4 class="title fw_600 text-dark mt-3">
                        Stem cells as the “<span class="font-light-green">Future of modern medicine</span>.”
                    </h4>
                    
                    <h6 class="fw_400 mt-3 desc">
                        Stem cells have the ability to differentiate into specific cell types that can be helpful in various pain management conditions as well as other medical conditions.
                    </h6>
                
                    <p class="text-uppercase text_blue_1 mb-2">Key Benefits of MSCs</p>
                    
                    <h6 class="fw_400 mt-3 desc">
                        <ul>
                            <li>Cartilage regeneration</li>
                            <li>Less bone and joint pain</li>
                            <li>Improves organ's function</li>
                            <li>Better energy and stamina</li>
                            <li>Healthier looking skin and hair</li>
                            <li>Helps heal wounds and incisions</li>
                            <li>Anti-aging</li>
                            <li>Improves overal quality of life</li>
                        </ul>
                    </h6>
                
                    <p class="text-uppercase text_blue_1 mb-2">How Is It Done?</p>
                    
                    <h6 class="fw_400 mt-3 desc">
                        <ul>
                            <li>Local administration via INJECTION</li>
                            <li>Intravenous Drip</li>
                        </ul>
                    </h6>
                </div>
            </div>
        </div>

        <div class="line"></div>
        <!--Drip Therapy-->
        <div class="section-services">
            <div class="container p-5">
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="title fw_600 text-dark">
                            Revive Intravenous Drip (IV) Therapy
                        </h1>
                        
                        <h6 class="fw_400 mt-3 desc text-justify">
                            Revive IV Therapy provides essential nutrients, vitamins, and electrolytes directly into your bloodstream, unlike traditional oral supplements that can have lower bioavailability rates. Additionally, vitamins B and C are water-soluble, making them easily excreted from the body through urine. In contrast, IV Revitalise boasts a complete bioavailability rate of 100%, bypassing the digestive system and allowing for maximum nutrient absorption. This efficient delivery system allows for rapid results, particularly in terms of energizing, improving your immune system, and achieving healthy, glowing skin. Whether you're looking to combat fatigue, recover from an illness, or simply optimize your overall well-being, IV Revitalise may be the perfect solution for you.
                        </h6>
                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid rounded mb-5 mb-lg-0 pl-5" src="../../../assets/images/service/drip-therapy.png" >
                        <div class="title-area mt-5 pl-5">
                    
                            <h6 class="fw_600 mt-3 desc">Trovite</h6>
                            <ul class="desc">
                                <li>Boost energy production </li>
                                <li>Boosts immune system </li>
                                <li>Reduce oxidative stress </li>
                                <li>Liver detoxification</li>
                                <li>Prevent chronic disease and heart disease </li>
                                <li>Improve memory </li>
                                <li>Reduce inflammation </li>
                                <li>Improve skin condition </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="line"></div>
        <!--Diet and Consultation-->
        <div class="section-services">
            <div class="container p-5">
                <div class="title-area mt-5">
                    <h1 class="title fw_600 text-dark mt-3">
                        Nutrition & Diet Consultation
                    </h1>
                    
                    <h6 class="fw_400 mt-3 desc">
                        Nadi's dietitian provides personalized nutrition and diet consultations to help clients achieve their health and wellness goals. The consultations typically involve:
                    </h6>
                    <ul class="desc">
                        <li>A thorough assessment of the client's health and medical history, lifestyle, and dietary habits.</li>
                        <li>Identification of nutritional deficiencies or imbalances, and recommendations for appropriate dietary modifications or supplements.</li>
                        <li>Development of personalized meal plans and dietary recommendations based on the client's goals and needs.</li>
                        <li>Ongoing support and follow-up to monitor progress and adjust the plan as needed.</li>
                    </ul>
                    <h6 class="fw_400 mt-3 desc">
                        Nadi's dietitian may also provide education on healthy eating habits, food choices, and meal preparation techniques to help clients make sustainable lifestyle changes. Whether the client's goal is weight loss, improved gut health, managing a specific medical condition, or simply improving overall health and well-being, Nadi's dietitian works with each client to develop a customized plan that is tailored to your unique needs and preferences.
                    </h6>
                </div>



            </div>

        </div>
   
</div>
