<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">STI Screening & Treatment</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / STI Screening & Treatment
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
      <div class="row h-100 d-flex align-items-center">
        <div class="col-md-6 info">
          <div class="">
            <h1 class="fw-700 mt-5">
              Protect Yourself And <br> Your Loved Ones
            </h1>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/sti-screening/sti-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Protect Yourself And Your Loved Ones
      </h1>
    </div>
  </div>

  <div class="section-info container py-5">
    <h4 class="text-center">What is</h4>
    <h1 class="text-center -mt-5">Sexually Transmitted Infections (STIs)</h1>
    <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
      Sexually Transmitted Infections (STIs) are a group of infections that are spread from person to person through sexual contact. These infections can be caused by bacteria, viruses, or parasites and can be transmitted through sexual activity. <br><br>
      We provide a comprehensive range of services to support your sexual health needs through screening and prevention to HIV.
    </p>
  </div>

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-area pb-5">
        <h2>STIs Screening Options:</h2>
      </div>

      <div class="honeycomb">
        <div class="ibws-fix">
          <div class="hexagon comb-green">
            <div class="hexagontent">Chlamydia</div>
          </div>
          <div class="hexagon comb-gray">
            <div class="hexagontent">Gonorrhea</div>
          </div>
          <div class="hexagon comb-green">
            <div class="hexagontent">HIV</div>
          </div>
          <div class="hexagon comb-blue">
            <div class="hexagontent">Hepatitis B and C</div>
          </div>
          <div class="hexagon comb-yellow">
            <div class="hexagontent">Herpes</div>
          </div>
        </div>
        <div class="ibws-fix">
          <div class="hexagon comb-blue">
            <div class="hexagontent">Syphilis</div>
          </div>
          <div class="hexagon comb-gray invisible">
            <div class="hexagontent"></div>
          </div>
          <div class="hexagon comb-yellow">
            <div class="hexagontent">Human Papillomavirus (HPV)</div>
          </div>
          <div class="hexagon comb-blue invisible">
            <div class="hexagontent"></div>
          </div>
          <div class="hexagon comb-green">
            <div class="hexagontent">Trichomoniasis</div>
          </div>
        </div>
      </div>

      <p class="info-text">
        Screening methods may include blood tests, urine tests, and swab tests, depending on the type of infection.
      </p>

      <div class="button-action text-center mt-5">
        <a class="btn btn-primary px-3 py-2" href="https://wa.me/+60126468392" target="_blank"> 
          <i class="bi bi-calendar3 mr-1"></i> Book Appoinment
        </a>
      </div>

      <p class="info-text mt-3 col-6 col-lg-4 mx-auto">
        Kindly schedule an appointment with our doctor for Screening & Treatment.
      </p>

    </div>
  </div>

  <div class="section-prevent py-5 px-3 my-5 container">
    <h3 class="title text-center">We have two preventive strategies used to reduce the risk of HIV transmission.</h3>

    <div class="why-choose-doctor row px-3 pt-3">
      <div class="col-12 col-md-6 col-lg-6 p-0 mt-4">
        <div class="card card-half card-odd">
          <div class="number-circle number-green">
            <div class="inner">1</div>
          </div>
          <div class="content">
            <div>
              <h5>Pre-Exposure Prophylaxis (PrEP)</h5>
              <h6>PrEP involves taking antiretroviral medications on a daily basis to prevent HIV infection in individuals who are at high risk <b>BEFORE</b> unprotected sex or needle sharing.</h6>
            </div>
          </div>
          <img class="green-bottom" src="../../../assets/images/sti-screening/green-half.png" alt="">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 p-0 mt-4">
        <div class="card card-half card-even">
          <div class="number-circle number-blue">
            <div class="inner">2</div>
          </div>
          <div class="content">
            <div>
              <h5>Post Exposure Prophylaxis (PEP)</h5>
              <h6>PEP involves taking antiretroviral medications <b>AFTER</b> a potential exposure to HIV, such as unprotected sex or needle sharing (within 72 hours, preferably within 2 hours).</h6>
            </div>
          </div>
          <img class="blue-top" src="../../../assets/images/sti-screening/blue-half.png" alt="">
        </div>
      </div>
    </div>

    <div class="text-block col-12 col-lg-8 mx-auto mt-5 pt-3">
      <div class="block my-3">Works by blocking the virus, reducing the likelihood of an individual becoming infected with HIV</div>
      <div class="block my-3">Aims to reduce the risk of HIV infection</div>
      <div class="block my-3">Suitable for individuals engaging in unprotected sex, sharing needles, having multiple partners, or in a sexual relationship with a HIV-positive partner.</div>
    </div>
  </div>

</div>