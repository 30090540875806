<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Head-to-Toe Contouring</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Head-to-Toe Contouring
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

	<div class="history-section pb-5">
		<div class="container p-5">
			<div class="d-flex justify-content-center">
				<img
					class="exilis-logo"
					src="../../../../assets/images/exilis-ultra-360-logo.png"
				/>
			</div>

			<div class="section-banner mt-5">
				<div class="row d-flex justify-content-center">
					<div class="col-12 col-md-6 col-lg-4 p-1">
						<div class="item">
							<img src="../../../../assets/images/exilis/lifting.png" alt="">
							<h2 class="text-center">LIFTING</h2>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4 p-1">
						<div class="item up">
							<img src="../../../../assets/images/exilis/firming.png" alt="">
							<h2 class="text-center">FIRMING</h2>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4 p-1">
						<div class="item">
							<img src="../../../../assets/images/exilis/toning.png" alt="">
							<h2 class="text-center">TONING</h2>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-10 mx-auto mt-5">
				<p class="fs-6 fw-light mb-0 text-justify desc text-center">
					Exilis Ultra 360 uses <b>radiofrequency (RF) technology</b> to deliver
					controlled heat to the skin. This heat helps to stimulate collagen
					production and improve the appearance of the skin. The device uses
					a combination of monopolar and bipolar RF energy to target
					different depths of the skin and achieve optimal results.
					<br /><br />
					The Exilis Ultra 360 device also features a unique handpiece that
					can be rotated 360 degrees, allowing for the treatment of larger
					areas of the body with more precision and comfort. This technology
					is designed to safely and effectively improve the appearance of
					fine lines and wrinkles, tighten loose skin, and reduce the
					appearance of cellulite.
				</p>
			</div>
		</div>
	</div>


	<div class="section-exilis">

		<div class="a-logo-left">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>
    <div class="a-logo-right">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>
		<div class="a-logo-bottom">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>

		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6">
					<img class="exilis-image" src="../../../../assets/images/exilis/exilis-ultra.png" alt="">
				</div>
				<div class="col-12 col-lg-6 mt-4">
					<div class="face-and-body">
						<h3 class="title text-center">For Face</h3>
						<div class="row">
							<div class="col-12 col-md-4 col-lg-4">
								<img src="../../../../assets/images/exilis/face-before.svg" alt="">
								<h6 class="text-center mt-3">Before</h6>
							</div>
							<div class="col-12 col-md-4 col-lg-4">
								<img src="../../../../assets/images/exilis/face-during.svg" alt="">
								<h6 class="text-center mt-3">During</h6>
							</div>
							<div class="col-12 col-md-4 col-lg-4">
								<img src="../../../../assets/images/exilis/face-after.svg" alt="">
								<h6 class="text-center mt-3">After</h6>
							</div>
						</div>
					</div>
					<div class="face-and-body">
						<h3 class="title text-center">For Body</h3>
						<div class="row">
							<div class="col-12 col-md-4 col-lg-4">
								<img src="../../../../assets/images/exilis/body-before.svg" alt="">
								<h6 class="text-center mt-3">Before</h6>
							</div>
							<div class="col-12 col-md-4 col-lg-4">
								<img src="../../../../assets/images/exilis/body-during.svg" alt="">
								<h6 class="text-center mt-3">During</h6>
							</div>
							<div class="col-12 col-md-4 col-lg-4">
								<img src="../../../../assets/images/exilis/body-after.svg" alt="">
								<h6 class="text-center mt-3">After</h6>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-10 mx-auto mt-3 pt-5 pb-2">
				<p class="text-center">
					In addition to RF energy, the Exilis Ultra 360 device also uses 
					<b>ultrasound technology</b> to help deliver heat more deeply into the skin. 
					It provides dermal heating to induce <b>COLLAGEN SYNTHESIS</b> and <b>ELASTIN REMODELLING</b>. 
					This combination of RF and ultrasound energy helps to achieve more comprehensive 
					and effective results.
				</p>
			</div>

			<div class="mb-5 py-5">
				<div class="video-container">
					<iframe src="https://www.youtube.com/embed/Q9qkUJLU0gQ"></iframe>
				</div>
			</div>

		</div>
	</div>

  <div class="section-help py-5">
		<img class="green-1" src="../../../assets/images/green-gradient.png" alt="">
		<div class="container">
			<img src="../../../../assets/images/exilis/astonishing-logo.png" alt="" class="d-flex mx-auto astonishing-logo">
			<h1 class="text-center mt-1">
				Exilis Ultra 360 treatment <br> restores youth from head-to-toe.
			</h1>
			<img class="my-3" src="../../../../assets/images/exilis/girl.svg" alt="">
		</div>
  </div>

	<div class="section-help container pt-5 pb-3">
    <h4 class="text-center">We Want to</h4>
    <h1 class="text-center -mt-5">Eliminate Every Single One of These!</h1>
  </div>

	<div class="section-eliminate pb-5">
		<div class="a-logo-left">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>
		<div class="container row mx-auto">
			<div class="col-12 col-md-6 col-lg-4 mt-5">
				<div class="card card-round">
					<img class="card-image" src="../../../../assets/images/exilis/eliminate-1.png" alt="">
					<h6 class="card-title">Fine lines and wrinkles</h6>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4 mt-5">
				<div class="card card-round">
					<img class="card-image" src="../../../../assets/images/exilis/eliminate-2.png" alt="">
					<h6 class="card-title">Loose skin</h6>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4 mt-5">
				<div class="card card-round">
					<img class="card-image" src="../../../../assets/images/exilis/eliminate-3.png" alt="">
					<h6 class="card-title">Cellulite</h6>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4 mt-5">
				<div class="card card-round">
					<img class="card-image" src="../../../../assets/images/exilis/eliminate-4.png" alt="">
					<h6 class="card-title">Sagging skin</h6>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4 mt-5">
				<div class="card card-round">
					<img class="card-image" src="../../../../assets/images/exilis/eliminate-5.png" alt="">
					<h6 class="card-title">Body contouring</h6>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4 mt-5">
				<div class="card card-round">
					<img class="card-image" src="../../../../assets/images/exilis/eliminate-6.png" alt="">
					<h6 class="card-title">Labia rejuvenation</h6>
				</div>
			</div>
		</div>
	</div>

	<div class="section-services py-5">
		<div class="container text-center">
			<div class="title-area pb-3">
				<h4>Why Choose</h4>
				<div class="d-flex justify-content-center">
					<img
						class="exilis-logo"
						src="../../../../assets/images/exilis-ultra-360-logo.png"
						style="margin-top: -20px;"
					/>
				</div>
				<p class="col-12 col-lg-8 mx-auto mt-3">
					It is the <b>FIRST</b> and <b>ONLY</b> device to simultaneously combine radio 
					frequency & ultrasound to tighten skin and address body concerns 
					from head to toe.
				</p>
			</div>
			<div class="row mt-4">
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/ultra-comfort-icon.png">
						</div>
						<h6 class="text-center mt-3">Ultra comfort</h6>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/treats-area-logo.png">
						</div>
						<h6 class="text-center mt-3">Treats multiple areas from head to toe</h6>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/non-invasive-icon.png">
						</div>
						<h6 class="text-center mt-3">Non invasive & no downtime</h6>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/ultra-safe-icon.png">
						</div>
						<h6 class="text-center mt-3">Ultra safe</h6>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-help container pt-5 pb-3">
		<h1 class="text-center">Customer Testimonials</h1>
	</div>
	<div class="section-testimonial py-5 mb-5">
		<div class="a-logo-left">
      <img src="../../../../assets/images/exilis/a-logo-gray.png" alt="">
    </div>
		<div class="a-logo-right">
      <img src="../../../../assets/images/exilis/a-logo-gray.png" alt="">
    </div>

		<div class="container">
			<div class="testimonials-slider">
				<div class="item"> 
					<h1 class="text-center mb-3">Face</h1>
					<div class="row">
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-face-1.png" alt="">
						</div>
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-face-2.png" alt="">
						</div>
					</div>
				</div>
				<div class="item"> 
					<h1 class="text-center mb-3">Abdomen</h1>
					<div class="row">
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-abdomen-1.png" alt="">
						</div>
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-abdomen-2.png" alt="">
						</div>
					</div>
				</div>
				<div class="item"> 
					<h1 class="text-center mb-3">Arm</h1>
					<div class="row">
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-arm-1.png" alt="">
						</div>
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-arm-2.png" alt="">
						</div>
					</div>
				</div>
				<div class="item"> 
					<h1 class="text-center mb-3">Back</h1>
					<div class="row">
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-back-1.png" alt="">
						</div>
						<div class="col-12 col-lg-6">
							<img src="../../../../assets/images/testimonial-back-2.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="section-services py-5">
		<div class="container text-center">
			<div class="title-area pb-5">
				<h2>For Face</h2>
			</div>
			<div class="row">
				<div class="col-12 col-md-4 mb-4">
					<div class="card-item">
						<div class="image">
							<img class="w-100" src="../../../../assets/images/before-exilis-face.png">
						</div>
						<h6 class="text-center mt-3">Before</h6>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-4">
					<div class="card-item">
						<div class="image">
							<img class="w-100" src="../../../../assets/images/during-exilis-face.png">
						</div>
						<h6 class="text-center mt-3">During</h6>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-4">
					<div class="card-item">
						<div class="image">
							<img class="w-100" src="../../../../assets/images/after-exilis-face.png">
						</div>
						<h6 class="text-center mt-3">After</h6>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-services py-5">
		<div class="container text-center">
			<div class="title-area pb-5">
				<h2>For Body</h2>
			</div>
			<div class="row">
				<div class="col-12 col-md-4 mb-4">
					<div class="card-item">
						<div class="image">
							<img class="w-100" src="../../../../assets/images/before-exilis-body.png">
						</div>
						<h6 class="text-center mt-3">Before</h6>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-4">
					<div class="card-item">
						<div class="image">
							<img class="w-100" src="../../../../assets/images/during-exilis-body.png">
						</div>
						<h6 class="text-center mt-3">During</h6>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-4">
					<div class="card-item">
						<div class="image">
							<img class="w-100" src="../../../../assets/images/after-exilis-body.png">
						</div>
						<h6 class="text-center mt-3">After</h6>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="container py-5">
		<p class="text-center">
			In addition to RF energy, the Exilis Ultra 360 device also uses <b>ultrasound technology</b> to help deliver heat more deeply into the skin. This combination of RF and ultrasound energy helps to achieve more comprehensive and effective results.
		</p>
	</div>

	<div class="container py-5">
		<div class="video-container nadi-border border">
			<iframe src="https://www.youtube.com/embed/Q9qkUJLU0gQ"></iframe>
		</div>
	</div>

	<div class="section-services py-5">
		<div class="container text-center">
			<p class="fs-6 fw-light text-center text-muted mb-0 text-justify desc">
				Exilis Ultra 360 treatment restores youth from head-to-toe.
			</p>
			<img
				class="col-12 col-md-6 d-flex mx-auto mt-4"
				src="../../../../assets/images/exilis-astonishing-logo.png"
			/>
		</div>
	</div> -->

	<!-- <div class="section-services py-5">
		<div class="container text-center">
			<div class="title-area pb-3">
				<h2>We Want to Eliminate Every Single One of These!</h2>
			</div>
			<div class="col-12 col-lg-6 mx-auto">
				<div class="card card-info text-left mb-3">
					Fine lines and wrinkles
				</div>
				<div class="card card-info text-left mb-3">
					Loose skin
				</div>
				<div class="card card-info text-left mb-3">
					Cellulite
				</div>
				<div class="card card-info text-left mb-3">
					Sagging skin
				</div>
				<div class="card card-info text-left mb-3">
					Body contouring
				</div>
				<div class="card card-info text-left mb-3">
					Labia rejuvenation
				</div>
			</div>
		</div>
	</div>

	<div class="section-services py-5">
		<div class="container text-center">
			<div class="title-area pb-3">
				<h2>Why Choose Exilis Ultra 360?</h2>
				<p class="col-12 col-lg-8 mx-auto mt-3">
					It is the <b>FIRST</b> and <b>ONLY</b> device to simultaneously combine radio 
					frequency & ultrasound to tighten skin and address body concerns 
					from head to toe.
				</p>
			</div>
			<div class="row mt-4">
				<div class="col-12 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/ultra-comfort-icon.png">
						</div>
						<h6 class="text-center mt-3">Ultra comfort</h6>
					</div>
				</div>
				<div class="col-12 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/treats-area-logo.png">
						</div>
						<h6 class="text-center mt-3">Treats multiple areas from head to toe</h6>
					</div>
				</div>
				<div class="col-12 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/non-invasive-icon.png">
						</div>
						<h6 class="text-center mt-3">Non invasive & no downtime</h6>
					</div>
				</div>
				<div class="col-12 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../../assets/images/ultra-safe-icon.png">
						</div>
						<h6 class="text-center mt-3">Ultra safe</h6>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-services py-5">
		<div class="container text-center">
			<div class="title-area">
				<h2>Customer Testimonials</h2>
			</div>

			<div class="title-area pb-3 mt-5">
				<h2>Face</h2>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-face-1.png">	
				</div>
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-face-2.png">
				</div>
			</div>

			<div class="title-area pb-3 mt-5">
				<h2>Arm</h2>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-arm-1.png">	
				</div>
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-arm-2.png">
				</div>
			</div>

			<div class="title-area pb-3 mt-5">
				<h2>Abdomen</h2>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-abdomen-1.png">	
				</div>
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-abdomen-2.png">
				</div>
			</div>

			<div class="title-area pb-3 mt-5">
				<h2>Back</h2>
			</div>
			<div class="row">
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-back-1.png">	
				</div>
				<div class="col-12 col-md-6 mb-4">
					<img class="w-100" src="../../../../assets/images/testimonial-back-2.png">
				</div>
			</div>

		</div>
	</div> -->

</div>
