<div class="wrapper-about">
    <header class="py-0 inner-header">
        <div class="header-overlay"></div>
        <div class="container py-4 px-5">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                    <div class="text-center">
                        <h1 class="fw_500">General Medical Consultation</h1>
                        <p class="fw-normal"><a href="index.html">Services</a> /
                            General Medical Consultation</p>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="section-main mx-3 mt-5 hide-sm">
        <div class="px-5 mb-4 h-100">
            <div class="row h-100 d-flex align-items-center">
                <div class="col-md-6 info">
                    <div class="">
                        <h1 class="fw-700 mt-5">
                            Your Trusted <br>
                            Health Parter In <br>
                            Delivering <br>
                            Comprehensive <br>
                            Care
                        </h1>
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
                </div>
            </div>
        </div>
    </div>

    <div class="banner-top-mobile mx-3 mt-5">
        <img class="w-100" src="../../../assets/images/general-medical-banner.png" alt="">
        <div class="mt-4">
            <h1 class="fw-700">
                Your Trusted Health Parter In
                Delivering Comprehensive Care
            </h1>
        </div>
    </div>

    <!-- <div class="history-section  py-5">
        <div class="container p-5">
            <div class="row">
                <div class="col-md-6">
                    <img class="img-fluid  mb-5 mb-lg-0 nadi-border" src="../../../assets/images/service/service-01.jpeg">
                </div>
                <div class="col-md-6">
                    <div class="pl-5">
                        <h1 class="fw-700 mb-3 text-black">General Medical Consultation</h1>
                        <p class="fs-6 fw-light text-muted mb-0 text-justify desc">Our experienced medical professionals
                            will listen attentively to your needs, conduct a thorough assessment, and provide
                            individualised treatments and recommendations. We try our best to treat the root cause of a
                            condition rather than treating only the symptoms.</p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="section-services py-5">

        <img class="green-1" src="../../../assets/images/green-gradient.png" alt="">
        <img class="green-2" src="../../../assets/images/green-gradient.png" alt="">
        <img class="green-3" src="../../../assets/images/green-gradient.png" alt="">

        <div class="container text-center">
            <div class="title-area pb-5">
                <h2>Services available</h2>
            </div>
            <div class="row">
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-1.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Diagnosis and treatment of common illnesses and conditions.</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-2.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Management of chronic diseases (diabetes, high blood pressure, high cholesterol etc).</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-3.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Routine health screenings.</p>
                        </div>
                    </div>

                </div>
                <!-- <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-4.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Immunizations and vaccinations.</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-5.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Review medications.</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-6.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Referral to specialists for more complex conditions.</p>
                        </div>
                    </div>

                </div> -->
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-7.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Health and wellness education and counseling.</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-8.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Wound treatment of minor injuries.</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-9.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Ultrasound (general & antenatal)</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-10.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Sexually Transmitted Infections (STIs).</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-11.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Minor surgical procedures</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 my-4">
                    <div class="card nadi-border">
                        <div class="card-img">
                            <img class="card-img-top" src="../../../assets/images/service-general/service-general-12.png">
                        </div>
                        <div class="card-body">
                            <p class="mb-0 desc">Assessing and managing allergies and respiratory conditions (asthma)</p>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>
</div>