import { Component } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AppointmentComponent } from './pages/appointment/appointment.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nadi-website';

  closeResult = '';

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }



    openModal() {
      const modalRef = this.modalService.open(AppointmentComponent,{
        ariaLabelledBy: 'modal-basic-title',
        size: 'none',
        windowClass: 'modal-customer',
        centered: true});
      modalRef.componentInstance.id = 10;
      modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
}