import { Component, OnInit } from '@angular/core';
declare var $: any;
import { doctorData } from '../doctor-info/doctor';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  images = [
    { img: "../../../assets/images/home/slider-1.png" },
    { img: "../../../assets/images/home/slider-2.png" }
  ];

  prefixs = ['+66','+60','+61','+62']
  selectedPrefix = '+60';

  isMobile = false;
  // img: any;
  // name: any;
  // title: any;
  // desc: any;
  // biography: any;
  img = '../../../assets/images/home/Dr-Amos.png'
  name = 'Dr Amos Ng';
  title = 'Chief Medical Doctor';
  desc = 'Doctor of Medicine First Moscow State University';
  biography = 'Dr Amos graduate from First Moscow State Medical University in degree of Medical Doctor. He did his internship in Hospital Melaka with his last department of Anaesthesia and Intensive Care Unit. Then he served his compulsory service in Hospital Tengku Ampuan Rahimah and Hospital Sungai Buloh.During his service in Internal Medicine and Rehabilitation Medicine, he observed how important to address health condition, diseases and help to reduce or prevent complication.Dr Amos believes healing is not an overnight process, it is a daily cleansing of pain. He is more to happy to share his experience with patients and also able to converse in Bahasa Malaysia, English and Chinese dialects.';

  sortOptions = doctorData;

  selectedTeamName = "Dr Gopee";
  selectedTeamJob = "Chief Operating Officer";
  selectedTeamDescription = "Chief Operating Officer";
  selectedImage = "../../../assets/images/new/team/dr-gopee.png"

  isLeftArrow = false;
 
  constructor() { }

  ngOnInit(): void {
    $(".carouselTicker").carouselTicker({
      speed: 1,
      delay: 30,
      mode:"horizontal",
    });

    setTimeout( ()=>{
      
      this.initTeamSlider();
    }, 1000);
   
    this.initClientSlider();
    this.initHeroSlider();
    this.initCareSlider();
    this.initPersonalisedSlider();

    sessionStorage.setItem('name', this.name );
    sessionStorage.setItem('title', this.title );
    sessionStorage.setItem('desc', this.desc );
    sessionStorage.setItem('biography', this.biography );
  }
  initHeroSlider() {
    $('.slider-box').slick({
      infinite: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $('.prev-0'),
      nextArrow: $('.next-0'),
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 1500
    });
  }
  initCareSlider() {
    $('.service-links').slick({
      infinite: false,
      dots: false,
      slidesToShow: 6,
      slidesToScroll: 3,
      prevArrow: $('.green-arrow-left'),
      nextArrow: $('.green-arrow-right'),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 1
          }
        },
      ]
    });
  }
  initPersonalisedSlider() {
    $('.personalised-slider').slick({
      infinite: false,
      dots: false,
      slidesToShow: 2.2,
      slidesToScroll: 1,
      prevArrow: $('.prev-0'),
      nextArrow: $('.next-0'),
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 1500,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1
          }
        },
      ]
    });
  }
  initClientSlider() {
    $('.testimonials-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }
  initTeamSlider() {
    $('.doctor-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.prev-doctor'),
      nextArrow: $('.next-doctor'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }


  // initMainSlider() {
  //   $('.slider-box').slick({
  //     infinite: false,
  //     dots: false,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     prevArrow: $('.prev-0'),
  //     nextArrow: $('.next-0'),
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 1,
  //           infinite: false,
  //           dots: true,
  //           centerPadding: '8%',
  //         }
  //       },
  //       {
  //         breakpoint: 740,
  //         settings: {
  //           slidesToShow: 1.2,
  //           slidesToScroll: 1,
  //           infinite: false,
  //         }
  //       }
  //     ]
  //   });
  // }


  choosePrefix(prefix: any) {
    this.selectedPrefix = prefix;
  }

  changeTeam(teamImg: any, teamName: any, teamTitle: any, teamDesc: any, teamBiography: any){
    this.img = teamImg;
    this.name = teamName;
    this.title = teamTitle;
    this.desc = teamDesc;
    this.biography = teamBiography;
    sessionStorage.setItem('img', this.img );
    sessionStorage.setItem('name', this.name );
    sessionStorage.setItem('title', this.title );
    sessionStorage.setItem('desc', this.desc );
    sessionStorage.setItem('biography', this.biography );
  }

  setDoctorData(i: any) {
    sessionStorage.setItem('img', this.sortOptions[i].image);
    sessionStorage.setItem('name', this.sortOptions[i].name);
    sessionStorage.setItem('title', this.sortOptions[i].title);
    sessionStorage.setItem('desc', this.sortOptions[i].desc);
    sessionStorage.setItem('biography', this.sortOptions[i].biography);
  }

  chooseTeam(name: string, job: string, description: string, image: string) {
    this.selectedTeamName = name;
    this.selectedTeamJob = job;
    this.selectedTeamDescription = description;
    this.selectedImage = image;
  }

  onRightArrow() {
    this.isLeftArrow = true;
  }
  onLeftArrow() {
    // this.isLeftArrow = false;
  }
}

