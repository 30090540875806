<div class="wrapper-health-screening">
    <header class="py-5 inner-header" style="background:url('../../../assets/images/clinic.png')no-repeat center center;">
      <div class="header-overlay"></div>
      <div class="container py-4 px-5">
         <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
               <div class="text-center">
                  <h1 class="fw_700 text-white">Aesthetic Services</h1>
                  <p class="fw-normal text-white-50 mb-0"><a class="text-white" href="index.html">Services</a> / Aesthetic Services</p>
               </div>
            </div>
         </div>
      </div>
    </header>
    <div class="section-about px-50 py-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 aling-self-center">
              <div class="title-area">
                <!-- <p class="text_blue_1">About US</p> -->
                <h1 class="text-dark fw_600 title mb-4">Platelet-rich plasma (PRP) Therapy</h1>
                <h6 class="mt-2 desc text-justify">PRP therapy is a non-surgical aesthetic procedure that utilizes the client's own blood to promote healing and rejuvenation of the skin.</h6>
                <!-- <h5 _ngcontent-xir-c106="" class="fw-600">PRP Procedures</h5>
                <ol class="bullet-green fs-6 fw-light mb-0 pl-3">
                    <li>Drawing a small amount of the client's blood.</li>
                    <li>Separating the plasma from the red and white blood cells through centrifugation.</li>
                    <li>The concentrated plasma (rich in growth factors and platelets) is then injected into the target areas.</li>
                </ol>
                <h5 _ngcontent-xir-c106="" class="fw-600">Who Needs PRP Therapy?</h5>
                <h6 class="mt-2 desc text-justify">Individuals with:</h6>
                <ul class="bullet-green fs-6 fw-light mb-0 pl-3">
                    <li>hair loss or thinning hair</li>
                    <li>wrinkles, fine lines, and aging skin</li>
                    <li>acne scars or other facial scars</li>
                    <li>dark circles or under-eye bags</li>
                    <li>joint pain or arthritis</li>
                    <li>looking for a natural, non-surgical cosmetic solution</li>
                </ul> -->
                <div class="mt-4">
                  <!-- <button class="btn btn-outline-primary">Sign In</button> -->
                  <button class="btn btn-primary px-5 py-2" routerLink="/">About Nadi <i class="bi bi-arrow-right ml-3"></i></button>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <img class="img-fluid" src="../../../assets/images/home/slider-demo-1.jpg" >
            </div>
          </div>
          
        </div>
    </div>

</div>