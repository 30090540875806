import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-aesthetic',
  templateUrl: './service-aesthetic.component.html',
  styleUrls: ['./service-aesthetic.component.scss']
})
export class ServiceAestheticComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
