<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Cellular Therapy</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Cellular Therapy
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
        <div class="row h-100 d-flex align-items-center">
            <div class="col-md-6 info">
                <div class="">
                    <h1 class="fw-700 mt-5">
                      Discover <br>
                      The Science of <br>
                      Regeneration
                    </h1>
                </div>
            </div>
            <div class="col-md-6">
            </div>
        </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/cellular/cellular-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Discover The Science of Regeneration
      </h1>
    </div>
  </div>

  <div class="section-help container py-5">
    <h4 class="text-center">What is</h4>
    <h1 class="text-center -mt-5">Cellular Therapy</h1>
    <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
      Cellular Health is a concept that explains how our overall health is dependent on the cells in our body and how they function. This includes ensuring cells get adequate nutrients, protected from external stressors and functions at its optimal potential. <br><br>
      As you age, your CELLS AGE too! <br>
      As time goes by, our cells become fragile and fail to work efficiently.
    </p>
  </div>

  <div class="section-tabs py-5">
    <div class="container">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Masenchymal Stem cells (MSCs)</a>
          <ng-template ngbNavContent>

            <div class="section-help py-5">
              <h4 class="text-center">Cellular therapy with</h4>
              <h1 class="text-center -mt-5">Masenchymal Stem cells (MSCs)</h1>
              <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
                A form of regenerative medicine designed to repair damaged cells within the body. Bone marrow and umbilical cord tissue are commonly available sources of MSCs. MSCs help to influence tissue repair via paracrine effects (cell signaling in order to change the behaviour of existing cells) or direct cell-to-cell contact. <br><br>
                As we age our stem cell numbers diminish & begin to mutate.
              </p>
            </div>

            <div class="section-how-does mx-4">
              <div class="section-help py-5">
                <h4 class="text-center">How Does</h4>
                <h1 class="text-center -mt-5">MSCs Therapy Work?</h1>
                <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
                  MSCs therapy works by harnessing the potential of mesenchymal stem cells (MSCs) which possess high anti-inflammatory properties. These cells act as homing devices, targeting sites of injury or inflammation within the body. This makes them particularly suitable for addressing aging-related inflammation, also known as inflammaging. Through the secretion of bioactive molecules, MSCs stimulate the proliferation of endogenous stem cells, enhancing their differentiation capabilities. Additionally, MSCs therapy inhibits apoptosis (cell death) and fibrosis, promoting tissue regeneration and repair.
                </p>
              </div>

              <div class="col-11 col-lg-8 mx-auto">
                <img class="w-100 msc-image-1" src="../../../assets/images/cellular/how-1.png" alt="">
              </div>

              <div class="pt-5">
                <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
                  Studies have shown cultured stem cells have the ability to differentiate into various types of cells
                </p>
              </div>

              <div class="col-11 col-lg-11 mx-auto py-5">
                <img class="w-100 msc-image-2" src="../../../assets/images/cellular/how-2.png" alt="">
              </div>
            </div>

            <div class="section-key-benefit">
              <div class="section-help py-5">
                <h4 class="text-center">Key Benefits of</h4>
                <h1 class="text-center -mt-5">MSCs Therapy</h1>
                <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
                  MSCs therapy works by harnessing the potential of mesenchymal stem cells (MSCs) which possess high anti-inflammatory properties. These cells act as homing devices, targeting sites of injury or inflammation within the body. This makes them particularly suitable for addressing aging-related inflammation, also known as inflammaging. Through the secretion of bioactive molecules, MSCs stimulate the proliferation of endogenous stem cells, enhancing their differentiation capabilities. Additionally, MSCs therapy inhibits apoptosis (cell death) and fibrosis, promoting tissue regeneration and repair.
                  <br><br>
                  Studies have shown cultured stem cells have the ability to differentiate into various types of cells
                </p>
              </div>
  
              <div class="row col-12 col-lg-10 mx-auto">
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/benefit-1.png" alt="">
                    <h6 class="card-title">Cartilage regeneration</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/benefit-2.png" alt="">
                    <h6 class="card-title">Improves organ's function</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/benefit-3.png" alt="">
                    <h6 class="card-title">Better energy and stamina</h6>
                  </div>
                </div>
              </div>
  
              <div class="row">
                <div class="col-12 col-md-6 col-lg-3 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/benefit-4.png" alt="">
                    <h6 class="card-title">Healthier looking skin and hair</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/benefit-5.png" alt="">
                    <h6 class="card-title">Helps heal wounds and incisions</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/benefit-6.png" alt="">
                    <h6 class="card-title">Anti-aging</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/benefit-7.png" alt="">
                    <h6 class="card-title">Improves overall quality of life</h6>
                  </div>
                </div>
              </div>
            </div>

          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Exosome</a>
          <ng-template ngbNavContent>
            
            <div class="section-help py-5">
              <h1 class="text-center -mt-5">Exosome</h1>
              <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
                Exosomes are cell-secreted nanoparticles which contain numerous biological molecules including nucleic acids, proteins and lipids. <br><br>
                Exosomes act as a messaging device between cells, relaying importan information and contents relating to actions like coagulation, regeneration, and waste management from one group of cells to another.
              </p>
            </div>

            <div class="section-how-does mx-4 pb-5">
              <div class="section-help py-5">
                <h4 class="text-center">How Does</h4>
                <h1 class="text-center -mt-5">Exosome Therapy Work?</h1>
                <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
                  Exosome therapy works by administering exosomes to the body through an IV drip or injection. These exosomes are then able to attach to various target cells within the body. By doing so, they facilitate intercellular communication and activate signaling pathways that enhance the function of endogenous stem cells. This improved stem cell function contributes to the promotion of overall health and vitality in the recipient of exosome therapy.
                </p>
              </div>

              <div class="col-11 col-lg-8 mx-auto">
                <img class="w-100 msc-image-1" src="../../../assets/images/cellular/exosome-how.png" alt="">
              </div>
            </div>

            <div class="section-exosome-benefit">
              <div class="section-help pt-5">
                <h4 class="text-center">Key Benefits of</h4>
                <h1 class="text-center -mt-5">Exosome Therapy</h1>
              </div>
  
              <div class="row col-12 col-lg-10 mx-auto">
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/exosome-1.png" alt="">
                    <h6 class="card-title">Reduce inflammation</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/exosome-2.png" alt="">
                    <h6 class="card-title">Enhance sexual function</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/exosome-3.png" alt="">
                    <h6 class="card-title">Increased energy & metabolism</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/exosome-4.png" alt="">
                    <h6 class="card-title">Improves brain health</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/exosome-5.png" alt="">
                    <h6 class="card-title">Reduces chronic pain</h6>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5">
                  <div class="card card-round">
                    <img class="card-image" src="../../../assets/images/cellular/exosome-6.png" alt="">
                    <h6 class="card-title">Enhance tissue regeneration</h6>
                  </div>
                </div>
              </div>
            </div>
            
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>

  <div class="section-source container py-5">
    <div class="section-help pt-5">
      <h4 class="text-center">Source of</h4>
      <h1 class="text-center -mt-5">Stem Cell</h1>
      <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
        - Cell research laboratory approved by the Malaysian government <br>
        - Recognised by the industry
      </p>
    </div>

    <div class="row col-12 col-lg-9 mx-auto">
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center align-items-center">
        <img class="logo w-100" src="../../../assets/images/cellular/logo-1.png" alt="">
      </div>
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center align-items-center">
        <img class="logo-sme w-100" src="../../../assets/images/cellular/logo-2.png" alt="">
      </div>
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center align-items-center">
        <img class="logo w-100" src="../../../assets/images/cellular/logo-3.png" alt="">
      </div>
      <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center align-items-center">
        <img class="logo w-100" src="../../../assets/images/cellular/logo-4.png" alt="">
      </div>
    </div>
  </div>

  <div class="section-how py-5">
    <img class="green-left" src="../../../assets/images/green-gradient.png" alt="">
    <img class="green-right" src="../../../assets/images/green-gradient.png" alt="">
    <div class="container">
      <div class="section-help pt-5">
        <h1 class="text-center -mt-5">How Is It Done?</h1>
      </div>
  
      <div class="row col-12 col-lg-10 mx-auto justify-content-center">
        <div class="col-12 col-md-6 col-lg-4 mt-5">
          <div class="card card-round">
            <img class="card-image" src="../../../assets/images/cellular/done-1.png" alt="">
            <h6 class="card-title">Local administration via INJECTION</h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mt-5">
          <div class="card card-round">
            <img class="card-image" src="../../../assets/images/cellular/done-2.png" alt="">
            <h6 class="card-title">Intravenous Drip</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-risk container py-5">
    <div class="section-help">
      <h4 class="text-center">Risk of</h4>
      <h1 class="text-center -mt-5">Cellular Therapy</h1>
      <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
        Stem cell therapy is considered safe and effective. Allogenic MSCs are immuno-privilleged and immuno-evasive. They possess high clinical safety profile. However, it’s worth noting that research is still ongoing. You are advised to seek treatment from qualified medical practitioners such as our medical team. Our doctors will conduct a pre therapy screening and assessment to ensure that you are suitable for this treatment.
      </p>
    </div>
  </div>
  
</div>
