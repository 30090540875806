import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AboutClinicComponent } from './pages/about-clinic/about-clinic.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ServicesComponent } from './pages/services/services.component';
import { TeamComponent } from './pages/team/team.component';
import { DoctorInfoComponent } from './pages/doctor-info/doctor-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { FaqComponent } from './pages/faq/faq.component';
import { ServiceGeneralComponent } from './pages/service-general/service-general.component';
import { ServiceHomeVisitComponent } from './pages/service-home-visit/service-home-visit.component';
import { ServiceHealthScreeningComponent } from './pages/service-health-screening/service-health-screening.component';
import { ServiceAestheticComponent } from './pages/service-aesthetic/service-aesthetic.component';
import { ServiceSexualHealthComponent } from './pages/service-sexual-health/service-sexual-health.component';
import { ServiceUrinaryIncontinenceComponent } from './pages/service-urinary-incontinence/service-urinary-incontinence.component';
import { ServiceWellnessComponent } from './pages/service-wellness/service-wellness.component';
import { ServiceSlimmingComponent } from './pages/service-slimming/service-slimming.component';
import { PrpTherapyComponent } from './pages/service-aesthetic/prp-therapy/prp-therapy.component';
import { ExilisUltraComponent } from './pages/service-aesthetic/exilis-ultra/exilis-ultra.component';
import { AcneTherapyComponent } from './pages/service-aesthetic/acne-therapy/acne-therapy.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { ServiceTeleconsultationComponent } from './pages/service-teleconsultation/service-teleconsultation.component';
import { ServiceVaccinationComponent } from './pages/service-vaccination/service-vaccination.component';
import { ForHimComponent } from './pages/service-sexual-health/for-him/for-him.component';
import { ForHerComponent } from './pages/service-sexual-health/for-her/for-her.component';
import { ServiceCellularTherapyComponent } from './pages/service-cellular-therapy/service-cellular-therapy.component';
import { ServiceReviveTherapyComponent } from './pages/service-revive-therapy/service-revive-therapy.component';
import { ServiceNutritionDietComponent } from './pages/service-nutrition-diet/service-nutrition-diet.component';
import { ServiceStiScreeningComponent } from './pages/service-sti-screening/service-sti-screening.component';
import { ServiceVaginalTighteningComponent } from './pages/service-vaginal-tightening/service-vaginal-tightening.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutClinicComponent,
    AboutUsComponent,
    FooterComponent,
    NavbarComponent,
    AppointmentComponent,
    HomeComponent,
    ContactComponent,
    ServicesComponent,
    TeamComponent,
    DoctorInfoComponent,
    OutsideClickDirective,
    FaqComponent,
    ServiceGeneralComponent,
    ServiceHomeVisitComponent,
    ServiceHealthScreeningComponent,
    ServiceAestheticComponent,
    ServiceSexualHealthComponent,
    ServiceUrinaryIncontinenceComponent,
    ServiceWellnessComponent,
    ServiceSlimmingComponent,
    PrpTherapyComponent,
    ExilisUltraComponent,
    AcneTherapyComponent,
    SearchResultComponent,
    ServiceTeleconsultationComponent,
    ServiceVaccinationComponent,
    ForHimComponent,
    ForHerComponent,
    ServiceCellularTherapyComponent,
    ServiceReviveTherapyComponent,
    ServiceNutritionDietComponent,
    ServiceStiScreeningComponent,
    ServiceVaginalTighteningComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
