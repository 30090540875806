<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Emsella</h1>
            <p class="fw-normal">
              <a href="index.html">Home</a> / Emsella
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
        <div class="row h-100 d-flex align-items-center">
            <div class="col-md-6 info">
                <div class="">
                    <h1 class="fw-700 mt-5">
                      Urinary <br> Incontinence
                    </h1>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
            </div>
        </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/urinary/urinary-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Urinary Incontinence
      </h1>
    </div>
  </div>

  <div class="section-help container py-5">
    <h4 class="text-center">We Know Your Pain</h4>
    <h1 class="text-center -mt-5">Let us help you.</h1>
    <p class="text-center mt-4">
      Urinary incontinence can affect individuals of various ages and backgrounds, including both men and women. It is more common among older adults, women who have given birth, and individuals with certain medical conditions. <br> <br>
      The condition can significantly interfere with daily life, causing embarrassment, discomfort, and a loss of confidence. It may lead to limitations in social activities, fear of leakage, and constant worry about finding restroom facilities. <br> <br>
      We understand the challenges posed by urinary incontinence and that is why Nadi is here to compassionately support to help you overcome this condition. We have an effortless way to help you regain control, improve bladder function, and experience a renewed sense of confidence and freedom.
    </p>
  </div>

  <img class="d-flex mx-auto emsella-logo my-5" src="../../../assets/images/urinary/emsella-logo.png" alt="">
  <div class="section-emsella py-5">
    <img class="logo-green-1" src="../../../assets/images/urinary/a-logo-green.png" alt="">
    <div class="logo-green-2">
      <img src="../../../assets/images/urinary/a-logo-green.png" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-7">
          <img class="emsella-big w-100" src="../../../assets/images/urinary/emsella-1.svg" alt="">
        </div>
        <div class="col-12 col-lg-5">
          <img class="w-100" src="../../../assets/images/urinary/emsella-2.png" alt="">
        </div>
      </div>
      <p class="text-center mt-4 col-12 col-lg-8 mx-auto">
        BTL Emsella uses high-intensity focused electromagnetic (HIFEM) technology to cause deep pelvic floor muscle stimulation and restoration of the neuromuscular control. The electromagnetic waves delivered by the device penetrate deep into the muscles, causing thousands of supramaximal contractions in a single treatment session. <br><br>
        This stimulation strengthens the pelvic floor muscles, which can help to improve bladder control and reduce urinary incontinence. A single Emsella session brings thousands of supramaximal pelvic floor muscle contrations, which are extremely important in muscle re-education.
      </p>
    </div>
  </div>

  <div class="section-determine py-5">
    <img class="green-1" src="../../../assets/images/green-gradient.png" alt="">
    <img class="green-2" src="../../../assets/images/green-gradient.png" alt="">
    <div class="container text-center">
      <div class="title-area">
        <h2 class="title fw_600 text-center mt-3 font-berlingske">
          Determine if you can relate to <br> any of the following points.
        </h2>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/urinary/point-1.png" alt="">
              <h6 class="card-title">A mom that needs care after delivery</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/urinary/point-2.png" alt="">
              <h6 class="card-title">An active person but suffering from urinary incontinence</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/urinary/point-3.png" alt="">
              <h6 class="card-title">Decreased intimate satisfaction</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/urinary/point-4.png" alt="">
              <h6 class="card-title">You feel sudden, urgent need to urinate, followed by accidental leakage</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/urinary/point-5.png" alt="">
              <h6 class="card-title">Leakage due to coughing/laughing</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/urinary/point-6.png" alt="">
              <h6 class="card-title">Incontinence frequent & affecting your quality life</h6>
            </div>
          </div>
        </div>
        <h3 class="info-bold">IF YOU ANSWERED “YES” TO ANY OF THESE, IT’S TIME FOR EMSELLA!</h3>
      </div>
    </div>
  </div>

  <div class="section-help container pt-5 py-4">
    <h4 class="text-center">Why Choose</h4>
    <h1 class="text-center -mt-5">BTL Emsella?</h1>
  </div>

  <div class="section-why-choose py-4">
    <img class="logo-green-1" src="../../../assets/images/urinary/a-logo-green.png" alt="">
    <div class="container text-center">
      <div class="card-container mt-4">
        <div class="col-12 col-md-6 col-lg-2 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/why-choose-emstella-1.png" />
            </div>
            <h6 class="text-center mt-3">Non-invasive</h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/why-choose-emstella-2.png" />
            </div>
            <h6 class="text-center mt-3">No drug needed</h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/why-choose-emstella-3.png" />
            </div>
            <h6 class="text-center mt-3">Fully-clothed during treatment</h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/why-choose-emstella-4.png" />
            </div>
            <h6 class="text-center mt-3">
              Short treatment duration (30mins) and no downtime
            </h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/why-choose-emstella-5.png" />
            </div>
            <h6 class="text-center mt-3">FDA approved</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-help container pt-5 py-4">
    <h4 class="text-center">Your</h4>
    <h1 class="text-center -mt-5">Expected Outcome</h1>
    <p class="text-center mt-4">
      Scientific research has shown that treated patients reported significant improvement in their quality of life.
    </p>
  </div>

  <div class="section-services pt-3 pb-5">
    <div class="container text-center">
      <div class="row mt-4 col-12 col-lg-9 mx-auto">
        <div class="col-12 col-md-4 col-lg-4 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/expected-outcome-1.png" />
            </div>
            <h6 class="text-center mt-3">75% pad reduction</h6>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/expected-outcome-2.png" />
            </div>
            <h6 class="text-center mt-3">95% day-to-day life improvement</h6>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-4">
          <div class="card-icon">
            <div class="image">
              <img src="../../../assets/images/expected-outcome-3.png" />
            </div>
            <h6 class="text-center mt-3">
              85% intimate satisfaction improvement
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper-services">
  <div class="section-testimonials" id="testimonials">

    <img class="green-1" src="../../../assets/images/green-gradient.png" alt="">
    <img class="green-2" src="../../../assets/images/green-gradient.png" alt="">
    <img class="green-3" src="../../../assets/images/green-gradient.png" alt="">

    <div class="container">
      <div class="row mb-5 align-items-center">
        <div class="col-md-12 col-12">
          <div class="title-area">
            <h1 class="title fw_600 text-center mt-3 font-berlingske">
              What Our Customers Say ...
            </h1>
          </div>
        </div>
      </div>

      <div class="testimonials-slider">
        <div class="item"> 
          <div class="inner">
            <div class="comments">
              <p>
                After only a few sessions with BTL Emsella, I noticed a
                significant improvement in my bladder control. I no longer had
                to worry about sudden leaks or running to the bathroom every few
                minutes. I felt more confident and comfortable in my own skin,
                and I'm so grateful to have found this treatment.
              </p>
            </div>
            <div class="user">
              <div class="image">
                <img src="../../../assets/images/home/brooklyn-simmons.jpg" alt="">
              </div>
              <div class="info"> 
                <div class="name">Rachel</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item"> 
          <div class="inner">
            <div class="comments">
              <p>
                I was hesitant to try BTL Emsella at first, but I'm so glad I did. 
                The treatment was comfortable and efficient, and the results have been amazing. 
                I no longer have to worry about embarrassing leaks or feeling self-conscious 
                about my bladder control. I feel like I've regained control over my body, and 
                it's an incredible feeling.
              </p>
            </div>
            <div class="user">
              <div class="image">
                <img src="../../../assets/images/home/brooklyn-simmons.jpg" alt="">
              </div>
              <div class="info"> 
                <div class="name">Laura</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item"> 
          <div class="inner">
            <div class="comments">
              <p>
                BTL Emsella was a game-changer for me. I've tried all sorts of treatments 
                for urinary incontinence in the past, but nothing has worked as well as this. 
                The treatment was easy and painless, and the results have been life-changing. 
                I feel more confident and empowered than ever before.
              </p>
            </div>
            <div class="user">
              <div class="image">
                <img src="../../../assets/images/maria.png" alt="">
              </div>
              <div class="info"> 
                <div class="name">Maria</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
