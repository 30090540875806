<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">IV Nutrition Therapy</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / IV Nutrition Therapy
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
      <div class="row h-100 d-flex align-items-center">
        <div class="col-md-6 info">
          <div class="">
            <h1 class="fw-700 mt-5">
              Revitalise <br>
              Rejuvenate <br>
              Detoxify
            </h1>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/revive/revive-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Revitalise <br>
        Rejuvenate <br>
        Detoxify
      </h1>
    </div>
  </div>

  <div class="section-info container py-5">
    <h4 class="text-center">What is</h4>
    <h1 class="text-center -mt-5">IV Nutrition Therapy</h1>
    <p class="text-center mt-4 col-12 col-lg-10 mx-auto">
      Revive IV Therapy provides essential nutrients, vitamins, and electrolytes directly into your bloodstream, unlike traditional oral supplements that can have lower bioavailability rates. Additionally, vitamins B and C are water-soluble, making them easily excreted from the body through urine. In contrast, Revive Therapy boasts a complete <b>bioavailability rate of 100%</b>, bypassing the digestive system and allowing for <b>maximum nutrient absorption.</b> <br><br>
      This efficient delivery system allows for <b>rapid results</b>, particularly in terms of energizing, improving your immune system, and achieving healthy, glowing skin. Whether you're looking to combat fatigue, recover from an illness, or simply optimize your overall well-being,
      Revive Therapy is the perfect solution for you.
    </p>
  </div>

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-area pb-5">
        <h2>Benefits of Revive Therapy</h2>
      </div>

      <div class="honeycomb">
        <div class="ibws-fix">
          <div class="hexagon comb-blue">
            <div class="hexagontent">Boost energy production</div>
          </div>
          <div class="hexagon comb-gray">
            <div class="hexagontent">Boosts immune system</div>
          </div>
          <div class="hexagon comb-yellow">
            <div class="hexagontent">Reduce oxidative stress</div>
          </div>
          <div class="hexagon comb-blue">
            <div class="hexagontent">Liver detoxification</div>
          </div>
          <div class="hexagon comb-green">
            <div class="hexagontent">Improve memory</div>
          </div>
        </div>
        <div class="ibws-fix">
          <div class="hexagon comb-green">
            <div class="hexagontent">Reduce inflammation</div>
          </div>
          <div class="hexagon comb-gray invisible">
            <div class="hexagontent"></div>
          </div>
          <div class="hexagon comb-green">
            <div class="hexagontent">Prevent chronic disease and heart disease</div>
          </div>
          <div class="hexagon comb-blue invisible">
            <div class="hexagontent"></div>
          </div>
          <div class="hexagon comb-yellow">
            <div class="hexagontent">Improve skin condition</div>
          </div>
        </div>
      </div>

      <div class="button-action  text-center">
        <a class="btn btn-primary px-3 py-2" href="https://wa.me/+60126468392" target="_blank"> 
          <i class="bi bi-calendar3 mr-1"></i> Book Appoinment
        </a>
      </div>

      <p class="info-text mt-3 col-6 col-lg-4 mx-auto">
        Kindly schedule an appointment with our doctor for Revive Therapy.
      </p>

    </div>
  </div>

</div>
