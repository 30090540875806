  <div class="modal-body">
      <h5 class="modal-title">Book your appointment</h5>
      <p class="text-secondary">Online Appoinment Form</p>
      <div class="mt-3">
        <div class="row">
          <div class="col-6">
              <label class="fw_600">First Name</label>
              <input type="text" class="form-control" placeholder="Enter first name">
          </div>
          <div class="col-6">
            <label class="fw_600">Last Name</label>
            <input type="text" class="form-control" placeholder="Enter last name">
          </div>
        </div>
        <div class="row">
          <div class="col-6">
              <label class="fw_600">Mobile Number</label>
              <input type="text" class="form-control" placeholder="Enter mobile number">
          </div>
          <div class="col-6">
            <label class="fw_600">Email Address</label>
            <input type="text" class="form-control" placeholder="Enter e-mail">
          </div>
        </div>
        <div class="row">
          <div class="col-6">
              <label class="fw_600">Appoinment Date</label>
              <!-- <input type="text" class="form-control" placeholder="Example 3/Jun/2022"> -->
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dp"
                  [(ngModel)]="model"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle()"
                />
                <!-- <button class="btn btn-outline-secondary bi bi-calendar3"  type="button"></button> -->
              </div>
          </div>
          <div class="col-6" style="position: relative;" (outsideClick)="showTimepicker = false" outsideClick>
            <label class="fw_600">Appoinment Time</label>
            <input type="text" class="form-control" placeholder="Example - 12.00" value="{{ time.hour + ':' + time.minute }}" (click)="toggleTimepicker()">
            <div *ngIf="showTimepicker" class="timepicker">
              <ngb-timepicker [(ngModel)]="time"></ngb-timepicker>
            </div>
          </div>
        </div>
        <div>
          <div class="mt-3">
            <label class="fw_600">Message</label>
          </div>
          <div class="form-group">
            <textarea class="form-control" rows="3"></textarea>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-primary btn-block text-center">BOOK AN APPOINTMENT</button>
        </div>
      </div>
  </div>

