import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/app.service';
import { AppointmentComponent } from 'src/app/pages/appointment/appointment.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuOpen = false;
  dropdown = false;
  public isCollapsed1 = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  closeResult = '';

  isSearchBox = false;
  isSearchBoxMobile = false;
  search = '';

  isMegaMenu = false;
  isServiceMenu = false;

  public isCollapsed = true;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private appService: AppService
  ) {
    router.events.subscribe(routerEvent => {

    })
  }

  ngOnInit(): void {
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  setGoToForm() {
    console.log('set');
    localStorage.setItem('scroll_to', 'form')
  }

  openModal() {
      const modalRef = this.modalService.open(AppointmentComponent,{
        ariaLabelledBy: 'modal-basic-title',
        size: 'none',
        windowClass: 'modal-customer',
        centered: true});
      modalRef.componentInstance.id = 10;
      modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggleSearchBox() {
    this.isSearchBox = !this.isSearchBox;
  }

  toggleSearchBoxMobile() {
    this.isSearchBoxMobile = !this.isSearchBoxMobile;
  }

  submitSearch() {
    this.isSearchBox = false;
    this.appService.keyword.next(this.search);
    this.router.navigateByUrl('/search-result');
    this.search = "";
  }

  toggleMegaMenu() {
    this.isMegaMenu = !this.isMegaMenu;
  }
}
