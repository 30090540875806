<div class="wrapper-services">
    <header class="py-5 inner-header" style="background:url('../../../assets/images/services.png')no-repeat center center;">
        <div class="header-overlay"></div>
        <div class="container py-4 px-5">
           <div class="row align-items-center justify-content-center">
              <div class="col-lg-12">
                 <div class="text-center">
                    <h1 class="fw_700 text-white">Our Services</h1>
                    <p class="fw-normal text-white-50 mb-0"><a class="text-white" href="index.html">Home</a> / Services</p>
                 </div>
              </div>
           </div>
        </div>
      </header>
      <div class="section-services py-5">
        <div class="container text-center">
            <div class="row">
                <div class="col-md-4">
                    <div class="card" routerLink="/general">
                        <img class="card-img-top" src="../../../assets/images/service/service-01.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">General Medical Consultation</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" routerLink="/doctor-home-visit">
                        <img class="card-img-top" src="../../../assets/images/service/service-02.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">Doctor Home Visit</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" routerLink="/health-screening">
                        <img class="card-img-top" src="../../../assets/images/service/service-03.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">Health Screening</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4">
                    <div class="card" routerLink="/aesthetic" >
                        <img class="card-img-top" src="../../../assets/images/service/service-04.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">Aesthetic</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" routerLink="/service-sexual-health">
                        <img class="card-img-top" src="../../../assets/images/service/service-05.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">Sexual Health</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" routerLink="/service-urinary-incontinence">
                        <img class="card-img-top" src="../../../assets/images/service/service-06.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">Urinary Incontinence</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4">
                    <div class="card" routerLink="/service-wellness" >
                        <img class="card-img-top" src="../../../assets/images/service/service-07.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">Wellness</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" routerLink="/service-slimming">
                        <img class="card-img-top" src="../../../assets/images/service/service-08.jpeg" alt="Card image cap">
                        <div class="title">
                            <h5 class="font-light-green service-name fw_700 mb-0">Slimming</h5>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>