<app-navbar></app-navbar>
<router-outlet>
    <div class="float-container">
        <!-- <button class="btn btn-calendar" >
            <a href="https://appt.link/meet-with-enpa-silan/example-meeting" target="_blank">
                <i class="fi fi-ss-calendar"></i>
            </a>
        </button> -->
        <button class="btn btn-wa mt-3">
            <a href="https://wa.me/+60126468392" target="_blank">
                <i class="fi fi-brands-whatsapp"></i>
            </a> 
        </button>
    </div>
</router-outlet>
<app-footer></app-footer>