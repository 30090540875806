<section class="container">
  <div class="search-wrapper py-4">
    <h1 class="text-dark fw_600 title mb-5">Search Results</h1>

    <div *ngIf="result.length == 0" class="text-secondary">
      No result found
    </div>

    <div *ngIf="result.length > 0" class="row">
      <div *ngFor="let result of result" class="col-12 col-md-6 col-lg-4">
        <div class="card link mb-4" routerLink="{{ result.url }}">
          <div class="image-box">
            <img *ngIf="result.url == '/'" src="../../../assets/images/home/slider-1.png" alt="cover">
            <img *ngIf="result.url == '/about-us'" src="../../../assets/images/about-us.png" alt="cover">
            <img *ngIf="result.url == '/our-team'" src="../../../assets/images/our-team.png" alt="cover">
            <img *ngIf="result.url == '/contact-us'" src="../../../assets/images/contact.png" alt="cover">
            <img *ngIf="result.url == '/about-clinic'" src="../../../assets/images/clinic.png" alt="cover">
            <img *ngIf="services.includes(result.url)" src="../../../assets/images/clinic.png" alt="cover">
          </div>
          <div class="result-box">
            <h5 class="search-title">
              {{ result.page }}
            </h5>
            <p class="search-description text-secondary">
              Keyword: {{ keyword }}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>