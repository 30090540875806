<div class="wrapper-about">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
       <div class="row align-items-center justify-content-center">
          <div class="col-lg-12">
             <div class="text-center">
                <h1 class="fw_500">Our Clinic</h1>
                <p class="fw-normal"><a href="index.html">Home</a> / Our Clinic</p>
             </div>
          </div>
       </div>
    </div>
  </header>
    <div class="section-clinic mx-3 mt-5 hide-md">
      <div class="px-5 mb-4">
          <div class="row">
              <div class="col-lg-6 info">
                  <div class="">
                      <h1 class="fw-700 mt-5">
                        Your solution for <br> Regenerative, <br> Aesthetics and General <br> Well-being
                      </h1>
                      <p class="">
                        Nadi Clinic is a leading health and wellness center that offers a comprehensive range of treatments and procedures, including regenerative medicine, aesthetics, and general health solutions. With a focus on the latest technologies and innovative techniques, the clinic is dedicated to helping both healthy individuals and aging individuals improve their overall health and well-being. At Nadi Clinic, clients can expect a one-stop solution for all of their health and wellness needs.
                      </p>
                  </div>
              </div>
              <div class="col-md-6">
                  <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
              </div>
          </div>
      </div>
    </div>

    <div class="banner-top-tablet mx-3 mt-5">
      <img class="w-100" src="../../../assets/images/new/clinic/clinic-banner.png" alt="">
      <div class="mt-4">
        <h1 class="fw-700">
          Your solution for Regenerative, Aesthetics and General Well-being
        </h1>
        <p>
          Nadi Clinic is a leading health and wellness center that offers a comprehensive range of treatments and procedures, including regenerative medicine, aesthetics, and general health solutions. With a focus on the latest technologies and innovative techniques, the clinic is dedicated to helping both healthy individuals and aging individuals improve their overall health and well-being. At Nadi Clinic, clients can expect a one-stop solution for all of their health and wellness needs.
        </p>
      </div>
    </div>

    <div class="section-history">
      <div class="p-5">
        <!-- <h5 class="subtitle text-center">Brief History</h5> -->
        <h2 class="title text-center">Empowering Your Health Goals, <br> Always at Your Service.</h2>
        <div class="button-action  text-center">
          <a class="btn btn-primary px-3 py-2" href="https://wa.me/+60126468392" target="_blank"> 
              <i class="bi bi-calendar3 mr-1"></i> Book Appoinment
          </a>
        </div>
      </div>

      <div class="bg-brown pt-4 pb-5">
        <div class="col-12 col-lg-10 row mx-auto mt-4">
          <div class="col-12 col-lg-4">
            <div class="item">
              <img class="image" src="../../../assets/images/new/clinic/general.png" alt="general">
              <h6 class="title text-center mt-4">General Treatment</h6>
              <h6 class="description text-center col-10 mx-auto">Holistic care for all common medical conditions including acute and chronic illness, and provide preventive care.</h6>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="item">
              <img class="image" src="../../../assets/images/new/clinic/regenerative.png" alt="general">
              <h6 class="title text-center mt-4">Regenerative Medicine</h6>
              <h6 class="description text-center col-10 mx-auto">Innovative field of medicine to regrow, repair or replace damaged or diseased cells, organs or tissues.</h6>
            </div>
          </div> 
          <div class="col-12 col-lg-4">
            <div class="item">
              <img class="image" src="../../../assets/images/new/clinic/aesthetics.png" alt="general">
              <h6 class="title text-center mt-4">Aesthetic Medicine</h6>
              <h6 class="description text-center col-10 mx-auto">Beauty procedures that alter cosmetic appearance, rejuvenate youth, and refresh skin from top-to-toe.</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section-testimonials" id="testimonials">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 my-5 aling-self-center text-center">
            <div class="title-area">
              <h1 class="title mb-3 font-primary-regular font-color-primary">
                Voice of Satisfaction <br>
                Hear what our customers have to say
              </h1>
            </div>
          </div>
        </div>
         
          <div class="testimonials-slider">
              <div class="item"> 
                  <div class="inner">
                      <div class="comments">
                          <p>Super quick responses, absolutely professional doctors and in Medical a team  that has done nothing but go above and beyond for us!</p>
                      </div>
                      <div class="user">
                          <div class="image">
                              <img src="../../../assets/images/home/brooklyn-simmons.jpg" alt="">
                          </div>
                          <div class="info"> 
                              <div class="name">Samantha Zhang</div>
                              <div class="sub">Bandar Sunway</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="item">
                  <div class="inner">
                      <div class="comments">
                          <p>TQ Dr Azlan dan team nadi atas servis yang amat pantas.. family saya dapat sembuh
                              daripada covid tanpa tinggalkan rumah.. tq sangat2...</p>
                      </div>
                      <div class="user">
                          <div class="image">
                              <img src="../../../assets/images/home/omar.png" alt="">
                          </div>
                          <div class="info">
                              <div class="name">En Danial B. Omar</div>
                              <div class="sub">Kuala Lumpur</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="item">
                  <div class="inner">
                      <div class="comments">
                          <p>The best service so far, booked for a swab test with them and they prepared
                              everything even before I made the payment. Kudos to the entire NADI team!</p>
                      </div>
                      <div class="user">
                          <div class="image">
                              <img src="../../../assets/images/home/mrskam.png" alt="">
                          </div>
                          <div class="info">
                              <div class="name">Mrs Kam</div>
                              <div class="sub">Kuala Lumpur</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="item"> 
                  <div class="inner">
                      <div class="comments">
                          <p>Thank you so much DR. Sheena for detecting COVID-19 in me & my family! My
                              family is okay today because of you!</p>
                      </div>
                      <div class="user">
                          <div class="image">
                              <img src="../../../assets/images/home/justin.png" alt="">
                          </div>
                          <div class="info"> 
                              <div class="name">Justin Ong</div>
                              <div class="sub">Subang Jaya</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

    <!-- <div class="section-services bg-gray py-5">
      <div class="container">
        
        <div class="row">
            <div class="col-md-4">
              <p class="text_blue_1">OUR SERVICES</p>
              <h1 class="title fw_600 text-dark mt-3">
                Empowering Your Health Goals, Always at Your Service.
              </h1>
              
            </div>
            <div class="col-md-8">
              <div class="row gx-5 row-cols-1 row-cols-md-2">
                <div class="col mb-5 h-100">
                   <div class="feature bg-blue-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-file-medical"></i></div>
                   <h5 class="fw-600">General Treatment</h5>
                   <p class="mb-0">Holistic care for all common medical conditions including acute and chronic illness, and provide preventive care.</p>
                </div>
                <div class="col mb-5 h-100">
                   <div class="feature bg-blue-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-soundwave"></i></div>
                   <h5 class="fw-600">Regenerative Medicine</h5>
                   <p class="mb-0">Innovative field of medicine to regrow, repair or replace damaged or diseased cells, organs or tissues.</p>
                </div>
                <div class="col mb-5 mb-md-0 h-100">
                   <div class="feature bg-blue-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-eye"></i></div>
                   <h5 class="fw-600">Aesthetics Medicine</h5>
                   <p class="mb-0">Cosmetic treatments that promote aging reversal, rejuvenate youth, and refresh skin from top-to-toe.</p>
                </div>
               
             </div>
            </div>
        </div>

    </div>
  </div> -->
  <!-- <section class="section-testimonials" id="testimonials">
    <div class="container">
      <div class="row mb-5 align-items-center">
        <div class="col-md-8 col-12">
          <div class="title-area">
            <p class="text_blue_1">Testimonials</p>
            <h1 class="title fw_600 text-dark mt-3">
              Hear it from our customers 
            </h1>
            <h6 class="fw_400 mt-3 desc">
              These stories of recovery are truly incredible and inspiring. NADI Health is humbled to be part of your healing journey.
            </h6>
              
          </div>
        </div>
        <div class="col-md-4 col-12 d-flex justify-content-end">
          <div class="control justify-content-center py-2">
            <div class="box">
              <button class="slider-btn prev">
                <span class="chevron left mx-3"></span>
              </button>
              <button class="slider-btn next">
                <span class="chevron right mx-3"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
       
        <div class="testimonials-slider">
            <div class="item"> 
                <div class="inner">
                    <div class="comments">
                        <p>Super quick responses, absolutely professional doctors and in Medical a team  that has done nothing but go above and beyond for us!</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/brooklyn-simmons.jpg" alt="">
                        </div>
                        <div class="info"> 
                            <div class="name">Samantha Zhang</div>
                            <div class="sub">Bandar Sunway</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="inner">
                    <div class="comments">
                        <p>TQ Dr Azlan dan team nadi atas servis yang amat pantas.. family saya dapat sembuh
                            daripada covid tanpa tinggalkan rumah.. tq sangat2...</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/omar.png" alt="">
                        </div>
                        <div class="info">
                            <div class="name">En Danial B. Omar</div>
                            <div class="sub">Kuala Lumpur</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="inner">
                    <div class="comments">
                        <p>The best service so far, booked for a swab test with them and they prepared
                            everything even before I made the payment. Kudos to the entire NADI team!</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/mrskam.png" alt="">
                        </div>
                        <div class="info">
                            <div class="name">Mrs Kam</div>
                            <div class="sub">Kuala Lumpur</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item"> 
                <div class="inner">
                    <div class="comments">
                        <p>Thank you so much DR. Sheena for detecting COVID-19 in me & my family! My
                            family is okay today because of you!</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/justin.png" alt="">
                        </div>
                        <div class="info"> 
                            <div class="name">Justin Ong</div>
                            <div class="sub">Subang Jaya</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section> -->
  <!-- <div class="section-client h-100 relative">
    <div class="container px-50">
      <div class="row h-100">
        <div class="col-12 col-md-8 col-md-8 d-flex align-items-center" data-aos="fade-up" data-aos-duration="800" data-aos-delay="0">
          <div>
            <h1 class="title title-2 fw_600 text-dark mt-3">
              Our Client Says it Best
            </h1>
          </div>
        </div>
        <div class="control col-12 col-md-4">
          <div class="box">
            <button class="slider-btn prev_1">
              <span class="chevron left mx-3"></span>
            </button>
            <button class="slider-btn next_1">
              <span class="chevron right mx-3"></span>
            </button>
          </div>
        </div>
      </div>
    

      <div class="blog-slider my-5">
        <div class="card">
          <div class="content">
            <div class="text-warning">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </div>
            <h6 class="desc text-center">Very good price and very professional. Always got my result on the same day. The staffs are very friendly too! 10/10.</h6>
            <h6 class="subtitle mt-5">Serene Claire</h6>
            <p class="client">Client</p>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="text-warning">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </div>
            <h6 class="desc text-center">Very good price and very professional. Always got my result on the same day. The staffs are very friendly too! 10/10.</h6>
            <h6 class="subtitle mt-5">Serene Claire</h6>
            <p class="client">Client</p>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="text-warning">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </div>
            <h6 class="desc text-center"> Very good services. Been using their home visit PCR test service for few times. </h6>
            <h6 class="subtitle mt-5">Serene Claire</h6>
            <p class="client">Client</p>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="text-warning">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </div>
            <h6 class="desc text-center"> NADI has set a high standard for healthcare. The staff and admin process are excellent and simple. I would have no hesitation to use NADI again. </h6>
            <h6 class="subtitle mt-5">Serene Claire</h6>
            <p class="client">Client</p>
          </div>
        </div>
      </div>
      <app-contact></app-contact>
    </div>
  </div>   -->
</div>
