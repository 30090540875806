import home from '../keywords/home.json';
import about_us from '../keywords/about-us.json';
import contact_us from '../keywords/contact-us.json';
import our_team from '../keywords/our-team.json';
import our_clinic from '../keywords/our-clinic.json';
import service_aesthetic from '../keywords/service-aesthetic.json';
import service_doctor_home_visit from '../keywords/service-doctor-home-visit.json';
import service_general from '../keywords/service-general.json';
import service_health_screening from '../keywords/service-health-screening.json';
import service_sexual_health from '../keywords/service-sexual-health.json';
import service_slimming from '../keywords/service-slimming.json';
import service_urinary_incontinence from '../keywords/service-urinary-incontinence.json';
import service_wellness from '../keywords/service-wellness.json';

export const keywords = [
  home,
  about_us,
  contact_us,
  our_team,
  our_clinic,
  service_aesthetic,
  service_doctor_home_visit,
  service_general,
  service_health_screening,
  service_sexual_health,
  service_slimming,
  service_urinary_incontinence,
  service_wellness
]