import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prp-therapy',
  templateUrl: './prp-therapy.component.html',
  styleUrls: ['./prp-therapy.component.scss']
})
export class PrpTherapyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
