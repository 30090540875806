import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AboutClinicComponent } from './pages/about-clinic/about-clinic.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { ServicesComponent } from './pages/services/services.component';
import { TeamComponent } from './pages/team/team.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DoctorInfoComponent } from './pages/doctor-info/doctor-info.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ServiceGeneralComponent } from './pages/service-general/service-general.component';
import { ServiceHomeVisitComponent } from './pages/service-home-visit/service-home-visit.component';
import { ServiceHealthScreeningComponent } from './pages/service-health-screening/service-health-screening.component';
import { ServiceAestheticComponent } from './pages/service-aesthetic/service-aesthetic.component';
import { ServiceSlimmingComponent } from './pages/service-slimming/service-slimming.component';
import { ServiceWellnessComponent } from './pages/service-wellness/service-wellness.component';
import { ServiceUrinaryIncontinenceComponent } from './pages/service-urinary-incontinence/service-urinary-incontinence.component';
import { ServiceSexualHealthComponent } from './pages/service-sexual-health/service-sexual-health.component';
import { AcneTherapyComponent } from './pages/service-aesthetic/acne-therapy/acne-therapy.component';
import { ExilisUltraComponent } from './pages/service-aesthetic/exilis-ultra/exilis-ultra.component';
import { PrpTherapyComponent } from './pages/service-aesthetic/prp-therapy/prp-therapy.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { ServiceTeleconsultationComponent } from './pages/service-teleconsultation/service-teleconsultation.component';
import { ServiceVaccinationComponent } from './pages/service-vaccination/service-vaccination.component';
import { ForHimComponent } from './pages/service-sexual-health/for-him/for-him.component';
import { ForHerComponent } from './pages/service-sexual-health/for-her/for-her.component';
import { ServiceCellularTherapyComponent } from './pages/service-cellular-therapy/service-cellular-therapy.component';
import { ServiceReviveTherapyComponent } from './pages/service-revive-therapy/service-revive-therapy.component';
import { ServiceNutritionDietComponent } from './pages/service-nutrition-diet/service-nutrition-diet.component';
import { ServiceStiScreeningComponent } from './pages/service-sti-screening/service-sti-screening.component';
import { ServiceVaginalTighteningComponent } from './pages/service-vaginal-tightening/service-vaginal-tightening.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'about-clinic',
    component: AboutClinicComponent
  },
  {
    path:'appointment',
    component: AppointmentComponent
  },
  {
    path: 'our-services',
    component: ServicesComponent
  },
  {
    path: 'our-team',
    component: TeamComponent
  },
  {
    path: 'contact-us',
    component: ContactComponent
  },
  {
    path: 'doctor-info',
    component: DoctorInfoComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'service-general',
    component: ServiceGeneralComponent
  },
  {
    path: 'service-doctor-home-visit',
    component: ServiceHomeVisitComponent
  },
  {
    path: 'service-health-screening',
    component: ServiceHealthScreeningComponent
  },
  {
    path: 'service-aesthetic',
    component: ServiceAestheticComponent
  },
  {
    path: 'acne-therapy',
    component: AcneTherapyComponent
  },
  {
    path: 'exilis-ultra',
    component: ExilisUltraComponent
  },
  {
    path: 'prp-therapy',
    component: PrpTherapyComponent
  },
  {
    path: 'service-slimming',
    component: ServiceSlimmingComponent
  },
  {
    path: 'service-wellness',
    component: ServiceWellnessComponent
  },
  {
    path: 'service-urinary-incontinence',
    component: ServiceUrinaryIncontinenceComponent
  },
  {
    path: 'service-sexual-health',
    component: ServiceSexualHealthComponent
  },
  {
    path: 'search-result',
    component: SearchResultComponent
  },
  {
    path: 'service-teleconsultation',
    component: ServiceTeleconsultationComponent
  },
  {
    path: 'service-vaccination',
    component: ServiceVaccinationComponent
  },
  {
    path: 'service-sexual-health-for-him',
    component: ForHimComponent
  },
  {
    path: 'service-sexual-health-for-her',
    component: ForHerComponent
  },
  {
    path: 'service-cellular-therapy',
    component: ServiceCellularTherapyComponent
  },
  {
    path: 'service-revive-therapy',
    component: ServiceReviveTherapyComponent
  },
  {
    path: 'service-nutrition-diet',
    component: ServiceNutritionDietComponent
  },
  {
    path: 'service-sti-screening',
    component: ServiceStiScreeningComponent
  },
  {
    path: 'service-vaginal-tightening',
    component: ServiceVaginalTighteningComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
