<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Sexual Health For Her</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Sexual Health For Her
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/sexual-health.jpg"
          />
        </div>
        <div class="col-md-6">
          <div class="pl-5">
            <h1 class="fw-700 mb-3 text-black">Sexual Health</h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              Sexuality is vital in shaping one's overall well-being and
              happiness. In order to cultivate a healthy sexual relationship, it
              is important to address any sexual health issues that may arise.
              By treating these issues, individuals can gain a sense of
              security, confidence, and fulfillment within their intimate
              relationships.
              <br /><br />
              We provide a comprehensive range of services to support your
              sexual health needs ranging from screening, prevention to
              treatments. Trust in our expertise to help you maintain a
              fulfilling and satisfying sexual life.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <div class="pr-0">
            <h5 class="fw-700 mb-3 text-black font-berlingske">FOR HER</h5>
            <h1 class="fw-700 mb-3 text-black">
              Sexually Transmitted Infections (STIs)
            </h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              Sexually Transmitted Infections (STIs) are a group of infections
              that are spread from person to person through sexual contact.
              These infections can be caused by bacteria, viruses, or parasites
              and can be transmitted through sexual activity, including vaginal,
              anal, or oral sex. Some common STIs include chlamydia, gonorrhea,
              syphilis, herpes, and human papillomavirus (HPV).
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/woman.jpg"
          />
        </div>
      </div>

      <div class="title-area">
        <h4 class="title fw_600 text-dark mt-5 font-berlingske">
          Enhance Your Body Awareness Through Screening
        </h4>
        <h6 class="fw_400 mt-3 desc">
          STIs Screening Options:
          <ul>
            <li style="list-style: auto">Chlamydia</li>
            <li style="list-style: auto">Gonorrhea</li>
            <li style="list-style: auto">HIV</li>
            <li style="list-style: auto">Herpatitis B and C</li>
            <li style="list-style: auto">Human Papillomavirus (HPV)</li>
            <li style="list-style: auto">Trichomoniasis</li>
          </ul>
          Screening methods may include blood tests, urine tests, pap smear and
          swab tests, depending on the type of infection.
        </h6>

        <h4 class="title fw_600 text-dark mt-5 font-berlingske">
          Pre-Exposure Prophylaxis (PrEP)
        </h4>
        <h6 class="fw_400 mt-3 desc">
          <ul class="pl-3">
            <li style="list-style: none">✔ Preventive medication for HIV</li>
            <li style="list-style: none">
              ✔ Works by blocking the virus, reducing the likelihood of an
              individual becoming infected with HIV
            </li>
            <li style="list-style: none">
              ✔ Aims to reduce the risk of HIV infection
            </li>
            <li style="list-style: none">✔ Taken on daily basic</li>
            <li style="list-style: none">
              ✔ Suitable for individuals engaging in unprotected sex, sharing
              needles, having multiple partners, or in a sexual relationship
              with a HIV-positive partner
            </li>
          </ul>
        </h6>

        <h4 class="title fw_600 text-dark mt-5 font-berlingske">
          Revitalize Your Sexuality: A Comprehensive Erectile Dysfunction (ED)
          Solution
        </h4>
        <h6 class="fw_400 mt-3 desc">
          Erectile Dysfunction (ED) can be a challenging and embarrassing issue
          for many men, but there is assistance available. At NADI, our team of
          sexual health expert doctors specializes in designing effective
          treatment programs to help men overcome ED and regain their
          confidence. Our program utilizes a safe and non-invasive method that
          improves blood flow to the penis, offering highly effective results in
          treating ED symptoms and restoring sexual function.
          <ul class="pl-3">
            <li style="list-style: none">✔ Improved blood flow</li>
            <li style="list-style: none">✔ Increased sexual desire</li>
            <li style="list-style: none">✔ Increased confidence</li>
            <li style="list-style: none">✔ Improved sexual performance</li>
          </ul>
        </h6>
      </div>
    </div>
  </div>

  <div class="section-services mb-4">
    <div class="container text-center">
      <img
        class="col-12 col-md-6 d-flex mx-auto mt-4"
        src="../../../../assets/images/ultra-femme-360-logo.png"
      />
      <h4 class="title fw_600 text-dark mt-5 font-berlingske">
        Vaginal Tightening & Labia Rejuvenation by Ultra Femme 360
      </h4>
      <p class="pt-3 pb-4">
        Ultra Femme 360 uses advanced radiofrequency (RF) technology to target
        and stimulate the rejuvenation and tightening of vaginal area tissues,
        leading to improved vaginal tone, increased collagen production, and
        enhanced overall vaginal health.
      </p>
      <p class="pb-4">
        The procedure utilises 360° volumetric heating with a smooth in-and-out
        motion ensuring the faster, safer and maximum level of comfort of female
        intimate part. The groundbreaking Energy Flow Control (EFC) system with
        integrated Impedance Intelligence ensures uniform heating of targeted
        tissue.
      </p>

      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <img class="w-100" src="../../../../assets/images/procedure-1.png" />
        </div>
        <div class="col-12 col-md-6 mb-4">
          <img class="w-100" src="../../../../assets/images/procedure-2.png" />
        </div>
      </div>
    </div>
  </div>

  <div class="history-section pt-3 pb-5">
    <div class="container p-5">
      <div class="title-area">
        <h4 class="title fw_600 text-dark mt-3">Why Choose BTL Emsella?</h4>
        <ul class="desc pl-3">
          <li style="list-style: none">✔ Non-invasive</li>
          <li style="list-style: none">✔ Fully-clothed during treatment</li>
          <li style="list-style: none">
            ✔ Short treatment duration (30mins) and no downtime
          </li>
          <li style="list-style: none">✔ FDA approved</li>
          <li style="list-style: none">
            ✔ Clinically proven to be safe and effective
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
