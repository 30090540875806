<div class="footer-top">
  <div class="container">
    <div class="help-you-need">
      <div class="desc">
        <div class="title-desc font-primary-regular">Healthcare at your fingertips!</div>
        <p>Reach out to us for additional details and secure your appointment in advance.</p>
        <div class="action d-flex flex-row">
          <!-- <a id="waTopBtnBottom" href="https://api.whatsapp.com/send?phone=601117665091&text=Hello%20NADI%20Health"
            class="btn btn-primary px-5 py-2"><i class="bi bi-telephone mr-1"></i> Get In Touch Now</a>
          <a id="waTopBtnBottom" href="https://appt.link/meet-with-enpa-silan/example-meeting" target="_blank"
            class="btn btn-secondary px-5 py-2 ml-2"><i class="bi bi-calendar3 mr-1"></i> Book An Appointment</a> -->
            <button class="btn btn-learn-more mt-2 mr-3" routerLink="/contact-us">
              <h6 class="text text-white">Get in touch</h6>
              <div class="arrow-icon mt-1">
                <span class="chevron down"></span>
              </div>
            </button>
            <a href="https://wa.me/+60126468392" target="_blank" class="btn btn-learn-more mt-2">
              <h6 class="text text-white">Book an appointment</h6>
              <div class="arrow-icon mt-1">
                <span class="chevron down"></span>
              </div>
            </a>
        </div>
      </div>
      <div class="image">
        <img src="../../../assets/images/new/cta-block.png" class="img-fluid">
      </div>
    </div>
  </div>
</div>
<div class="footer-contact">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-12 my-2">
        <div class="contact-info">
          <div class="row">
            <div class="col-2 col-lg-3">
              <div class="contact-icon">
                <img src="../../../assets/images/new/footer-phone.png">
              </div>
            </div>
            <div class="col-8 col-lg-9 align-self-center">
              <div class="contact-detail">
                <div class="contact-main font-primary-regular">
                  03-7731 2727
                </div>
                <div class="contact-desc">
                  Call our hotline number for immediate assistance
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12 my-2">
        <div class="contact-info">
          <div class="row">
            <div class="col-2 col-lg-3">
              <div class="contact-icon">
                <img src="../../../assets/images/new/footer-map.png">
              </div>
            </div>
            <div class="col-8 col-lg-9 align-self-center">
              <div class="contact-detail">
                <div class="contact-main font-primary-regular">
                  Locate Our Clinic
                </div>
                <div class="contact-desc">
                  10 Boulevard, B-2-18, Block B, Lebuhraya SPRINT, PJU 6A, 47400 Petaling Jaya, Selangor
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12 my-2">
        <div class="contact-info">
          <div class="row">
            <div class="col-2 col-lg-3">
              <div class="contact-icon">
                <img src="../../../assets/images/new/footer-mail.png">
              </div>
            </div>
            <div class="col-8 col-lg-9 align-self-center">
              <div class="contact-detail">
                <div class="contact-main font-primary-regular">
                  nadiclinic@nadimeditech.com
                </div>
                <div class="contact-desc">
                  Drop your message to our official email.
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- <div class="pre-footer bg-super-light-green">
    <div class="container mt-5">

      <div class="row my-5">

        <div class="col-md-4 col-11 mx-auto footer-brand-summary">
          <div class="footer-brand mb-4">
            <img routerLink="/" class="logo" src="../../../assets/images/nadi logo.png">
            <div class="logo-title">Nadi Meditech</div>
          </div>
          <p>Primary healthcare provider focusing on preventive medicine via holistic approach & regenerative medicine
            through medical advancements.</p>

          <ul class="footer-social">
            <li><a href="https://www.facebook.com/nadimeditechsdnbhd"><i class="bi bi-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/nadimeditech/"><i class="bi bi-instagram"></i></a></li>
          </ul>
        </div>


        <div class="col-md-2 col-11 mx-auto footer-links">

          <h5 class="footer-link-title">Quicklinks</h5>
          <ul>
            <li><a routerLink="/about-us">About Us</a></li>
            <li><a routerLink="/our-services">Our Services</a></li>
            <li><a routerLink="/our-team">Our Team</a></li>
            <li><a routerLink="/contact-us">Get In Touch</a></li>
            <li><a href="https://appt.link/meet-with-enpa-silan/example-meeting" target="_blank">Appointment</a></li>
            <li><a routerLink="/faq">FAQ</a></li>
          </ul>

        </div>


        <div class="col-md-6 col-11 mx-auto footer-contact">

          <h5 class="footer-contact-title">Contact Information</h5>
          <div class="row">
            <div class="col-md-12 col-12 mb-2">
              <div class="d-flex gap-3 mb-3 py-1">
                <div class="footer-contact-icon">
                  <i class="bi bi-telephone"></i>
                </div>

                <div class="footer-contact-info">
                  <a href="tel:+0377223188">03-77223188</a>
                  <p>Call our hotline number for immediate assistance</p>
                </div>
              </div>
              <div class="d-flex gap-3 mb-3 py-1">
                <div class="footer-contact-icon">
                  <i class="bi bi-geo-alt"></i>
                </div>

                <div class="footer-contact-info">
                  <a>Locate Our Clinic</a>
                  <p>10 Boulevard, B-2-18, Block B, Lebuhraya SPRINT, PJU 6A, 47400 Petaling Jaya, Selangor</p>
                </div>
              </div>
              <div class="d-flex gap-3 mb-3 py-1">
                <div class="footer-contact-icon">
                  <i class="bi bi-envelope"></i>
                </div>

                <div class="footer-contact-info">
                  <a href="mailto:nadiclinic@nadimeditech.com">nadiclinic@nadimeditech.com</a>
                  <p>Drop your message to our official email.</p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>





    </div>
  </div> -->
  <div class="footer-main">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-2">
          <div class="row">
            <div class="col-12">
              <div class="footer-section-title font-primary-regular">
                Quicklinks
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div routerLink="/" class="footer-link">Home</div>
              <div routerLink="/about-us" class="footer-link">About Us</div>
              <div routerLink="/about-clinic" class="footer-link">Our Clinic</div>
            </div>
            <div class="col-md-6 col-12">
              <div routerLink="/our-team" class="footer-link">Our Team</div>
              <div routerLink="/contact-us" class="footer-link">Contact</div>
              <div routerLink="/faq" class="footer-link">FAQ</div>
            </div>
          </div>

        </div>
        <div class="col-md-6 mb-2">
          <div class="row">
            <div class="col-12">
              <div class="footer-section-title font-primary-regular">
                Services
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4 col-12">
              <div routerLink="/service-general" class="footer-link">Medical Consultation</div>
              <div routerLink="/service-teleconsultation" class="footer-link">Teleconsultation</div>
              <div routerLink="/service-doctor-home-visit" class="footer-link">Doctor Home Visit</div>
              <div routerLink="/service-health-screening" class="footer-link">Health Screening</div>
              <div routerLink="/service-vaccination" class="footer-link">Vaccination</div>
            </div>
            <div class="col-md-6 col-lg-4 col-12">
              <div routerLink="/service-urinary-incontinence" class="footer-link">Emsella</div>
              <div routerLink="/exilis-ultra" class="footer-link">Head-to-Toe Contouring</div>
              <div routerLink="/service-slimming" class="footer-link">Slimming</div>
              <div routerLink="/service-cellular-therapy" class="footer-link">Cellular Therapy</div>
            </div>
            <div class="col-md-6 col-lg-4 col-12">
              <div routerLink="/service-vaginal-tightening" class="footer-link">Vaginal Tightening</div>
              <div routerLink="/service-sti-screening" class="footer-link">STI Test & Treatment</div>
              <div routerLink="/service-revive-therapy" class="footer-link">IV Nutrition Therapy </div>
              <div routerLink="/service-nutrition-diet" class="footer-link">Nutrition & Diet Consultation</div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-2 mb-2">
          <div class="row">
            <div class="col-12">
              <div class="footer-section-title font-primary-regular">
                Legal
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="footer-link">Privacy Policy</div>
              <div class="footer-link">Terms of Use</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
      <div class="row">

        <div class="col-md-6 col-12 order-2 order-md-1 copy">

          <p> &copy; 2023 Nadi Meditech Sdn Bhd. All rights reserved.</p>

        </div>



        <div class="col-md-6 col-12 order-1 order-md-2 legal">


          <ul >
            <li><a href="https://www.facebook.com/nadiclinicmy" target="_blank"><img src="../../../assets/images/new/fb-icon.png"></a></li>
            <li><a href="https://www.instagram.com/nadiclinic/" target="_blank"><img src="../../../assets/images/new/ig-icon.png"></a></li>
          </ul>



        </div>



      </div>
    </div>
  </div>