<div class="wrapper-aesthetic">
    <header class="py-5 inner-header" style="background:url('../../../assets/images/clinic.png')no-repeat center center;">
      <div class="header-overlay"></div>
      <div class="container py-4 px-5">
         <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
               <div class="text-center">
                  <h1 class="fw_700 text-white">Aesthetic Services</h1>
                  <p class="fw-normal text-white-50 mb-0"><a class="text-white" href="index.html">Services</a> / Aesthetic Services</p>
               </div>
            </div>
         </div>
      </div>
    </header>
    <div class="history-section bg-gray py-5">
        <div class="container p-5">
            <div class="row">
                <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/service-aesthetic.jpg" >
                </div>
                <div class="col-md-6">
                    <div class="pl-5">
                        <p class="text-uppercase text_blue_1 mb-2">Our Service</p>
                        <h1 class="fw-700 mb-3 text-black">Aesthetic</h1>
                        <p class="fs-6 fw-light text-muted mb-0 text-justify desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga sapiente rem fugit aliquid recusandae tempora dolorum esse suscipit atque veritatis.</p>
                      </div>
                 </div>
            </div>
        </div>
    </div>
    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="pl-5">
                        <h1 class="fw-700 mb-3 text-black">Platelet-rich plasma (PRP) Therapy	</h1>
                        <p class="fs-6 fw-light text-muted mb-0 text-justify desc">PRP therapy is a non-surgical aesthetic procedure that utilizes the client's own blood to promote healing and rejuvenation of the skin.</p>
                        <h4 class="title fw_600 text-dark mt-2">PRP Procedures</h4>
                        <ol class="fs-6 fw-light text-muted mb-0 text-justify desc">
                            <li style="list-style: auto;">Drawing a small amount of the client's blood.</li>
                            <li style="list-style: auto;">Separating the plasma from the red and white blood cells through centrifugation.</li>
                            <li style="list-style: auto;">The concentrated plasma (rich in growth factors and platelets) is then injected into the target areas.</li>
                        </ol>
                    </div>
                 </div>
                 <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/service-prp-therapy.jpg" >
                </div>
            </div>
        </div>
    </div>

    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 text-center">
                    <img class="img-fluid w-75 text-center rounded mb-5 mb-lg-0" src="../../../assets/images/service/PRP-Facial.jpg" >
                </div>
                <div class="col-md-6">
                    <h4 class="title fw_600 text-dark mt-5">Who Needs PRP Therapy?</h4>
                    <h6>Individuals with:</h6>
                    <h6 class="fw_400 mt-3 desc">
                    <ul>
                        <li style="list-style: none;">✔ hair loss or thinning hair</li>
                        <li style="list-style: none;">✔ wrinkles, fine lines, and aging skin</li>
                        <li style="list-style: none;">✔ acne scars or other facial scars</li>
                        <li style="list-style: none;">✔ dark circles or under-eye bags</li>
                        <li style="list-style: none;">✔ joint pain or arthritis</li>
                        <li style="list-style: none;">✔ looking for a natural, non-surgical cosmetic solution</li>
                    </ul>
                    </h6>
                 </div>
                 
            </div>
        </div>
    </div>
    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h4 class="title fw_600 text-dark mt-5">Benefits of PRP Therapy</h4>
                    <h6 class="fw_400 mt-3 desc">
                        <ul>
                            <li style="list-style: none;">✔  Promotes skin rejuvenation</li>
                            <li style="list-style: none;">✔  Improves skin texture and tone</li>
                            <li style="list-style: none;">✔  Reduces the appearance of fine lines and wrinkles </li>
                            <li style="list-style: none;">✔  Boosts collagen production </li>
                            <li style="list-style: none;">✔  Enhances skin elasticity</li>
                            <li style="list-style: none;">✔  Minimizes acne scars and other types of scars                        </li>
                            <li style="list-style: none;">✔  Treats hair loss</li>
                            <li style="list-style: none;">✔  Helps to regenerate hair growth                        </li>
                            <li style="list-style: none;">✔  Improves skin hydration                        </li>
                            <li style="list-style: none;">✔  Improves wound healing and tissue repair.	                        </li>
                        </ul>
                    </h6>
                 </div>
                 <div class="col-md-6 text-center">
                    <img class="img-fluid w-75 text-center rounded mb-5 mb-lg-0" src="../../../assets/images/service/benefits-prp.jpg" >
                </div>
            </div>
            <h4 class="title fw_600 text-dark mt-5 text-center">
                Side effects for PRP therapy		
            </h4>
            <h6 class="fw_400 mt-3 desc text-justify pl-5">
                PRP therapy is a completely natural, safe, and effective way to enhance your beauty and achieve your desired appearance. The therapy uses your own body's natural healing properties, so there is no risk of allergic reactions. The most common side effects include pain, swelling, and bleeding at the injection site.	
            </h6>
        </div>
    </div>
    <div class="line"></div>

    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="pl-5">
                        <h1 class="fw-700 mb-3 text-black">Exilis Ultra 360</h1>
                        <h4 class="title fw_600 text-dark mt-3">LIFTING FIRMING TONING	</h4>
                
                        <h6 class="fw_400 mt-3 desc text-justify">
                            Exilis Ultra 360 uses radiofrequency (RF) technology to deliver controlled heat to the skin. This heat helps to stimulate collagen production and improve the appearance of the skin. The device uses a combination of monopolar and bipolar RF energy to target different depths of the skin and achieve optimal results.<br><br>
                        </h6>
                    </div>
                 </div>
                 <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/service-exilis.jpg" >
                </div>
                <div class="container">
                    <h6 class="fw_400 mt-3 desc text-justify pl-5">
                        The Exilis Ultra 360 device also features a unique handpiece that can be rotated 360 degrees, allowing for the treatment of larger areas of the body with more precision and comfort. This technology is designed to safely and effectively improve the appearance of fine lines and wrinkles, tighten loose skin, and reduce the appearance of cellulite.
                        In addition to RF energy, the Exilis Ultra 360 device also uses ultrasound technology to help deliver heat more deeply into the skin. This combination of RF and ultrasound energy helps to achieve more comprehensive and effective results.
                    </h6>
                </div>
            </div>
        </div>
    </div>

    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 text-center">
                    <img class="img-fluid w-75 text-center rounded mb-5 mb-lg-0" src="../../../assets/images/service/body-part.jpg" >
                </div>
                <div class="col-md-6">
                    <h4 class="title fw_600 text-dark mt-3">Experience the Rejuvenation, Any Part of Your Body.	</h4>
                <h6 class="fw_400 mt-3 desc">Face, double chin, neck, arms, bra fat, tummy, love handles, saddlebags, hips, legs, and intimate part</h6>
                 </div>
                 
            </div>
        </div>
    </div>
    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <h6 class="fw_400 desc text-justify px-5 pb-5">Exilis Ultra 360 is a non-surgical skin tightening and rejuvenation treatment that can be used to improve the appearance of a variety of skin concerns, including: </h6>
                <div class="col-md-6 desc">
                        <ul>
                            <li style="list-style: none;">✔  <b>Fine lines and wrinkles:</b> Exilis Ultra 360 can help to smooth out fine lines and wrinkles, improving the overall appearance of the skin.
                            </li>
                            <li style="list-style: none;">✔  <b>Loose skin:</b> The RF energy delivered by the Exilis Ultra 360 device can help to tighten loose skin and restore a more youthful appearance.
                            </li>
                            <li style="list-style: none;">✔  <b>Cellulite:</b> Exilis Ultra 360 can help to reduce the appearance of cellulite by improving the texture and smoothness of the skin.
                            </li>
                            <li style="list-style: none;">✔  <b>Sagging skin:</b> The RF energy delivered by Exilis Ultra 360 can help to lift and firm sagging skin, giving the face and body a more youthful and contoured appearance.
                            </li>
                        </ul>
                 </div>
                 <div class="col-md-6 desc">
                    <ul>
                        <li style="list-style: none;">✔  <b>Body contouring:</b> Exilis Ultra 360 can be used to contour and shape the body by reducing the appearance of stubborn fat and tightening the skin.
                        </li>
                        <li style="list-style: none;">✔  <b>Vaginal tightening:</b> By tightening and firming the vaginal tissue, Exilis Ultra 360 can help to increase sensation during sexual activity, leading to improved sexual satisfaction.
                        </li>
                        <li style="list-style: none;">✔  <b>Labia rejuvenation:</b> Exilis Ultra 360 can improve the appearance of the labia by reducing sagging, wrinkling, or asymmetry, resulting in a more youthful and aesthetically pleasing appearance.	</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="line"></div>

    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="pl-5">
                        <h1 class="fw-700 mb-3 text-black"> Acne Therapy</h1>
                        <h4 class="title fw_600 text-dark mt-3">LIFTING FIRMING TONING	</h4>
                
                        <h6 class="fw_400 mt-3 desc">
                            Acne is a common skin condition that affects people of all ages, but it is especially common in teenagers and young adults. Acne can be painful and unsightly, and it can impact a person's self-esteem and quality of life. In NADI, we provide a comprehensive and effective solution to help combat this condition and restore your skin to its natural, healthy state. 
                        </h6>
                    </div>
                 </div>
                 <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/service-acne.jpg" >
                </div>
            </div>
        </div>
    </div>
    <div class="history-section py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 text-center d-flex align-items-center">
                    <img class="img-fluid w-75 text-center rounded mb-5 mb-lg-0" src="../../../assets/images/service/acne-causes.jpg" >
                </div>
                <div class="col-md-6 desc">
                    <h4 class="title fw_600 text-dark mt-3">Causes of Acne</h4>
                    <ul style="padding: 0">
                        <li style="list-style: none;">✔  <b>Hormonal Changes:</b> Hormonal changes, such as those that occur during puberty, pregnancy, and menopause, can cause an increase in the production of oil in the skin. This oil can clog the pores and lead to the formation of pimples, blackheads, and whiteheads.
                        </li>
                        <li style="list-style: none;">✔ <b>Genetics:</b> Acne is often hereditary, meaning that if a parent had acne, their children may also be more likely to develop the condition. Some people simply have a greater sensitivity to the hormones that trigger acne, making them more susceptible to breakouts.
                        </li>
                        <li style="list-style: none;">✔  <b>Poor Skin Care Habits:</b> Using heavy or oily skin care products, not cleansing the skin properly, and not removing makeup before bed can contribute to the development of acne. Using too many products, or products that are too harsh, can also lead to dry, irritated skin, which can trigger an acne flare-up.
                        </li>
                    </ul>
                 </div>
            </div>
        </div>
    </div>
    <div class="history-section text-center py-5">
        <div class="container">
            <div class="row">
                <div class="">
                    <div class="pl-5">
                        <h4 class="title fw_600 text-dark mb-5">Solutions for Acne</h4>
                        <div class="row">
                            <div class="col-md-6">
                                <div>
                                    <img class="img-fluid w-50 rounded mb-5 mb-lg-0" src="../../../assets/images/service/antibiotics.jpg" >
                                    <div class="desc mt-3">Medications & antibiotics</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <img class="img-fluid w-50 rounded mb-5 mb-lg-0" src="../../../assets/images/service/topical.jpg" >
                                    <div class="desc mt-3">Topical Treatment</div>
                                </div>
                            </div>

                        </div>
                        <!-- <h6 class="fw_400 mt-3 desc">
                            <ol>
                                <li>Medications & antibiotics</li>
                                <li>Topical Treatment</li>
                            </ol>
                        </h6> -->
                    </div>
                 </div>
            </div>
        </div>
    </div>

</div>
