import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-nutrition-diet',
  templateUrl: './service-nutrition-diet.component.html',
  styleUrls: ['./service-nutrition-diet.component.scss']
})
export class ServiceNutritionDietComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
