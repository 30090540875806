<section class="wrapper-vaginal">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Vaginal Tightening</h1>
            <p class="fw-normal"><a href="index.html">Home</a> / Vaginal Tightening</p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100 info">
      <div class="row h-100">
        <div class="col-md-6 d-flex align-items-center">
          <div class="">
            <h1 class="fw-700">Putting the "O" <br> in Intimacy</h1>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/vaginal/vaginal-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Putting the "O" in Intimacy
      </h1>
    </div>
  </div>

  <div class="section-info container py-5">
    <h1 class="text-center -mt-5">Vaginal Tightening & Labia Rejuvenation</h1>
    <h4 class="text-center">by</h4>
    <img class="ultra-femme-logo mt-2" src="../../../assets/images/vaginal/ultra-femme-logo.png" alt="">
    <p class="text-center mt-5 col-12 col-lg-10 mx-auto">
      Ultra Femme 360 uses advanced radiofrequency (RF) technology to target and stimulate the rejuvenation and tightening of vaginal area tissues, leading to improved vaginal tone, increased collagen production, and enhanced overall vaginal health and appearance.
    </p>
  </div>

  <div class="section-exilis">

		<div class="a-logo-left">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>
    <div class="a-logo-right">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>

		<div class="container">
			<div class="row">
				<div class="col-12 col-md col-lg-6">
					<img class="tool-1-image" src="../../../assets/images/vaginal/tool-1.png" alt="">
				</div>
				<div class="col-12 col-md col-lg-6 mt-4">
					<img class="tool-2-image" src="../../../assets/images/vaginal/tool-2.png" alt="">
				</div>
			</div>

			<div class="col-12 col-lg-8 mx-auto mt-3 pt-5 pb-2">
				<p class="text-center">
          The procedure utilises 360° volumetric heating with a smooth in-and-out motion ensuring the faster, safer and maximum level of comfort of female intimate part. The groundbreaking Energy Flow Control (EFC) system with integrated Impedance Intelligence ensures uniform heating of targeted tissue.
				</p>
			</div>
		</div>
	</div>

  <div class="section-help container py-5 mt-5">
    <h1 class="text-center -mt-5">The Improvements That <br> Guarantee Your Confidence</h1>
  </div>

  <div class="section-determine mb-5">
    <img class="green-1" src="../../../assets/images/green-gradient.png" alt="">
    <img class="green-2" src="../../../assets/images/green-gradient.png" alt="">
    <div class="container text-center">
      <div class="title-area">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/vaginal/improve-1.png" alt="">
              <h6 class="card-title">Tighten the vaginal canal</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/vaginal/improve-2.png" alt="">
              <h6 class="card-title">Restore tightness to the vaginal opening</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/vaginal/improve-3.png" alt="">
              <h6 class="card-title">Enhance the plumpness of the labia majora skin</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/vaginal/improve-4.png" alt="">
              <h6 class="card-title">Enhance orgasmic experiences</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/vaginal/improve-5.png" alt="">
              <h6 class="card-title">Enhance sexual desire</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/vaginal/improve-6.png" alt="">
              <h6 class="card-title">Alleviate vaginal dryness and improve lubrication</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-services py-5">
		<div class="container text-center">
			<div class="title-area pb-3">
				<h4>Why Choose</h4>
				<div class="d-flex justify-content-center">
					<img
						class="ultra-femme-logo-2"
						src="../../../assets/images/vaginal/ultra-femme-logo.png"
						style="margin-top: 0px;"
					/>
				</div>
			</div>

      <img class="w-100 my-3" src="../../../assets/images/vaginal/leg-banner.png" alt="">

			<div class="row mt-4">
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../assets/images/vaginal/choose-1.png">
						</div>
						<h6 class="text-center mt-3">Various sizes for different patients</h6>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../assets/images/vaginal/choose-2.png">
						</div>
						<h6 class="text-center mt-3">Fastest RF treatment in the market</h6>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../assets/images/vaginal/choose-3.png">
						</div>
						<h6 class="text-center mt-3">360° volumetric heating</h6>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mb-4">
					<div class="card-icon">
						<div class="image">
							<img src="../../../assets/images/vaginal/choose-4.png">
						</div>
						<h6 class="text-center mt-3">Non invasive & no downtime</h6>
					</div>
				</div>
			</div>
		</div>
	</div>

  <div class="section-info container pb-3 pt-5">
    <h1 class="text-center -mt-5">Customer Testimonials</h1>
  </div>

  <div class="section-exilis testimonials mb-5">

		<div class="a-logo-left">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>
    <div class="a-logo-right">
      <img src="../../../../assets/images/exilis/a-logo-blue.png" alt="">
    </div>

		<div class="container">
			<div class="mb-5 pt-5 py-3">
				<div class="video-container">
					<iframe src="https://www.youtube.com/embed/O5KQZ0vXgiI"></iframe>
				</div>
			</div>
		</div>

    <div class="video-caption container mx-auto row pb-5">
      <div class="col-12 col-lg-4 p-0">
        <h2>Women's Wellness</h2>
      </div>
      <div class="col-12 col-lg-4 p-0">
        <h2 class="line">Intimate Health</h2>
      </div>
      <div class="col-12 col-lg-4 p-0">
        <h2>Feminine Well-Being</h2>
      </div>
    </div>
	</div>

</section>
