import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-sti-screening',
  templateUrl: './service-sti-screening.component.html',
  styleUrls: ['./service-sti-screening.component.scss']
})
export class ServiceStiScreeningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
