import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-about-clinic',
  templateUrl: './about-clinic.component.html',
  styleUrls: ['./about-clinic.component.scss']
})
export class AboutClinicComponent implements OnInit {

  isMobile = false;
  active = 1;

  constructor() { }

  ngOnInit(): void {
    this.initClientSlider();
    this.initSliderBlog();

    let width = window.innerWidth;
    if(width < 768) {
      this.isMobile = true;
    }
  }

  initClientSlider() {
    $('.testimonials-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }

  initSliderBlog() {
    $('.blog-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.prev_1'),
      nextArrow: $('.next_1'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }
}
