<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Doctor Home Visit</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Doctor Home Visit
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
        <div class="row h-100 d-flex align-items-center">
            <div class="col-md-6 info">
                <div class="">
                    <h1 class="fw-700 mt-5">
                      Personalized <br>
                      Healthcare Right <br>
                      To Your Doorstep
                    </h1>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
            </div>
        </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/doctor-home-visit-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Personalized Healthcare Right To Your Doorstep
      </h1>
    </div>
  </div>

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-area pb-5">
        <h2>Here’s some of the common things people ask us, for your clarity.</h2>
      </div>

      <div class="title-box">
        What Can I Do with Doctor Home Visit Service?
      </div>

      <div class="what-can-i-do row">

        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-1.png" alt="">
            </div>
            <div class="title">
              Basic health assessment and screening
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-2.png" alt="">
            </div>
            <div class="title">
              Acute illness management (fever, infection etc)
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-3.png" alt="">
            </div>
            <div class="title">
              Chronic disease management
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-4.png" alt="">
            </div>
            <div class="title">
              Vaccinations
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-5.png" alt="">
            </div>
            <div class="title">
              Infusion therapy
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-6.png" alt="">
            </div>
            <div class="title">
              Wound care
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-7.png" alt="">
            </div>
            <div class="title">
              Minor surgical procedures
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-4">
          <div class="card p-3">
            <div class="icon">
              <img src="../../../assets/images/doctor-home-visit/icon-8.png" alt="">
            </div>
            <div class="title">
              Pain management
            </div>
          </div>
        </div>

        <p class="info-text mt-3 col-12 mx-auto">
          (For other conditions that are not listed above, please contact us for more details.)
        </p>

      </div>
    </div>
  </div>

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-box">
        Why Choose Doctor Home Visit Service?
      </div>

      <div class="why-choose-doctor row px-3 pt-3">
        <div class="col-12 col-md-6 col-lg-3 p-0 mt-4">
          <div class="card card-half">
            <div class="number-circle number-green">
              <div class="inner">1</div>
            </div>
            <div class="content">
              <div>
                <h5>Convenience:</h5>
                <h6>No need to travel to a hospital or clinic, save time and energy.</h6>
              </div>
            </div>
            <img class="green-bottom" src="../../../assets/images/doctor-home-visit/green-half.png" alt="">
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 p-0 mt-4">
          <div class="card card-half card-even">
            <div class="number-circle number-blue">
              <div class="inner">2</div>
            </div>
            <div class="content">
              <div>
                <h5>Comfort:</h5>
                <h6>Patients can receive treatment in the comfort of their own home.</h6>
              </div>
            </div>
            <img class="blue-top" src="../../../assets/images/doctor-home-visit/blue-half.png" alt="">
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 p-0 mt-4">
          <div class="card card-half">
            <div class="number-circle number-brown">
              <div class="inner">3</div>
            </div>
            <div class="content">
              <div>
                <h5>Flexibility:</h5>
                <h6>Services can be scheduled at a time that is convenient for the patient.</h6>
              </div>
            </div>
            <img class="green-bottom" src="../../../assets/images/doctor-home-visit/brown-half.png" alt="">
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 p-0 mt-4">
          <div class="card card-half card-even">
            <div class="number-circle number-gray">
              <div class="inner">4</div>
            </div>
            <div class="content">
              <div>
                <h5>Reduced risk of exposure:</h5>
                <h6>Reduced risk of exposure to other patients in a hospital or clinic setting.</h6>
              </div>
            </div>
            <img class="blue-top" src="../../../assets/images/doctor-home-visit/gray-half.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-box">
        How to Book a Doctor Home Visit Service?
      </div>

      <div class="book-step mt-4 col-12 col-md-10 col-lg-7 mx-auto">
        <h5 class="title">Experience Convenient Care with Our 2-Step Home Visit Booking Process:</h5>
        <div class="step-box">
          Book An Appointment.
          <span class="circle-box">1</span>
          <span class="dotted-line"></span>
        </div>
        <h5 class="description">
          At Nadi website, scroll down and click <br>
          <b>"GET IN TOUCH NOW"</b> or <b>"BOOK AN APPOINTMENT"</b> button
          to submit your details and request.
        </h5>

        <div class="step-box">
          Receive Convenient Care
          <span class="circle-box">2</span>
        </div>
        <h5 class="description">
          Experience top-notch medical care in the comfort of your own home with Nadi's professional doctor consultations and personalized treatments.
          <br><br>
          After every visit, our doctor will ensure a seamless after-visit experience with personalized follow-up care and expert advice.
        </h5>
      </div>

    </div>
  </div>

  <!-- <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/service-home-visit.jpg"
          />
        </div>
        <div class="col-md-6">
          <div class="pl-5">
            <h1 class="fw-700 mb-3 text-black">Doctor Home Visit</h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              We bring personalized healthcare right to your doorstep,
              eliminating the need for you to leave your home. With a simple
              click to book a doctor, our services ensure that you can enjoy the
              medical support you need in the comfort of your own environment.
              Our certified doctors prioritise your well-being, providing
              attentive care and ensuring that you receive the attention and
              treatment you deserve. Experience the convenience and peace of
              mind that comes with our in-home healthcare services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="section-faq desc py-5">
    <div class="container">
      <div class="title-area">
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is Doctor Home Visit Service?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
                Doctor home visit service is a convenient option for those who are unable to visit a hospital or clinic, or who wants to skip long queue and get immediate treatments. These services are provided by medical professionals who travel to a patient's home to perform necessary medical procedures or treatments.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What Can I Do with Doctor Home Visit Service?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <ul>
                <li>Basic health assessment and screening</li>
                <li>Acute illness management (fever, infection etc)</li>
                <li>Chronic disease management</li>
                <li>Vaccinations</li>
                <li>Infusion therapy</li>
                <li>Wound care</li>
                <li>Minor surgical procedures</li>
                <li>Pain management</li>
              </ul>
              (For other conditions that are not listed above, please contact us for more details.)
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Why Choose Doctor Home Visit Service?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul>
                    <li>Convenience: No need to travel to a hospital or clinic, save time and energy.</li>
                    <li>Comfort: Patients can receive treatment in the comfort of their own home.</li>
                    <li>Flexibility: Services can be scheduled at a time that is convenient for the patient.</li>
                    <li>Reduced risk of exposure: Reduced risk of exposure to other patients in a hospital or clinic setting.</li>
                </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How to Book a Doctor Home Visit Service?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
                Experience Convenient Care with Our 2-Step Home Visit Booking Process:<br>
                1. Book An Appointment. <br>
                At Nadi website, scroll down and click "GET IN TOUCH NOW" or "BOOK AN APPOINTMENT" button to submit your details and request. <br>
                2. Receive Convenient Care <br>
                Experience top-notch medical care in the comfort of your own home with Nadi's professional doctor consultations and personalized treatments. <br>
                After every visit, our doctor will ensure a seamless after-visit experience with personalized follow-up care and expert advice.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div> -->

</div>