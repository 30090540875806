import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm!: FormGroup;
  formError = false;
  closeResult = '';
  modalTitle = '';
  modalDescription = '';
  modalIcon = '';
  loading = false;

  @ViewChild('submitModal', { static: false }) submitModal: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.contactForm = this.formBuilder.group({
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			email: ['', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
			phone: ['', Validators.required],
			message: ['', Validators.required],
    })
  }

  submitForm() {    
    if(this.contactForm.valid) {
      this.formError = false;
      this.loading = true;

      fetch("https://formsubmit.co/ajax/nadiclinic@nadimeditech.com", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          first_name: this.contactForm.controls['first_name'].value,
          last_name: this.contactForm.controls['last_name'].value,
          email: this.contactForm.controls['email'].value,
          phone: this.contactForm.controls['phone'].value,
          message: this.contactForm.controls['message'].value,
        })
      })
      .then(response => {
        if(response.status == 200) {
          this.loading = false;
          this.contactForm.reset({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message: ''
          });
          this.showSuccessModal();
        } 
        else {
          this.loading = false;
          this.showErrorModal();
        }
      })
      .catch(error =>{
        this.loading = false;
        this.showErrorModal();
      });
    }
    else {
      this.formError = true;
    }
  }

  open(content) {
		this.modalService.open(content, { 
      ariaLabelledBy: 'modal-contact',
      modalDialogClass: 'modal-contact',
      centered: true 
    }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  showSuccessModal() {
    this.modalTitle = "Success";
    this.modalDescription = "Your submission has been sent.";
    this.modalIcon = "../../../assets/images/success-icon.png";
    this.open(this.submitModal);
  }

  showErrorModal() {
    this.modalTitle = "Error";
    this.modalDescription = "There was an error trying to send your message. Please try again.";
    this.modalIcon = "../../../assets/images/error-icon.png";
    this.open(this.submitModal);
  }
}
