<div class="wrapper-services">
    <header class="py-5 inner-header" style="background:url('../../../assets/images/services.png')no-repeat center center;">
        <div class="header-overlay"></div>
        <div class="container py-4 px-5">
           <div class="row align-items-center justify-content-center">
              <div class="col-lg-12">
                 <div class="text-center">
                    <h1 class="fw_700 text-white">Sexual Health</h1>
                    <p class="fw-normal text-white-50 mb-0"><a class="text-white" href="index.html">Home</a> / <a class="text-white">Services</a> / Sexual Health</p>
                 </div>
              </div>
           </div>
        </div>
      </header>
    <div class="history-section bg-gray py-5">
        <div class="container p-5">
            <div class="row">
                <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/sexual-health.jpg" >
                </div>
                <div class="col-md-6">
                    <div class="pl-5">
                        <p class="text-uppercase text_blue_1 mb-2">Our Services</p>
                        <h1 class="fw-700 mb-3 text-black">Sexual Health</h1>
                        <p class="fs-6 fw-light text-muted mb-0 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><br>
                        
                      </div>
                 </div>
                
            </div>
        </div>
    </div>
    <div class="section-services py-5">
        <div class="container p-5">
            <div class="row mt-3">
                <div class="col-md-6">
                    <div class="title-area">
                        <p class="text-uppercase text_blue_1">For him</p>
                        <h1 class="title fw_600 text-dark mt-3">
                            Sexually Transmitted Infections (STIs)
                        </h1>
                        <h6 class="fw_400 mt-3 desc">
                            Sexually Transmitted Infections (STIs) are a group of infections that are spread from person to person through sexual contact. These infections can be caused by bacteria, viruses, or parasites and can be transmitted through sexual activity, including vaginal, anal, or oral sex. Some common STIs include chlamydia, gonorrhea, syphilis, herpes, and human papillomavirus (HPV).
                        </h6>
                    </div>
                </div>
                <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/man.jpg" >
                </div>
            </div>
            <div class="title-area">
                
                

                <h4 class="title fw_600 text-dark mt-5">Enhance Your Body Awareness Through Screening</h4>
                <h6 class="fw_400 mt-3 desc">STIs Screening Options:
                    <ul>
                        <li style="list-style: auto;">Chlamydia</li>
                        <li style="list-style: auto;">Gonorrhea</li>
                        <li style="list-style: auto;">Syphilis</li>
                        <li style="list-style: auto;">Herpes simplex virus (HSV)</li>
                        <li style="list-style: auto;">Human immunodeficiency virus (HIV)</li>
                        <li style="list-style: auto;">Human papillomavirus (HPV)</li>
                        <li style="list-style: auto;">Trichomoniasis</li>
                        <li style="list-style: auto;">Hepatitis B and C</li>
                        <li style="list-style: auto;">Bacterial vaginosis (BV)</li>
                    </ul>
                    Screening methods may include blood tests, urine tests, and swab tests, depending on the type of infection.
                </h6>
                
                <h4 class="title fw_600 text-dark mt-5">Pre-Exposure Prophylaxis (PrEP)</h4>
                <h6 class="fw_400 mt-3 desc">
                    <ul>
                        <li style="list-style: none;">✔ Preventive medication for HIV</li>
                        <li style="list-style: none;">✔ Works by blocking the virus, reducing the likelihood of an individual becoming infected with HIV</li>
                        <li style="list-style: none;">✔ Aims to reduce the risk of HIV infection</li>
                        <li style="list-style: none;">✔ Taken on daily basic</li>
                        <li style="list-style: none;">✔ Suitable for individuals engaging in unprotected sex, sharing needles, having multiple partners, or in a sexual relationship with a HIV-positive partner</li>
                    </ul>
                    
                </h6>
            
                <h4 class="title fw_600 text-dark mt-5">Revitalize Your Sexuality: A Comprehensive Erectile Dysfunction (ED) Solution</h4>
                <h6 class="fw_400 mt-3 desc">Erectile Dysfunction (ED) can be a difficult and embarrassing problem for many men, but help is available. At NADI, our sexual health expert doctors will design an effective treatment program helping men overcome ED and regain your confidence. The program is completely save and non-invasive method that helps to improve blood flow to the penis, and highly effective in treating ED symptoms and restoring your sexual function.
                    <ul>
                        <li style="list-style: none;">✔  Improved blood flow</li>
                        <li style="list-style: none;">✔  Increased sexual desire</li>
                        <li style="list-style: none;">✔  Increased confidence</li>
                        <li style="list-style: none;">✔  Improved sexual performance</li>
                    </ul>
                </h6>
                


            </div>
        </div>     
    </div>
    <div class="section-services">
        <div class="container p-5">
            <div class="row">
                <div class="col-md-6">
                    <img class="img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/service/woman.jpg" >
                </div>
                <div class="col-md-6">
                    <div class="title-area">
                        <p class="text-uppercase text_blue_1">For her</p>
                        <h1 class="title fw_600 text-dark mt-3">
                            Vaginal Tightening by BTL Emsella
                        </h1>
                        <h4 class="title fw_600 text-dark mt-3">Say "NO" to Vaginal Laxity!</h4>
                        
                        <h6 class="fw_400 mt-3 desc">
                            BTL Emsella uses electromagnetic energy to stimulate and strengthen the pelvic floor muscles. The device emits high-intensity electromagnetic waves to stimulate the pelvic floor muscles, causing them to contract and relax, thereby improving muscle tone. It does not involve any surgical incisions or anesthesia and is a non-invasive treatment for vaginal tightening.
                        </h6>
                    </div>
                </div>
                
            </div>
            <div class="title-area mt-5">
                
                <h4 class="title fw_600 text-dark mt-3">Why Choose BTL Emsella?</h4>
                <ul class="desc">
                    <li style="list-style: none;">✔ Non-invasive </li>
                    <li style="list-style: none;"> ✔ Fully-clothed during treatment</li>
                    <li style="list-style: none;">✔ Short treatment duration (30mins) and no downtime</li>
                    <li style="list-style: none;">✔ FDA approved </li>
                    <li style="list-style: none;">✔ Clinically proven to be safe and effective</li>
                </ul>
                
            </div>
        </div>
    </div>
</div>
