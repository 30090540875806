<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Slimming</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Slimming
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
      <div class="row h-100 d-flex align-items-center">
        <div class="col-md-6 info">
          <div class="">
            <h1 class="fw-700 mt-5">
              Regain Your <br> Confidence
            </h1>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/slimming/slimming-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Regain Your Confidence
      </h1>
    </div>
  </div>

  <div class="section-help container py-5">
    <h4 class="text-center">What is</h4>
    <h1 class="text-center -mt-5">S-Pen?</h1>
  </div>

  <div class="section-s-pen py-5">

    <div class="a-logo-left">
      <img src="../../../assets/images/slimming/a-logo-brown.png" alt="">
    </div>
    <div class="a-logo-right">
      <img src="../../../assets/images/slimming/a-logo-brown.png" alt="">
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <img class="pen-image" src="../../../assets/images/slimming/s-pen.svg" alt="">
        </div>
        <div class="col-12 col-lg-6">
          <div>
            <p>
              A prescription formula approved by the U.S. Food and Drug Administration (FDA) for weight management. <br><br>
              It works by mimicking a hormone called glucagon-like peptide-1 (GLP-1)
            </p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-4">
                <div class="card-round">
                  <img src="../../../assets/images/slimming/formula-1.png" alt="">
                  <h6>Reduces appetite</h6>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4">
                <div class="card-round">
                  <img src="../../../assets/images/slimming/formula-2.png" alt="">
                  <h6>Slows down stomach emptying</h6>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4">
                <div class="card-round">
                  <img src="../../../assets/images/slimming/formula-3.png" alt="">
                  <h6>blood sugar levels</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-help container py-5 mt-5">
    <h1 class="text-center -mt-5">XFit360 Program with S-Pen</h1>
    <p class="text-center col-12 col-lg-8 mx-auto mt-5">
      With the collaborative consultation with professional healthcares such as doctors, dietitians, and fitness trainers, the S-Pen can be a powerful tool to support your healthy weight loss goals.
    </p>
  </div>

  <div class="section-determine">
    <img class="green-1" src="../../../assets/images/green-gradient.png" alt="">
    <img class="green-2" src="../../../assets/images/green-gradient.png" alt="">
    <div class="container text-center">
      <div class="title-area">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/slimming/program-1.png" alt="">
              <h6 class="card-title">Full doctor assessment</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/slimming/program-2.png" alt="">
              <h6 class="card-title">Blood screening & intepretation</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/slimming/program-3.png" alt="">
              <h6 class="card-title">Saxenda 1 month supply</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/slimming/program-4.png" alt="">
              <h6 class="card-title">Weekly dietitian consultation</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/slimming/program-5.png" alt="">
              <h6 class="card-title">2 x Physical fitness coaching session</h6>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-5">
            <div class="card card-round">
              <img class="card-image" src="../../../assets/images/slimming/program-6.png" alt="">
              <h6 class="card-title">1 x Virtual fitness coaching session</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="wrapper-services mt-5">
  <div class="section-testimonials" id="testimonials">
    <div class="container">
      <div class="row mb-5 align-items-center">
        <div class="col-md-12 col-12">
          <div class="title-area">
            <h1 class="title fw_600 text-center mt-3 font-berlingske">
              What Our Customers Say ...
            </h1>
          </div>
        </div>
      </div>

      <div class="testimonials-slider">
        <div class="item"> 
          <div class="inner">
            <div class="comments">
              <p>
                After only a few sessions with BTL Emsella, I noticed a
                significant improvement in my bladder control. I no longer had
                to worry about sudden leaks or running to the bathroom every few
                minutes. I felt more confident and comfortable in my own skin,
                and I'm so grateful to have found this treatment.
              </p>
            </div>
            <div class="user">
              <div class="image">
                <img src="../../../assets/images/home/brooklyn-simmons.jpg" alt="">
              </div>
              <div class="info"> 
                <div class="name">Rachel</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item"> 
          <div class="inner">
            <div class="comments">
              <p>
                I was hesitant to try BTL Emsella at first, but I'm so glad I did. 
                The treatment was comfortable and efficient, and the results have been amazing. 
                I no longer have to worry about embarrassing leaks or feeling self-conscious 
                about my bladder control. I feel like I've regained control over my body, and 
                it's an incredible feeling.
              </p>
            </div>
            <div class="user">
              <div class="image">
                <img src="../../../assets/images/home/brooklyn-simmons.jpg" alt="">
              </div>
              <div class="info"> 
                <div class="name">Laura</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item"> 
          <div class="inner">
            <div class="comments">
              <p>
                BTL Emsella was a game-changer for me. I've tried all sorts of treatments 
                for urinary incontinence in the past, but nothing has worked as well as this. 
                The treatment was easy and painless, and the results have been life-changing. 
                I feel more confident and empowered than ever before.
              </p>
            </div>
            <div class="user">
              <div class="image">
                <img src="../../../assets/images/maria.png" alt="">
              </div>
              <div class="info"> 
                <div class="name">Maria</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
