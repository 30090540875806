<div class="wrapper-about">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Vaccination</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Vaccination
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
        <div class="row h-100 d-flex align-items-center">
            <div class="col-md-6 info">
                <div class="">
                    <h1 class="fw-700 mt-5">
                      Vaccinate For A <br> Safer Tomorrow
                    </h1>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
            </div>
        </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/vaccination-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Vaccinate For A Safer Tomorrow
      </h1>
    </div>
  </div>

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-area pb-5">
        <h2>Vaccines Available in NADI</h2>
      </div>

      <div class="honeycomb">
        <div class="ibws-fix">
          <div class="hexagon comb-green">
            <div class="hexagontent">MMR Vaccine</div>
          </div>
          <div class="hexagon comb-blue">
            <div class="hexagontent">HPV Vaccine</div>
          </div>
          <div class="hexagon comb-yellow">
            <div class="hexagontent">Hexaxim Vaccine</div>
          </div>
          <div class="hexagon comb-gray">
            <div class="hexagontent">Meningococcal Vaccine</div>
          </div>
          <div class="hexagon comb-blue">
            <div class="hexagontent">Hepatitis A Vaccine</div>
          </div>
        </div>
        <div class="ibws-fix">
          <div class="hexagon comb-yellow">
            <div class="hexagontent">Pneumococcal Vaccine</div>
          </div>
          <div class="hexagon comb-gray">
            <div class="hexagontent">Hepatitis B Vaccine</div>
          </div>
          <div class="hexagon comb-green">
            <div class="hexagontent">Chickenpox Vaccine</div>
          </div>
          <div class="hexagon comb-blue">
            <div class="hexagontent">Tdap Vaccine</div>
          </div>
          <div class="hexagon comb-green">
            <div class="hexagontent">Influenza (flu) Vaccine</div>
          </div>
        </div>
        <div class="ibws-fix">
          <div class="hexagon comb-yellow invisible">
            <div class="hexagontent"></div>
          </div>
          <div class="hexagon comb-gray invisible">
            <div class="hexagontent"></div>
          </div>
          <div class="hexagon comb-yellow">
            <div class="hexagontent">Travelor's Vaccine</div>
          </div>
          <div class="hexagon comb-blue invisible">
            <div class="hexagontent"></div>
          </div>
          <div class="hexagon comb-green invisible">
            <div class="hexagontent"></div>
          </div>
        </div>
      </div>

      <div class="button-action  text-center">
        <a class="btn btn-primary px-3 py-2" href="https://wa.me/+60126468392" target="_blank"> 
          <i class="bi bi-calendar3 mr-1"></i> Book Appoinment
        </a>
      </div>

      <p class="info-text mt-3 col-6 col-lg-3 mx-auto">
        Kindly schedule an appointment with our doctor for vaccination.
      </p>

    </div>
  </div>

  <!-- <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/service-01.jpeg"
          />
        </div>
        <div class="col-md-6">
          <div class="pl-5">
            <h1 class="fw-700 mb-3 text-black">Vaccination</h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              We do provide various types of vaccines to our patients based on
              age, health condition, and immunization requirements.Our doctor
              will determine the most appropriate vaccines based on individual
              factors and guidelines from Centers for Disease Control and
              Prevention (CDC) and the World Health Organization (WHO). If you
              have specific concerns or need information about a particular
              vaccine, it is recommended to book a teleconsultation session with
              our doctor or may visit to our clinic.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-area pb-5">
        <h2>Vaccines Available in NADI</h2>
      </div>
      <div class="row">
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                MMR vaccine: Protects against measles, mumps, and rubella.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Polio vaccine: Prevents poliomyelitis, a viral disease that can cause paralysis.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Influenza (flu) vaccine: Guards against seasonal influenza viruses.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Hepatitis A vaccine: Provides immunity against hepatitis A virus, which spreads through contaminated food or water.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Hepatitis B vaccine: Protects against hepatitis B virus, a leading cause of liver infection.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Tdap vaccine: Prevents tetanus, diphtheria, and pertussis (whooping cough) bacterial infections.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                HPV vaccine: Guards against certain strains of human papillomavirus, reducing the risk of cervical and other cancers.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Pneumococcal vaccine: Protects against Streptococcus pneumoniae bacteria, which can cause pneumonia and meningitis.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Meningococcal vaccine: Prevents meningococcal meningitis, a bacterial infection affecting the brain and spinal cord.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card nadi-border">
            <div class="card-img">
              <img
                class="card-img-top"
                src="https://fakeimg.pl/600x400?font=noto"
              />
            </div>
            <div class="card-body">
              <p class="mb-0 desc">
                Chickenpox vaccine: Provides immunity against varicella-zoster virus, which causes chickenpox.
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="text-left">Kindly schedule an appointment with our doctor for vaccination.</p>
    </div>
  </div> -->
</div>
