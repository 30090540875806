<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Teleconsultation</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Teleconsultation
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
        <div class="row h-100 d-flex align-items-center">
            <div class="col-md-6 info">
                <div class="">
                    <h1 class="fw-700 mt-5">
                      We Are Just <br> One Click Away!
                    </h1>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
            </div>
        </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/teleconsultation-banner.png" alt="">
    <div class="mt-4">
        <h1 class="fw-700">
          We Are Just One Click Away!
        </h1>
    </div>
  </div>

  <!-- <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/service-01.jpeg"
          />
        </div>
        <div class="col-md-6">
          <div class="pl-5">
            <h1 class="fw-700 mb-3 text-black">Teleconsultation</h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              We provide a seamless and efficient way for you to discuss your
              health concerns, receive expert medical advice, and obtain
              prescriptions when necessary. You are able to embrace the
              flexibility of teleconsultation and experience quality healthcare
              without the need to travel or wait in a traditional clinic
              setting.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-area pb-5">
        <h2>Services available</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-5 d-flex align-items-center">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/doctor-teleconsultation.png"
          />
        </div>
        <div class="col-12 col-md-7">
          <div>
            <h1 class="fw-700 mb-3 text-black text-left">
              Doctor Teleconsultation
            </h1>
            <div class="card card-info text-left mb-3">
              Medical history assessment and current symptoms review.
            </div>
            <div class="card card-info text-left mb-3">
              Provide a diagnosis for a medical condition or illness.
            </div>
            <div class="card card-info text-left mb-3">
              Prescribe medications or recommend over-the-counter treatments.
            </div>
            <div class="card card-info text-left mb-3">
              Interpret lab test results and imaging studies.
            </div>
            <div class="card card-info text-left mb-3">
              Discuss treatment options and develop a treatment plan with the
              patient.
            </div>
            <div class="card card-info text-left mb-3">
              Provide education on a medical condition, including prevention,
              management, and self-care strategies.
            </div>
            <div class="card card-info text-left mb-3">
              Health and wellness education and counseling.
            </div>
            <div class="card card-info text-left mb-3">
              Refer the patient to a specialist or other healthcare professional
              if necessary.
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 col-md-7 d-flex align-items-center">
          <div class="pl-4">
            <h1 class="fw-700 mb-3 text-black text-left">
              Dietitian Teleconsultation
            </h1>
            <div class="card card-info text-left mb-3">
              Nutrition assessment and counseling
              <!-- <span class="fw-600">Nutrition assessment and counseling</span> -->
              <!-- Our dietitians can assess your current diet and provide personalized nutrition counseling to help you achieve optimal health. -->
            </div>
            <div class="card card-info text-left mb-3">
              Personalized nutrition plans
              <!-- <span class="fw-600">Personalized nutrition plans</span> -->
              <!-- We develop customized nutrition plans based on your individual needs, preferences, and lifestyle to help you achieve your goals. -->
            </div>
            <div class="card card-info text-left mb-3">
              Education and guidance
              <!-- <span class="fw-600">Education and guidance</span> -->
              <!-- We provide education and guidance on healthy eating habits, portion control, meal planning, grocery shopping, and more to help you make sustainable lifestyle changes. -->
            </div>
            <div class="card card-info text-left mb-3">
              Specialized nutrition care
              <!-- <span class="fw-600">Specialized nutrition care</span> -->
              <!-- Our dietitians provide specialized nutrition care for specific health conditions, such as diabetes, heart disease, weight management and more. -->
            </div>
            <div class="card card-info text-left mb-3">
              Progress monitoring and support
              <!-- <span class="fw-600">Progress monitoring and support</span> -->
              <!-- We offer ongoing support and monitoring to help you stay on track with your nutrition goals and make long-term changes to your health. -->
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 d-flex align-items-center">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border ml-5 ma-0 md-ml-0"
            src="../../../assets/images/dietitian-teleconsultation.png"
          />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 col-md-5 d-flex align-items-center">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/pharmacist-teleconsultation.png.png"
          />
        </div>
        <div class="col-12 col-md-7">
          <div>
            <h1 class="fw-700 mb-3 text-black text-left">
              Pharmacist Teleconsultation
            </h1>
            <div class="card card-info text-left mb-3">
              Medication counseling and education.
            </div>
            <div class="card card-info text-left mb-3">
              Patient's medication regimen review.
            </div>
            <div class="card card-info text-left mb-3">
              Queries on over-the-counter medications, supplements, and herbal remedies.
            </div>
            <div class="card card-info text-left mb-3">
              Proper medication storage and disposal guidance.
            </div>
            <!-- <div class="card card-info text-left mb-3">
              Prescription refills or transfers.
            </div> -->
            <div class="card card-info text-left mb-3">
              Medication management of chronic conditions.
            </div>
            <div class="card card-info text-left mb-3">
              Develop medication adherence plan and strategies.
            </div>
            <!-- <div class="card card-info text-left mb-3">
              Cost-saving options for medications, such as generic alternatives or patient assistance programs.
            </div>
            <div class="card card-info text-left mb-3">
              Identify and report adverse drug reactions and medication errors.
            </div> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
