<div class="wrapper-home">
  <div class="container">
    <div class="section-slider">
      <div class="slider">
          <div class="slider-box">

                  <div class="single-item" >

                        <div class="container">
                          <div class="single-item-inner col-12 col-md-6 col-xl-4">
                            <h2 class="font-primary-regular">Find Your Path to Optimal Health</h2>
                            <p>
                              Nadi Clinic provides comprehensive healthcare solutions that prioritise <b>identifying and addressing the underlying root causes</b> of your health issues. We aim to not only alleviate symptoms but also promote overall wellness and prevent future health issues.
                            </p>
                            <a href="https://wa.me/+60126468392" target="_blank" class="btn btn-learn-more mt-3 btn-slider-1">
                              <h6 class="text">BOOK NOW</h6>
                              <div class="arrow-icon mt-1">
                                <span class="chevron down"></span>
                              </div>
                            </a>
                        </div>

                        </div>
                        <div class="slider-img-container">
                          <img src="../../../assets/images/new/slider-demo-1.png" class="img-fluid">
                        </div>
                  </div>
                  <div class="single-item" >

                    <div class="container">
                      <div class="single-item-inner col-12 col-md-6 col-xl-4">
                        <h2 class="font-primary-regular">Unleash Your Best Self!</h2>
                        <!-- <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga sapiente rem fugit aliquid recusandae tempora dolorum esse suscipit atque veritatis. </p> -->
                        <button routerLink="/exilis-ultra" class="btn btn-learn-more mt-3 btn-slider-1">
                          <h6 class="text">FIND OUT MORE</h6>
                          <div class="arrow-icon mt-1">
                            <span class="chevron down"></span>
                          </div>
                        </button>
                    </div>

                    </div>
                    <div class="slider-img-container">
                      <img src="../../../assets/images/home-banner-2.png" class="img-fluid">
                    </div>
              </div>
              <div class="single-item" >

                <div class="container">
                  <div class="single-item-inner col-12 col-md-6 col-xl-4">
                    <h2 class="font-primary-regular">High-Quality Medical Care Without Leaving Home</h2>
                    <p class="content">Book a doctor home visit service and receive:</p>
                    <ul class="desc pl-1 mt-0 text-left">
                      <li style="list-style: none">✔ Treatment from fully certified medical doctors</li>
                      <li style="list-style: none">✔ Personalized advice, solutions, and treatment options</li>
                      <li style="list-style: none">✔ 100% customer satisfaction guarantee</li>
                    </ul>
                    <a class="btn btn-learn-more mt-3 btn-more-padding ml-5 btn-slider-3"
                      href="https://wa.me/+60126468392" target="_blank"
                    >
                      <h6 class="text">BOOK AN APPOINTMENT</h6>
                      <div class="arrow-icon mt-1">
                        <span class="chevron down"></span>
                      </div>
                    </a>
                </div>

                </div>
                <div class="slider-img-container">
                  <img src="../../../assets/images/home-banner-3.png" class="img-fluid">
                </div>
          </div>



          </div>
          <!-- <button class="slider-btn prev-0">
            <span class="chevron left mx-3"></span>
          </button>
          <button class="slider-btn next-0">
            <span class="chevron right"></span>
          </button> -->
      </div>
  </div>
  </div>


  <!-- <div class="scrolling-text-section">
    <div class="carouselTicker">
      <ul class="carouselTicker__list">
        <li class="carouselTicker__item">Professional Doctors</li>
        <li class="carouselTicker__item">Client Centric</li>
        <li class="carouselTicker__item">Personalised Solutions</li>
        <li class="carouselTicker__item">Customer Satisfaction</li>
        <li class="carouselTicker__item">Professional Doctors</li>
        <li class="carouselTicker__item">Client Centric</li>
        <li class="carouselTicker__item">Personalised Solutions</li>
        <li class="carouselTicker__item">Customer Satisfaction</li>
      </ul>
    </div>
  </div> -->

  <div class="section-about py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 my-5 aling-self-center text-center">
            <div class="title-area">
              <h1 class="title mb-4 font-primary-regular font-color-primary">NADI Empowers<br> Your Seamless Journey To Wellness</h1>
              <!-- <h6 class="mt-2 desc  px-md-5 px-3">
                We aim to remove the barriers and create a cohesive experience that addresses the root causes of health concerns. By embracing the interconnected nature of well-being, we empower you to take an active role in your health and unlock your true potential for vitality and thriving in Nadi.
              </h6> -->
              <div class="mt-4">
                <!-- <button class="btn btn-outline-primary">Sign In</button> -->
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="container-fluid">
        <div class="service-links">
          <div class="service-item" routerLink="/service-doctor-home-visit">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service06.png">
            </div>
            <div class="service-name font-primary-semibold">
              Doctor Home Visit
            </div>
          </div>
          <div class="service-item" routerLink="/service-teleconsultation">
            <div class="service-item-img">
              <img src="../../../assets/images/home-new/teleconsultation.png">
            </div>
            <div class="service-name font-primary-semibold">
              Teleconsultation
            </div>
          </div>
          <div class="service-item" routerLink="/service-urinary-incontinence">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service02.png">
            </div>
            <div class="service-name font-primary-semibold">
              Emsella
            </div>
          </div>
          <div class="service-item" routerLink="/exilis-ultra">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service04.png">
            </div>
            <div class="service-name font-primary-semibold">
              Exilis Ultra 360
            </div>
          </div>
          <div class="service-item" routerLink="/service-cellular-therapy">
            <div class="service-item-img">
              <img src="../../../assets/images/home-new/cellular-therapy.png">
            </div>
            <div class="service-name font-primary-semibold">
              Cellular Therapy
            </div>
          </div>
          <div class="service-item" routerLink="/service-vaginal-tightening">
            <div class="service-item-img">
              <img src="../../../assets/images/home-new/vaginal-tightening.png">
            </div>
            <div class="service-name font-primary-semibold">
              Vaginal Tightening
            </div>
          </div>
          <div class="service-item" routerLink="/service-revive-therapy">
            <div class="service-item-img">
              <img src="../../../assets/images/home-new/revive-therapy.png">
            </div>
            <div class="service-name font-primary-semibold">
              Rejuvenation Therapy
            </div>
          </div>
          <!-- <div class="service-item" routerLink="/service-general">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service03.png">
            </div>
            <div class="service-name font-primary-semibold">
              Medical Consultation
            </div>
          </div>
          <div class="service-item" routerLink="/service-teleconsultation">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service04.png">
            </div>
            <div class="service-name font-primary-semibold">
              Teleconsultation
            </div>
          </div>
          <div class="service-item" routerLink="/service-vaccination">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service04.png">
            </div>
            <div class="service-name font-primary-semibold">
              Vaccination
            </div>
          </div>
          <div class="service-item" routerLink="/service-slimming">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service04.png">
            </div>
            <div class="service-name font-primary-semibold">
              Slimming
            </div>
          </div>
          <div class="service-item" routerLink="/service-cellular-therapy">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service04.png">
            </div>
            <div class="service-name font-primary-semibold">
              Cellular Therapy
            </div>
          </div>
          <div class="service-item" routerLink="/service-vaginal-tightening">
            <div class="service-item-img">
              <img src="../../../assets/images/new/service04.png">
            </div>
            <div class="service-name font-primary-semibold">
              Vaginal Tightening
            </div>
          </div> -->
        </div>
      </div>
      <div class="slider-green-arrow green-arrow-left invisible" [class.visible]="isLeftArrow">
        <div class="chevron"></div>
      </div>
      <div class="slider-green-arrow green-arrow-right" (click)="onRightArrow()">
        <div class="chevron"></div>
      </div>
  </div>
  <section class="section-usp">
      <div class="">
          <div class="container">
              <div class="col-md-12">
                <h1 class="title  mt-3 font-primary-regular font-color-primary">
                  Personalised Healthcare <br>
experience at NADI
                </h1>
              </div>

          </div>

          <div class="personalised-slider mt-4">
            <div class="usp-card mr-4">
              <img src="../../../assets/images/home-new/professional-doctor.png" class="usp-card-img">
              <div class="usp-body">
                <div class="usp-title font-primary-regular">
                  Professional Doctors
                </div>
                <div class="usp-desc">
                  We have fully certified medical and aesthetic doctors with years of experience and are highly skilled in maximizing patient wellness and beauty.
                </div>
              </div>
            </div>
            <div class="usp-card mr-4">
              <img src="../../../assets/images/new/usp2.png" class="usp-card-img">
              <div class="usp-body">
                <div class="usp-title font-primary-regular">
                  Client Centric
                </div>
                <div class="usp-desc">
                  We create the best experience at every stage of the customer journey, putting our clients at the heart of everything we do.
                </div>
              </div>
            </div>
            <div class="usp-card mr-4">
              <img src="../../../assets/images/home-new/personalised-solutions.png" class="usp-card-img">
              <div class="usp-body">
                <div class="usp-title font-primary-regular">
                  Personalised Solutions
                </div>
                <div class="usp-desc">
                  We offer tailored solutions based on a deep understanding of our clients' unique needs, expectations, and goals for optimal health and beauty outcomes.
                </div>
              </div>
            </div>
            <div class="usp-card">
              <img src="../../../assets/images/home-new/customer-satisfaction.png" class="usp-card-img">
              <div class="usp-body">
                <div class="usp-title font-primary-regular">
                  Customer Satisfaction
                </div>
                <div class="usp-desc">
                  We strive for unsurpassed customer satisfaction by providing personalised care and exceptional services that meet or exceed expectations.
                </div>
              </div>
            </div>
          </div>

      </div>

    </section>
    <section class="our-clinic-new">

      <div class="section-experience row">
        <div class="col-12 col-lg-4 left">
          <div class="img-left">
            <img src="../../../assets/images/home-new/experience.png" class="img-big">
            <div class="img-round">
              <img src="../../../assets/images/new/clinic-green-empty.png" class="img">
              <h6>Premium <br> Comfort</h6>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8 right">
          <div class="img-right">
            <img src="../../../assets/images/home-new/experience-2.png" class="img-big">
            <img src="../../../assets/images/new/nadi-a-bg.png" class="img-logo">
          </div>
          <div class="title-area">
            <h1 class="title fw_600 text-dark mt-3 font-primary-regular font-color-primary col-12 col-lg-6 p-0">
              Experience The Vanguard of Wellness
            </h1>
            <p class="desc col-12 col-lg-9 p-0">
              Nadi as a primary healthcare provider emphasizes preventive medicine through a holistic approach and utilizes the latest medical advancements in regenerative medicine. We aim to promote overall health and well-being for our clients through innovative and comprehensive approach to healthcare. In addition to our focus on preventive and regenerative medicine, NADI also offers a range of cutting-edge aesthetic treatments to help our clients look and feel their best.
            </p>
            <button class="btn btn-learn-more mt-3" routerLink="/about-clinic">
              <h6 class="text">Learn More</h6>
              <div class="arrow-icon mt-1">
                <span class="chevron down"></span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <!-- <img src="../../../assets/images/home-new/experience.png" class="clinic-img-left">
      <img src="../../../assets/images/home-new/experience-2.png" class="clinic-img-right">
      <img src="../../../assets/images/home-new/experience-3.png" class="clinic-img-bottom-right">
      <img src="../../../assets/images/new/clinic-round.png" class="clinic-img-round-right">
      <img src="../../../assets/images/new/nadi-a-bg.png" class="clinic-img-center">
      <div class="container">
        <div class="row h-100">
          <div class="col-lg-6 col-12 mx-auto my-5 py-5 align-self-center">
            <div class="title-area">
              <h1 class="title fw_600 text-dark mt-3 font-primary-regular font-color-primary">
                Experience The Vanguard of Wellness
              </h1>
                <p class="desc">
                  Nadi as a primary healthcare provider emphasizes preventive medicine through a holistic approach and utilizes the latest medical advancements in regenerative medicine. We aim to promote overall health and well-being for our clients through innovative and comprehensive approach to healthcare. In addition to our focus on preventive and regenerative medicine, NADI also offers a range of cutting-edge aesthetic treatments to help our clients look and feel their best.
                </p>
                <button class="btn btn-learn-more mt-3" routerLink="/about-clinic">
                  <h6 class="text">Learn More</h6>
                  <div class="arrow-icon mt-1">
                    <span class="chevron down"></span>
                  </div>
                </button>
              </div>
          </div>
        </div>
      </div> -->
    </section>
  <section class="section-doctor meet-our-team h-100 relative">
    <div class="container">
      <div class="row align-items-center mb-4">
        <div class="col-md-8 col-12">
          <div class="title-area">
            <h1 class="title fw_600 text-dark mt-3 font-primary-regular font-color-primary">
              Meet our team <br>
            </h1>
            <h5>
              We Help Elevate Your Well-being, Embrace a Healthier You.
            </h5>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-md-6 my-2">
              <div class="doctor-links">
                <div class="doctor-item"
                  (click)="chooseTeam('Dr Gopee','Chief Operating Officer','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam','../../../assets/images/new/team/dr-gopee.png')"
                  [class.doctor-item-active]="selectedTeamName == 'Dr Gopee'"
                >
                  <div class="doctor-icon">
                    <img src="../../../assets/images/home/medical-doctor-icon.png">
                  </div>
                  <div class="doctor-info">
                    <div class="doctor-name">
                      Dr Gopee
                    </div>
                    <div class="doctor-position">
                      Chief Operating Officer
                    </div>
                  </div>
                  <div class="doctor-arrow">
                    <span class="chevron down"></span>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-6 my-2">
              <div class="doctor-links">
                <div class="doctor-item"
                  (click)="chooseTeam('Dr Edwina Tong','Resident Doctor','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam','../../../assets/images/new/team/dr-edwina.png')"
                  [class.doctor-item-active]="selectedTeamName == 'Dr Edwina Tong'"
                >
                  <div class="doctor-icon">
                    <img src="../../../assets/images/home/medical-doctor-icon.png">
                  </div>
                  <div class="doctor-info">
                    <div class="doctor-name">
                      Dr Edwina Tong
                    </div>
                    <div class="doctor-position">
                      Resident Doctor
                    </div>
                  </div>
                  <div class="doctor-arrow">
                    <span class="chevron down"></span>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-6 my-2">
              <div class="doctor-links">
                <div class="doctor-item"
                  (click)="chooseTeam('Dr Amos Ng','Resident Doctor','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam', '../../../assets/images/new/team/dr-amos.png')"
                  [class.doctor-item-active]="selectedTeamName == 'Dr Amos Ng'"
                >
                  <div class="doctor-icon">
                    <img src="../../../assets/images/home/chief-medical-doctor-icon.png">
                  </div>
                  <div class="doctor-info">
                    <div class="doctor-name">
                      Dr Amos Ng
                    </div>
                    <div class="doctor-position">
                      Resident Doctor
                    </div>
                  </div>
                  <div class="doctor-arrow">
                    <span class="chevron down"></span>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-6 my-2">
              <div class="doctor-links">
                <div class="doctor-item"
                  (click)="chooseTeam('Dr Ashraf','Resident Doctor','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam', '../../../assets/images/new/team/dr-ashraf.png')"
                  [class.doctor-item-active]="selectedTeamName == 'Dr Ashraf'"
                >
                  <div class="doctor-icon">
                    <img src="../../../assets/images/home/medical-doctor-icon.png">
                  </div>
                  <div class="doctor-info">
                    <div class="doctor-name">
                      Dr Ashraf
                    </div>
                    <div class="doctor-position">
                      Resident Doctor
                    </div>
                  </div>
                  <div class="doctor-arrow">
                    <span class="chevron down"></span>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-6 my-2">
              <div class="doctor-links">
                <div class="doctor-item"
                  (click)="chooseTeam('Mogan Ram','Chief Pharmacist','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam', '../../../assets/images/new/team/morgan-ram.png')"
                  [class.doctor-item-active]="selectedTeamName == 'Mogan Ram'"
                >
                  <div class="doctor-icon">
                    <img src="../../../assets/images/home/chief-pharmacist-icon.png">
                  </div>
                  <div class="doctor-info">
                    <div class="doctor-name">
                      Mogan Ram
                    </div>
                    <div class="doctor-position">
                      Chief Pharmacist
                    </div>
                  </div>
                  <div class="doctor-arrow">
                    <span class="chevron down"></span>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-6 my-2">
              <div class="doctor-links">
                <div class="doctor-item"
                  (click)="chooseTeam('Jeanne Wong','Nutritionist','She is specialized in providing individualized nutrition consultation', '../../../assets/images/new/team/jeanne.png')"
                  [class.doctor-item-active]="selectedTeamName == 'Jeanne Wong'"
                >
                  <div class="doctor-icon">
                    <img src="../../../assets/images/home/senior-dietitian-icon.png">
                  </div>
                  <div class="doctor-info">
                    <div class="doctor-name">
                      Jeanne Wong
                    </div>
                    <div class="doctor-position">
                      Nutritionist
                    </div>
                  </div>
                  <div class="doctor-arrow">
                    <span class="chevron down"></span>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="team-info mt-3">
            <h5 class="doctor-name">{{ selectedTeamName }} | {{ selectedTeamJob }}</h5>
            <!-- <h6 class="doctor-description">{{ selectedTeamDescription }}</h6> -->
            <button class="btn btn-learn-more mt-3" routerLink="/our-team">
              <h6 class="text">Learn More</h6>
              <div class="arrow-icon mt-1">
                <span class="chevron down"></span>
              </div>
            </button>
          </div>

        </div>
        <div class="col-12 col-lg-6">
          <div class="our-team-image">
            <img class="person" src="{{ selectedImage }}" alt="person"
              [class.morgan-image]="selectedTeamName == 'Mogan Ram'"
            >
            <img class="person-bg" src="../../../assets/images/new/about-us/person-bg.png" alt="bg">
          </div>
        </div>
      </div>

      <div class="doctor-slider d-none">
        <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(0)">
          <div class="team-inner">
            <div class="team-photo">
              <img src="../../../assets/images/home/Dr-Amos.png">
            </div>
            <div class="team-info mt-3">
              <div class="team-name">Dr Amos Ng</div>
              <div class="team-post">Chief Medical Doctor</div>
            </div>
          </div>

        </div>
        <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(1)">
          <div class="team-inner">
            <div class="team-photo">
              <img src="../../../assets/images/home/Dr-Edwina.png">
            </div>
            <div class="team-info mt-3">
              <div class="team-name">Dr Edwina Tong</div>
              <div class="team-post">Medical Doctor</div>
            </div>
          </div>

        </div>
        <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(2)">
          <div class="team-inner">
            <div class="team-photo">
              <img src="../../../assets/images/home/Dr-Luqman.png">
            </div>
            <div class="team-info mt-3">
              <div class="team-name">Dr Luqman</div>
              <div class="team-post">Medical Doctor</div>
            </div>
          </div>

        </div>
        <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(3)">
          <div class="team-inner">
            <div class="team-photo">
              <img src="../../../assets/images/home/Dr-Ashraf.png">
            </div>
            <div class="team-info mt-3">
              <div class="team-name">Dr Ashraf</div>
              <div class="team-post">Medical Doctor</div>
            </div>
          </div>

        </div>
        <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(4)">
          <div class="team-inner">
            <div class="team-photo">
              <img src="../../../assets/images/home/Ashlee.png">
            </div>
            <div class="team-info mt-3">
              <div class="team-name">Jeanne Wong</div>
              <div class="team-post">Nutritionist</div>
            </div>
          </div>

        </div>
        <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(5)">
          <div class="team-inner">
            <div class="team-photo">
              <img src="../../../assets/images/home/Mogan.png">
            </div>
            <div class="team-info mt-3">
              <div class="team-name">Mogan Ram</div>
              <div class="team-post">Chief Pharmacist</div>
            </div>
          </div>

        </div>
        <!-- <div *ngFor="let data of sortOptions">
            <div  class="card card-white card-doctor">
              <div class="nav-link" routerLink="/our-team" (click)="changeTeam(data.image,data.name,data.title,data.desc,data.biography)">
                <div class="img-doc">
                  <img class="img-fluid w-50" src="{{data.image}}" alt="">
                </div>
                <h5 class="name text-center mt-4">{{data.name}}</h5>
                <h6 class="desc fw_400 text-center mt-3">{{data.title}}<br>
                  </h6>
              </div>
            </div>
          </div> -->

      </div>


    </div>
  </section>
  <section class="section-testimonials" id="testimonials">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12 my-5 aling-self-center text-center">
          <div class="title-area">
            <h1 class="title mb-3 font-primary-regular font-color-primary">
              Voice of Satisfaction <br>
              Hear what our customers have to say
            </h1>
          </div>
        </div>
      </div>

        <div class="testimonials-slider">
            <div class="item">
                <div class="inner">
                    <div class="comments">
                        <p>Super quick responses, absolutely professional doctors and in Medical a team  that has done nothing but go above and beyond for us!</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/brooklyn-simmons.jpg" alt="">
                        </div>
                        <div class="info">
                            <div class="name">Samantha Zhang</div>
                            <div class="sub">Bandar Sunway</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="inner">
                    <div class="comments">
                        <p>TQ Dr Azlan dan team nadi atas servis yang amat pantas.. family saya dapat sembuh
                            daripada covid tanpa tinggalkan rumah.. tq sangat2...</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/omar.png" alt="">
                        </div>
                        <div class="info">
                            <div class="name">En Danial B. Omar</div>
                            <div class="sub">Kuala Lumpur</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="inner">
                    <div class="comments">
                        <p>The best service so far, booked for a swab test with them and they prepared
                            everything even before I made the payment. Kudos to the entire NADI team!</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/mrskam.png" alt="">
                        </div>
                        <div class="info">
                            <div class="name">Mrs Kam</div>
                            <div class="sub">Kuala Lumpur</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="inner">
                    <div class="comments">
                        <p>Thank you so much DR. Sheena for detecting COVID-19 in me & my family! My
                            family is okay today because of you!</p>
                    </div>
                    <div class="user">
                        <div class="image">
                            <img src="../../../assets/images/home/justin.png" alt="">
                        </div>
                        <div class="info">
                            <div class="name">Justin Ong</div>
                            <div class="sub">Subang Jaya</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-faq d-none" id="faq">
  <div class="container">
      <div class="title-area">
          <!-- <h3>Faq</h3> -->
          <h2>Frequently Asked Questions</h2>
          <p>Here’s some of the common things people ask us, for your clarity. </p>
      </div>
      <div class="faq-list">
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">Is NADI a registered company in Malaysia?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info">
                  Yes, we are a registered entity in Malaysia.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">Who are NADI Doctors?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  All NADI doctors are MMC registered in Malaysia, and have extensive professional experience.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">How experienced are NADI doctors?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  All NADI doctors have at least 10 years of experience and have undergone rigorous training processes to uphold the highest standards of medical service quality.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">What is a doctor home visit?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  There are two types of home visits, the covid and the non-covid ones. For those who have Covid-19 symptoms or are diagnosed with Covid-19, our doctors will conduct a home-based assessment, follow-ups by video call telehealth, and 24/7 chatbox support. The monitoring process will be done efficiently from time to time and our doctors will act swiftly if there are any issues incurred.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">Is the home visit service available for one person or the whole family?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  A complete medical assessment will be done for the whole family along with continuous care and 24/7 medical support. Each home visit lasts between 1-2 hour and subjected to a maximum of 4 family members living in the same household.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">What is the process of a home visit?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  After subscribing to the desired package, our doctor will first make a video call to get the basic information about your family and plan accordingly before kick-starting the home visit assessment.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">How the home visit assessment is done?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  Our doctor will conduct assessments for vital signs, temperature, blood pressure, oxygen level, heart rate, and sugar level if needed. Overall condition history will be taken along with physical examination. Covid-19 RTK test or PCR swab test will be done and the result will be explained in a video call. Medications will be given accordingly. Any other conditions will be supported with our 24/7 medical chatbox, doctors will standby for any emergency and enquiry.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">What is the difference between a one-off home visit and a premium home visit?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  Premium home visit has 24/7 support with a doctor and the condition will be followed up till post covid recovery support.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">What does a Tele-health mean?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  Tele-health is the use of communication technologies, such as computers and mobile devices, to access healthcare services remotely and manage your healthcare.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">Who is eligible for Free Telehealth (video call) pre-consultation?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
              Anyone that has been diagnosed with Covid-19 or suspected to be in close contact with a Covid-19 positive patient. The Free Initial Telehealth consultation is provided to allow our doctors to understand your conditions better before providing any treatments or medications.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">What is a 24/7 medical chatbox with doctor support for?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  It is a premium package available for the whole family, to instantly chat with our doctors and attain immediate attention on any arising illness. Also to follow up on the health condition of each family member.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">Can I buy a Home Visit PCR test only for myself?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  The short answer is no. However, we do provide PCR Test for factories and offices with a minimum group of 10. Kindly choose Package 4 from Our Packages section in our website to find out more about group tests.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">How to make payment?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">

              Once you have confirmed on your preferred care package, our team will send payment advice to you via email.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">What are the locations where NADI Doctor Home Visit is available?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  At the moment, our doctors are providing Doctor Home Visit services only within the Klang Valley (Kuala Lumpur & Selangor) region. We will be expanding our services to regions outside of Klang Valley soon.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">Is there any emergency hotline that I can call?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  At the moment we do not have any emergency hotline or direct service line that you may call. However, our support team is available via WhatsApp Live Chat as well as emails (from 9am - 11.59pm) daily to handle your inquiries.
              </div>
          </div>
          <div class="item">
              <div class="head">
                  <div class="name">
                      <div class="text">How do I submit an inquiry?</div>
                      <div class="plus-minus">
                          <span class="plus">+</span>
                          <span class="minus">-</span>
                      </div>
                  </div>
              </div>
              <div class="info" style="display: none;">
                  Kindly scroll to the bottom of our website and click on the “Inquiry Now” button. Fill up the form and hit the submit button, our team will get in touch with you via email as soon as possible.
              </div>
          </div>
      </div>
  </div>
</section>
<section class="section-faq d-none" id="faq">
    <div class="container">
        <div class="title-area">
            <!-- <h3>Faq</h3> -->
            <h2>Frequently Asked Questions</h2>
            <p>Here’s some of the common things people ask us, for your clarity. </p>
        </div>
        <div class="faq-list">
          <ngb-accordion [closeOthers]="true">
            <ngb-panel id="static-1">
              <ng-template ngbPanelTitle>
                <span class="question">Is NADI a registered company in Malaysia?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Yes, we are a registered entity in Malaysia.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-2">
              <ng-template ngbPanelTitle>
                <span class="question">Who are NADI Doctors?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                All NADI doctors are MMC registered in Malaysia, and have extensive professional experience.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-3">
              <ng-template ngbPanelTitle>
                <span class="question">How experienced are NADI doctors?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                All NADI doctors have at least 10 years of experience and have undergone rigorous training processes to uphold the highest standards of medical service quality.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-4">
              <ng-template ngbPanelTitle>
                <span class="question">What is a doctor home visit?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                There are two types of home visits, the covid and the non-covid ones. For those who have Covid-19 symptoms or are diagnosed with Covid-19, our doctors will conduct a home-based assessment, follow-ups by video call telehealth, and 24/7 chatbox support. The monitoring process will be done efficiently from time to time and our doctors will act swiftly if there are any issues incurred.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-5">
              <ng-template ngbPanelTitle>
                <span class="question">Is the home visit service available for one person or the whole family?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                A complete medical assessment will be done for the whole family along with continuous care and 24/7 medical support. Each home visit lasts between 1-2 hour and subjected to a maximum of 4 family members living in the same household.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-6">
              <ng-template ngbPanelTitle>
                <span class="question">What is the process of a home visit?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                After subscribing to the desired package, our doctor will first make a video call to get the basic information about your family and plan accordingly before kick-starting the home visit assessment.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-7">
              <ng-template ngbPanelTitle>
                <span class="question">How the home visit assessment is done?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Our doctor will conduct assessments for vital signs, temperature, blood pressure, oxygen level, heart rate, and sugar level if needed. Overall condition history will be taken along with physical examination. Covid-19 RTK test or PCR swab test will be done and the result will be explained in a video call. Medications will be given accordingly. Any other conditions will be supported with our 24/7 medical chatbox, doctors will standby for any emergency and enquiry.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-8">
              <ng-template ngbPanelTitle>
                <span class="question">What is the difference between a one-off home visit and a premium home visit?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Premium home visit has 24/7 support with a doctor and the condition will be followed up till post covid recovery support.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-9">
              <ng-template ngbPanelTitle>
                <span class="question">What does a Tele-health mean?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Tele-health is the use of communication technologies, such as computers and mobile devices, to access healthcare services remotely and manage your healthcare.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-10">
              <ng-template ngbPanelTitle>
                <span class="question">Who is eligible for Free Telehealth (video call) pre-consultation?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anyone that has been diagnosed with Covid-19 or suspected to be in close contact with a Covid-19 positive patient. The Free Initial Telehealth consultation is provided to allow our doctors to understand your conditions better before providing any treatments or medications.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-11">
              <ng-template ngbPanelTitle>
                <span class="question">What is a 24/7 medical chatbox with doctor support for?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                It is a premium package available for the whole family, to instantly chat with our doctors and attain immediate attention on any arising illness. Also to follow up on the health condition of each family member.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-12">
              <ng-template ngbPanelTitle>
                <span class="question">Can I buy a Home Visit PCR test only for myself?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                The short answer is no. However, we do provide PCR Test for factories and offices with a minimum group of 10. Kindly choose Package 4 from Our Packages section in our website to find out more about group tests.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-12">
              <ng-template ngbPanelTitle>
                <span class="question">How to make payment?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Once you have confirmed on your preferred care package, our team will send payment advice to you via email.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-13">
              <ng-template ngbPanelTitle>
                <span class="question">What are the locations where NADI Doctor Home Visit is available?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                At the moment, our doctors are providing Doctor Home Visit services only within the Klang Valley (Kuala Lumpur & Selangor) region. We will be expanding our services to regions outside of Klang Valley soon.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-14">
              <ng-template ngbPanelTitle>
                <span class="question">Is there any emergency hotline that I can call?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                At the moment we do not have any emergency hotline or direct service line that you may call. However, our support team is available via WhatsApp Live Chat as well as emails (from 9am - 11.59pm) daily to handle your inquiries.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-15">
              <ng-template ngbPanelTitle>
                <span class="question">How do I submit an inquiry?
                  <div class="plus-minus">
                    <span class="plus">+</span>
                    <span class="minus">-</span>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Kindly scroll to the bottom of our website and click on the “Inquiry Now” button. Fill up the form and hit the submit button, our team will get in touch with you via email as soon as possible.
              </ng-template>
            </ngb-panel>

          </ngb-accordion>

        </div>
    </div>
</section>
  <!-- <div class="section-testimonials h-100 relative">
      <div class="container px-50">
        <div class="row h-100">
          <div class="col-12 col-md-8 col-md-8 d-flex align-items-center" data-aos="fade-up" data-aos-duration="800" data-aos-delay="0">
            <div>
              <p class="text_blue_1">TESTIMONIALS</p>
              <h1 class="title fw_600 text-dark mt-3">
                Story From Our Users
              </h1>
              <h6 class="fw_400 mt-3 desc">
                Let’s see what the customers are saying about our services.
              </h6>
            </div>
          </div>
          <div class="control col-12 col-md-4">
            <div class="box">
              <button class="slider-btn prev">
                <span class="chevron left mx-3"></span>
              </button>
              <button class="slider-btn next">
                <span class="chevron right mx-3"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="testimonial-slider mt-5">
          <div class="card card-white">
            <div>
              <div class="image-box">
                <img src="../../../assets/images/home/Review Eyka 1.png" >
              </div>
              <h5 class="name text-center mt-4">Brandy Ho Stillman</h5>
              <h6 class="desc fw_400 text-center mt-3">
                Very good price and very professional. Always got my result on the same day. The staffs are very friendly too! 10/10.
              </h6>
            </div>
          </div>
          <div class="card card-white">
            <div>
              <div class="image-box">
                <img src="../../../assets/images/home/Review Brandy Ho 1.png" >
              </div>
              <h5 class="name text-center mt-4">Chrissie Mi</h5>
              <h6 class="desc fw_400 text-center mt-3">
                Very good services. Been using their home visit PCR test service for few times.
              </h6>
            </div>
          </div>
          <div class="card card-white">
            <div>
              <div class="image-box">
                <img src="../../../assets/images/home/Review Chrissie Mi 1.png" >
              </div>
              <h5 class="name text-center mt-4">Eyka Aziz</h5>
              <h6 class="desc fw_400 text-center mt-3">
                NADI has set a high standard for healthcare. The staff and admin process are excellent and simple. I would have no hesitation to use NADI again.
              </h6>
            </div>
          </div>
          <div class="card card-white">
            <div>
              <div class="image-box">
                <img src="../../../assets/images/home/Review Chrissie Mi 1.png" >
              </div>
              <h5 class="name text-center mt-4">Eyka Aziz</h5>
              <h6 class="desc fw_400 text-center mt-3">
                NADI has set a high standard for healthcare. The staff and admin process are excellent and simple. I would have no hesitation to use NADI again.
              </h6>
            </div>
          </div>
        </div>
      </div>
  </div> -->

<!-- <app-contact></app-contact> -->


</div>
