<div class="section-contact">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
       <div class="row align-items-center justify-content-center">
          <div class="col-lg-12">
             <div class="text-center">
                <h1 class="fw_500">Contact Us</h1>
                <p class="fw-normal"><a>Home</a> / Contact Us</p>
             </div>
          </div>
       </div>
    </div>
  </header>

  <div class="section-banner mx-3 mt-5 hide-md">
    <div class="px-5 mb-4 h-100 info">
       <div class="row h-100">
          <div class="col-lg-6 d-flex align-items-center">
             <div class="content">
                <h1 class="fw-700">
                  Get In Touch <br>
                  Path to Wellness Begins Here!
                </h1>
                <p class="">
                  We welcome the opportunity to connect with you. If you have any questions, inquiries, or would like more information about our services, please don't hesitate to contact us. Our dedicated team is committed to providing exceptional assistance and addressing your needs. You can reach us by phone, email, or by filling out the contact form below. 
                </p>
             </div>
          </div>
          <div class="col-md-6">
          </div>
       </div>
    </div>
  </div>

  <div class="banner-top-tablet mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/new/contact/contact-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Get In Touch <br>
        Path to Wellness Begins Here!
      </h1>
      <p class="">
        We welcome the opportunity to connect with you. If you have any questions, inquiries, or would like more information about our services, please don't hesitate to contact us. Our dedicated team is committed to providing exceptional assistance and addressing your needs. You can reach us by phone, email, or by filling out the contact form below. 
      </p>
    </div>
  </div>

    <div class="container mx-auto py-5">
      <div class="row">
        <div class="col-lg-6 col-12 mb-2 contact-info">
          <div class="d-flex gap-3 mb-4 py-1">
            <div class="contact-icon">
              <i class="bi bi-telephone"></i>
            </div>
            
            <div class="contact-info mt-2">
               <a href="tel:+0377312727">03-7731 2727</a>
               <p class="w-75">Call our hotline number for immediate assistance</p>
            </div>
         </div>
         <div class="d-flex gap-3 mb-4 py-1">
          <div class="contact-icon">
            <i class="bi bi-geo-alt"></i>
          </div>
          
          <div class="contact-info">
             <a>Locate Our Clinic</a>
             <p class="w-75">10 Boulevard, B-2-18, Block B, Lebuhraya SPRINT, PJU 6A, 47400 Petaling Jaya, Selangor</p>
          </div>
       </div>
         <div class="d-flex gap-3 mb-4 py-1">
          <div class="contact-icon">
            <i class="bi bi-envelope"></i>
          </div>
          
          <div class="contact-info mt-2">
             <a href="mailto:nadiclinic@nadimeditech.com">nadiclinic@nadimeditech.com</a>
             <p>Drop your message to our official email.</p>
          </div>
       </div>

     <div class="d-flex gap-3 py-1">
        <div style="width: 100%">
          <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d43789.94790875487!2d101.6048309947182!3d3.111625833658302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4f3a40846e0d%3A0xd68da85e3ec7d9d5!2sLiew%20Clinic%2010%20Boulevard!5e0!3m2!1sen!2smy!4v1676252582815!5m2!1sen!2smy" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63741.707310155674!2d101.53708704863277!3d3.1325431999999966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4f3a40846e0d%3A0xd68da85e3ec7d9d5!2sLiew%20Clinic%2010%20Boulevard!5e0!3m2!1sen!2smy!4v1684721931619!5m2!1sen!2smy" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

     </div>
        </div>
        <div class="col-12 col-lg-6 ">
          <form [formGroup]="contactForm" onsubmit="return false;" class="">
              <h4 class="title fw-600 text-dark text-green">
                  Drop Us A Message.
                </h4>
                <p class="mb-3">Our team will be in touch with you as soon as possible.</p>
            <div class="row">
              <div class="form-group col-12 col-md-6">
                <label class="fw-600 text-green">First Name</label>
                <input formControlName="first_name" type="text" class="form-control" placeholder="Enter your first name"
                  [class.border-danger]="formError && contactForm.controls['first_name'].value == ''"
                >
                <div class="text-danger mt-2 fs-14"
                  *ngIf="formError && contactForm.controls['first_name'].value == ''"
                >
                  First Name is required
                </div>
              </div>
              <div class="form-group col-12 col-md-6">
                <label class="fw-600 text-green">Last Name</label>
                <input formControlName="last_name" type="text" class="form-control" placeholder="Enter your last name"
                  [class.border-danger]="formError && contactForm.controls['last_name'].value == ''"
                >
                <div class="text-danger mt-2 fs-14"
                  *ngIf="formError && contactForm.controls['last_name'].value == ''"
                >
                  Last Name is required
                </div>
              </div>
            
            <div class="form-group col-12 col-md-6">
              <label class="fw-600 text-green">Email Address</label>
              <input formControlName="email" type="text" class="form-control" placeholder="Enter the email address"
                [class.border-danger]="formError && contactForm.controls['email'].value == ''"
              >
              <div class="text-danger mt-2 fs-14"
                *ngIf="formError && contactForm.controls['email'].value == ''"
              >
                Email Address is required
              </div>
              <div class="text-danger mt-2 fs-14"
                *ngIf="formError && (contactForm.controls['email'].invalid && contactForm.controls['email'].touched)"
              >
                Email Address is not valid
              </div>
            </div>
            <div class="form-group col-12 col-md-6">
              <label class="fw-600 text-green">Phone number</label>
              <input formControlName="phone" type="text" class="form-control" placeholder="Enter the phone number"
                [class.border-danger]="formError && contactForm.controls['phone'].value == ''"
              >
              <div class="text-danger mt-2 fs-14"
                *ngIf="formError && contactForm.controls['phone'].value == ''"
              >
                Phone number is required
              </div>
            </div>
            <div class="form-group col-12">
              <label class="fw-600 text-green">Message</label>
              <textarea formControlName="message" class="form-control" placeholder="Message to clinic" rows="13"
                [class.border-danger]="formError && contactForm.controls['message'].value == ''"
              ></textarea>
              <div class="text-danger mt-2 fs-14"
                *ngIf="formError && contactForm.controls['phone'].value == ''"
              >
                Message is required
              </div>
            </div>
            <div class="col-12">
              <button (click)="submitForm()" class="btn btn-submit mt-4 px-5 py-2">
                <div *ngIf="loading" class="spinner-border" role="status"></div>
                Submit
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>

      
    </div>
  </div>

  <ng-template #submitModal let-modal>
    <button type="button" class="btn close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    <div class="modal-body p-4">
      <div>
        <img class="icon" [src]="modalIcon" alt="icon">
        <h3 class="title text-center">
          {{ modalTitle }}
        </h3>
        <h6 class="description text-center">
          {{ modalDescription }}
        </h6>
      </div>
    </div>
  </ng-template>