<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Health Screening Services</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Health Screening Services
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
        <div class="row h-100 d-flex align-items-center">
            <div class="col-md-6 info">
                <div class="">
                    <h1 class="fw-700 mt-5">
                      Know Your <br>
                      Health Status, <br>
                      Screen Regularly.
                    </h1>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
            </div>
        </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5">
    <img class="w-100" src="../../../assets/images/health-screening-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Know Your Health Status, Screen Regularly.
      </h1>
    </div>
  </div>

  <!-- <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/service-home-visit.jpg"
          />
        </div>
        <div class="col-md-6">
          <div class="pl-5">
            <h1 class="fw-700 mb-3 text-black">Health Screening</h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              We provide comprehensive health screening services that provide
              valuable insights into your overall well-being. Whether you're
              seeking a regular screenings for preventive healthcare or need
              early detection due to specific symptoms or genetic
              predisposition, our expert team is here for you. With thorough
              assessments and a range of essential tests, we empower you to take
              proactive steps towards maintaining optimal health, enabling
              individuals to make informed decisions about their lifestyle,
              diet, and potential treatment options.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="section-services py-5">
    <div class="container text-center">
      <div class="title-area">
        <h2>Services available</h2>
      </div>
    </div>
  </div>

  <div class="section-packages py-5">
    <img class="a-logo-1" src="../../../assets/images/a-icon-gray.png" alt="">
    <img class="a-logo-2" src="../../../assets/images/a-icon-gray.png" alt="">

    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 d-flex align-items-center">
          <div class="w-100">
            <div class="card card-package mb-3"
              (click)="selectPackage('basic')"
              [class.active]="selectedPackage == 'basic'"
            >
              <div class="icon">
                <img class="star" src="../../../assets/images/health-screening/basic.png" alt="">
              </div>
              <h6>Basic Screening Package</h6>
            </div>
            <div class="card card-package mb-3"
              (click)="selectPackage('premium')"
              [class.active]="selectedPackage == 'premium'"
            >
              <div class="icon">
                <img class="crown" src="../../../assets/images/health-screening/premium.png" alt="">
              </div>
              <h6>Premium Screening Package</h6>
            </div>
            <div class="card card-package mb-3"
              (click)="selectPackage('platinum')"
              [class.active]="selectedPackage == 'platinum'"
            >
              <div class="icon">
                <img src="../../../assets/images/health-screening/platinum.png" alt="">
              </div>
              <h6>Platinum Screening Package</h6>
            </div>
            <div class="card card-package mb-3"
              (click)="selectPackage('anti')"
              [class.active]="selectedPackage == 'anti'"
            >
              <div class="icon">
                <img src="../../../assets/images/health-screening/anti-aging.png" alt="">
              </div>
              <h6>Anti-aging Screening Package</h6>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="{{ packageImage }}"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="section-services py-5">
    <div class="container text-center">

      <!-- <div class="row justify-content-center">
        <div class="col-12 col-md-6 pb-4">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/Basic.png"
          />
        </div>
        <div class="col-12 col-md-6 pb-4">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/Premium.png"
          />
        </div>
        <div class="col-12 col-md-6 pb-4">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/Platinum.png"
          />
        </div>
        <div class="col-12 col-md-6 pb-4">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/Anti-aging.png"
          />
        </div>
      </div> -->

      <div class="row mt-5">
        <div class="col-12 col-md-5 d-flex align-items-center">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/other-screening-package.png"
          />
        </div>
        <div class="col-12 col-md-7">
          <div>
            <h1 class="fw-700 mb-3 text-black text-left">
              Other Screening Packages
            </h1>
            <div class="card card-info text-left mb-3">
              Pre-employment Medical Examination
            </div>
            <div class="card card-info text-left mb-3">
              International Student Screen
            </div>
            <div class="card card-info text-left mb-3">
              Pre-marital Screen
            </div>
            <div class="card card-info text-left mb-3">
              Cardiovascular & Coagulation
            </div>
            <div class="card card-info text-left mb-3">
              Endocrinology & Metabolic
            </div>
            <div class="card card-info text-left mb-3">
              Gynaecology & Reproductive
            </div>
            <div class="card card-info text-left mb-3">
              Haematology & Coagulation
            </div>
            <div class="card card-info text-left mb-3">
              Hepatobiliary & Gastrointestinal
            </div>
            <div class="card card-info text-left mb-3">
              Infectious Diseases
            </div>
            <!-- <div class="card card-info text-left mb-3">
              Paediatric Screen
            </div> -->
            <!-- <div class="card card-info text-left mb-3">
              Renal & Urinary
            </div> -->
            <div class="card card-info text-left mb-3">
              Rheumatology, Immunology & Allergy
            </div>
            <div class="card card-info text-left mb-3">
              Tumour Markers
            </div>
            <p class="text-left text-small">
              For customised health screening package, please reach out to us for more information.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<!-- <section class="section-package h-100 relative">
  <div class="container">
    <div class="row align-items-center mb-4">
      <div class="col-md-8 col-12">
        <div class="title-area">
          <p class="text_blue_1">our packages</p>
          <h1 class="title fw_600 text-dark mt-3">
            NADI Healthcare Packages
          </h1>
          <h6 class="fw_400 mt-3 desc">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde
            nisi placeat quisquam expedita perferendis. Minus placeat
            suscipit, ducimus, corporis illo, cupiditate est obcaecati non
            ipsum reiciendis ipsa veritatis quae dolorem!
          </h6>
        </div>
      </div>
      <div class="col-md-4 col-12 d-flex justify-content-end">
        <div class="control justify-content-center py-2">
          <div class="box">
            <button class="slider-btn prev-package">
              <span class="chevron left mx-3"></span>
            </button>
            <button class="slider-btn next-package">
              <span class="chevron right mx-3"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="package-slider">
      <div class="package-item">
        <div class="package-inner">
          <div class="package-info mt-3">
            <div class="package-name">Basic Screening Package</div>
            <div class="desc">FAST result within 24 hours!</div>
            <div class="price">RM 180.00</div>
            <div class="action my-4">
              <a
                href="https://drive.google.com/file/d/1vMz0p8XDj8rm83UMDfnl_MuJRJ7NleGO/view"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalBuyPackage"
                id="package7"
                >Choose this package</a
              >
            </div>
            <div class="benefits">
              <div class="title-ul">Notes</div>
              <ul>
                <li>PCR results are available in 24 hours</li>
                <li>NADI base is in Lakefield Sg. Besi</li>
                <p style="font-size: small">
                  *Same day result, except Sundays, public holidays
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="package-item">
        <div class="package-inner">
          <div class="package-info mt-3">
            <div class="package-name">Premium Screening Package</div>
            <div class="desc">FAST result within 24 hours!</div>
            <div class="price">RM 180.00</div>
            <div class="action my-4">
              <a
                href="https://drive.google.com/file/d/1yWBhm9sqphy1clK40Dnoa4TurLwXUuAy/view?usp=sharing"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalBuyPackage"
                id="package7"
                >Choose this package</a
              >
            </div>
            <div class="benefits">
              <div class="title-ul">Notes</div>
              <ul>
                <li>PCR results are available in 24 hours</li>
                <li>NADI base is in Lakefield Sg. Besi</li>
                <p style="font-size: small">
                  *Same day result, except Sundays, public holidays
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="package-item">
        <div class="package-inner">
          <div class="package-info mt-3">
            <div class="package-name">Platinum Screening Package</div>
            <div class="desc">FAST result within 24 hours!</div>
            <div class="price">RM 180.00</div>
            <div class="action my-4">
              <a
                href="https://drive.google.com/file/d/1vUHRQJ0SQCIDdavwdAlNPCf3NHhbqxEc/view?usp=sharing"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalBuyPackage"
                id="package7"
                >Choose this package</a
              >
            </div>
            <div class="benefits">
              <div class="title-ul">Notes</div>
              <ul>
                <li>PCR results are available in 24 hours</li>
                <li>NADI base is in Lakefield Sg. Besi</li>
                <p style="font-size: small">
                  *Same day result, except Sundays, public holidays
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="package-item">
        <div class="package-inner">
          <div class="package-info mt-3">
            <div class="package-name">Anti-aging Screening Package</div>
            <div class="desc">FAST result within 24 hours!</div>
            <div class="price">RM 180.00</div>
            <div class="action my-4">
              <a
                href="https://drive.google.com/file/d/1Ssn6m5zNaW_c-k3zhskXETxYfa8arVOM/view?usp=sharing"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalBuyPackage"
                id="package7"
                >Choose this package</a
              >
            </div>
            <div class="benefits">
              <div class="title-ul">Notes</div>
              <ul>
                <li>PCR results are available in 24 hours</li>
                <li>NADI base is in Lakefield Sg. Besi</li>
                <p style="font-size: small">
                  *Same day result, except Sundays, public holidays
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="package-item">
        <div class="package-inner">
          <div class="package-info mt-3">
            <div class="package-name">Other Screening Packages</div>
            <div class="desc">FAST result within 24 hours!</div>
            <div class="price">RM 180.00</div>
            <div class="action my-4">
              <a
                href="https://drive.google.com/file/d/1vMz0p8XDj8rm83UMDfnl_MuJRJ7NleGO/view"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalBuyPackage"
                id="package7"
                >Choose this package</a
              >
            </div>
            <div class="benefits">
              <div class="title-ul">Notes</div>
              <ul>
                <li>PCR results are available in 24 hours</li>
                <li>NADI base is in Lakefield Sg. Besi</li>
                <p style="font-size: small">
                  *Same day result, except Sundays, public holidays
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="package-item">
        <div class="package-inner">
          <div class="package-info mt-3">
            <div class="package-name">Basic Screening Package</div>
            <div class="desc">FAST result within 24 hours!</div>
            <div class="price">RM 180.00</div>
            <div class="action my-4">
              <a
                href="https://drive.google.com/file/d/1vMz0p8XDj8rm83UMDfnl_MuJRJ7NleGO/view"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalBuyPackage"
                id="package7"
                >Choose this package</a
              >
            </div>
            <div class="benefits">
              <div class="title-ul">Notes</div>
              <ul>
                <li>PCR results are available in 24 hours</li>
                <li>NADI base is in Lakefield Sg. Besi</li>
                <p style="font-size: small">
                  *Same day result, except Sundays, public holidays
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
