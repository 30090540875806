import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-service-health-screening',
  templateUrl: './service-health-screening.component.html',
  styleUrls: ['./service-health-screening.component.scss']
})
export class ServiceHealthScreeningComponent implements OnInit {

  selectedPackage = 'basic';
  packageImage = '../../../assets/images/Basic.png';

  constructor() { }

  ngOnInit(): void {
    setTimeout( ()=>{
      
      this.initPackageSlider();
    }, 1000);
  }

  initPackageSlider() {
    $('.package-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.prev-package'),
      nextArrow: $('.next-package'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }

  selectPackage(title: string) {
    this.selectedPackage = title;
  
    if(title == 'basic') {
      this.packageImage = '../../../assets/images/Basic.png';
    }
    else if(title == 'premium') {
      this.packageImage = '../../../assets/images/Premium.png';
    }
    else if(title == 'platinum') {
      this.packageImage = '../../../assets/images/Platinum.png';
    }
    else if(title == 'anti') {
      this.packageImage = '../../../assets/images/Anti-aging.png';
    }
  }
}
