<div class="section-faq">
  <header
    class="py-5 inner-header"
    style="
      background: url('../../../assets/images/contact.png') no-repeat center
        center;
    "
  >
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_700 text-white">Frequently Asked Questions</h1>
            <p class="fw-normal text-white-50 mb-0">
              <a class="text-white">Home</a> / Frequently Asked Questions
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>Our Doctors</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is NADI a registered company in Malaysia?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, we are a registered entity in Malaysia.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Who are NADI Doctors?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              All NADI doctors are MMC registered in Malaysia, and have
              extensive professional experience.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How experienced are NADI doctors?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              All NADI doctors have at least 10 years of experience and have
              undergone rigorous training processes to uphold the highest
              standards of medical service quality.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is a doctor home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              There are two types of home visits, the covid and the non-covid
              ones. For those who have Covid-19 symptoms or are diagnosed with
              Covid-19, our doctors will conduct a home-based assessment,
              follow-ups by video call telehealth, and 24/7 chatbox support. The
              monitoring process will be done efficiently from time to time and
              our doctors will act swiftly if there are any issues incurred.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is the home visit service available for one person or the whole
                family?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              A complete medical assessment will be done for the whole family
              along with continuous care and 24/7 medical support. Each home
              visit lasts between 1-2 hour and subjected to a maximum of 4
              family members living in the same household.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-6">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the process of a home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              After subscribing to the desired package, our doctor will first
              make a video call to get the basic information about your family
              and plan accordingly before kick-starting the home visit
              assessment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-7">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How the home visit assessment is done?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Our doctor will conduct assessments for vital signs, temperature,
              blood pressure, oxygen level, heart rate, and sugar level if
              needed. Overall condition history will be taken along with
              physical examination. Covid-19 RTK test or PCR swab test will be
              done and the result will be explained in a video call. Medications
              will be given accordingly. Any other conditions will be supported
              with our 24/7 medical chatbox, doctors will standby for any
              emergency and enquiry.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-8">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the difference between a one-off home visit and a
                premium home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Premium home visit has 24/7 support with a doctor and the
              condition will be followed up till post covid recovery support.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-9">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What does a Tele-health mean?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Tele-health is the use of communication technologies, such as
              computers and mobile devices, to access healthcare services
              remotely and manage your healthcare.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-10">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Who is eligible for Free Telehealth (video call)
                pre-consultation?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Anyone that has been diagnosed with Covid-19 or suspected to be in
              close contact with a Covid-19 positive patient. The Free Initial
              Telehealth consultation is provided to allow our doctors to
              understand your conditions better before providing any treatments
              or medications.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-11">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is a 24/7 medical chatbox with doctor support for?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              It is a premium package available for the whole family, to
              instantly chat with our doctors and attain immediate attention on
              any arising illness. Also to follow up on the health condition of
              each family member.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-12">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I buy a Home Visit PCR test only for myself?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The short answer is no. However, we do provide PCR Test for
              factories and offices with a minimum group of 10. Kindly choose
              Package 4 from Our Packages section in our website to find out
              more about group tests.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-12">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How to make payment?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Once you have confirmed on your preferred care package, our team
              will send payment advice to you via email.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-13">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What are the locations where NADI Doctor Home Visit is
                available?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              At the moment, our doctors are providing Doctor Home Visit
              services only within the Klang Valley (Kuala Lumpur & Selangor)
              region. We will be expanding our services to regions outside of
              Klang Valley soon.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-14">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is there any emergency hotline that I can call?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              At the moment we do not have any emergency hotline or direct
              service line that you may call. However, our support team is
              available via WhatsApp Live Chat as well as emails (from 9am -
              11.59pm) daily to handle your inquiries.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-15">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How do I submit an inquiry?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Kindly scroll to the bottom of our website and click on the
              “Inquiry Now” button. Fill up the form and hit the submit button,
              our team will get in touch with you via email as soon as possible.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section> -->

  <!-- <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>Our Clinic</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is NADI a registered company in Malaysia?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, we are a registered entity in Malaysia.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Who are NADI Doctors?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              All NADI doctors are MMC registered in Malaysia, and have
              extensive professional experience.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How experienced are NADI doctors?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              All NADI doctors have at least 10 years of experience and have
              undergone rigorous training processes to uphold the highest
              standards of medical service quality.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is a doctor home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              There are two types of home visits, the covid and the non-covid
              ones. For those who have Covid-19 symptoms or are diagnosed with
              Covid-19, our doctors will conduct a home-based assessment,
              follow-ups by video call telehealth, and 24/7 chatbox support. The
              monitoring process will be done efficiently from time to time and
              our doctors will act swiftly if there are any issues incurred.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is the home visit service available for one person or the whole
                family?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              A complete medical assessment will be done for the whole family
              along with continuous care and 24/7 medical support. Each home
              visit lasts between 1-2 hour and subjected to a maximum of 4
              family members living in the same household.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-6">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the process of a home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              After subscribing to the desired package, our doctor will first
              make a video call to get the basic information about your family
              and plan accordingly before kick-starting the home visit
              assessment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-7">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How the home visit assessment is done?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Our doctor will conduct assessments for vital signs, temperature,
              blood pressure, oxygen level, heart rate, and sugar level if
              needed. Overall condition history will be taken along with
              physical examination. Covid-19 RTK test or PCR swab test will be
              done and the result will be explained in a video call. Medications
              will be given accordingly. Any other conditions will be supported
              with our 24/7 medical chatbox, doctors will standby for any
              emergency and enquiry.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-8">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the difference between a one-off home visit and a
                premium home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Premium home visit has 24/7 support with a doctor and the
              condition will be followed up till post covid recovery support.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-9">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What does a Tele-health mean?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Tele-health is the use of communication technologies, such as
              computers and mobile devices, to access healthcare services
              remotely and manage your healthcare.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-10">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Who is eligible for Free Telehealth (video call)
                pre-consultation?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Anyone that has been diagnosed with Covid-19 or suspected to be in
              close contact with a Covid-19 positive patient. The Free Initial
              Telehealth consultation is provided to allow our doctors to
              understand your conditions better before providing any treatments
              or medications.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-11">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is a 24/7 medical chatbox with doctor support for?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              It is a premium package available for the whole family, to
              instantly chat with our doctors and attain immediate attention on
              any arising illness. Also to follow up on the health condition of
              each family member.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-12">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I buy a Home Visit PCR test only for myself?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The short answer is no. However, we do provide PCR Test for
              factories and offices with a minimum group of 10. Kindly choose
              Package 4 from Our Packages section in our website to find out
              more about group tests.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-12">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How to make payment?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Once you have confirmed on your preferred care package, our team
              will send payment advice to you via email.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-13">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What are the locations where NADI Doctor Home Visit is
                available?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              At the moment, our doctors are providing Doctor Home Visit
              services only within the Klang Valley (Kuala Lumpur & Selangor)
              region. We will be expanding our services to regions outside of
              Klang Valley soon.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-14">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is there any emergency hotline that I can call?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              At the moment we do not have any emergency hotline or direct
              service line that you may call. However, our support team is
              available via WhatsApp Live Chat as well as emails (from 9am -
              11.59pm) daily to handle your inquiries.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-15">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How do I submit an inquiry?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Kindly scroll to the bottom of our website and click on the
              “Inquiry Now” button. Fill up the form and hit the submit button,
              our team will get in touch with you via email as soon as possible.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section> -->

  <!-- <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>General Matter </h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is NADI a registered company in Malaysia?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, we are a registered entity in Malaysia.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Who are NADI Doctors?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              All NADI doctors are MMC registered in Malaysia, and have
              extensive professional experience.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How experienced are NADI doctors?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              All NADI doctors have at least 10 years of experience and have
              undergone rigorous training processes to uphold the highest
              standards of medical service quality.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is a doctor home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              There are two types of home visits, the covid and the non-covid
              ones. For those who have Covid-19 symptoms or are diagnosed with
              Covid-19, our doctors will conduct a home-based assessment,
              follow-ups by video call telehealth, and 24/7 chatbox support. The
              monitoring process will be done efficiently from time to time and
              our doctors will act swiftly if there are any issues incurred.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is the home visit service available for one person or the whole
                family?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              A complete medical assessment will be done for the whole family
              along with continuous care and 24/7 medical support. Each home
              visit lasts between 1-2 hour and subjected to a maximum of 4
              family members living in the same household.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-6">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the process of a home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              After subscribing to the desired package, our doctor will first
              make a video call to get the basic information about your family
              and plan accordingly before kick-starting the home visit
              assessment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-7">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How the home visit assessment is done?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Our doctor will conduct assessments for vital signs, temperature,
              blood pressure, oxygen level, heart rate, and sugar level if
              needed. Overall condition history will be taken along with
              physical examination. Covid-19 RTK test or PCR swab test will be
              done and the result will be explained in a video call. Medications
              will be given accordingly. Any other conditions will be supported
              with our 24/7 medical chatbox, doctors will standby for any
              emergency and enquiry.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-8">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the difference between a one-off home visit and a
                premium home visit?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Premium home visit has 24/7 support with a doctor and the
              condition will be followed up till post covid recovery support.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-9">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What does a Tele-health mean?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Tele-health is the use of communication technologies, such as
              computers and mobile devices, to access healthcare services
              remotely and manage your healthcare.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-10">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Who is eligible for Free Telehealth (video call)
                pre-consultation?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Anyone that has been diagnosed with Covid-19 or suspected to be in
              close contact with a Covid-19 positive patient. The Free Initial
              Telehealth consultation is provided to allow our doctors to
              understand your conditions better before providing any treatments
              or medications.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-11">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is a 24/7 medical chatbox with doctor support for?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              It is a premium package available for the whole family, to
              instantly chat with our doctors and attain immediate attention on
              any arising illness. Also to follow up on the health condition of
              each family member.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-12">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I buy a Home Visit PCR test only for myself?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The short answer is no. However, we do provide PCR Test for
              factories and offices with a minimum group of 10. Kindly choose
              Package 4 from Our Packages section in our website to find out
              more about group tests.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-12">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How to make payment?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Once you have confirmed on your preferred care package, our team
              will send payment advice to you via email.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-13">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What are the locations where NADI Doctor Home Visit is
                available?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              At the moment, our doctors are providing Doctor Home Visit
              services only within the Klang Valley (Kuala Lumpur & Selangor)
              region. We will be expanding our services to regions outside of
              Klang Valley soon.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-14">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is there any emergency hotline that I can call?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              At the moment we do not have any emergency hotline or direct
              service line that you may call. However, our support team is
              available via WhatsApp Live Chat as well as emails (from 9am -
              11.59pm) daily to handle your inquiries.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-15">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How do I submit an inquiry?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Kindly scroll to the bottom of our website and click on the
              “Inquiry Now” button. Fill up the form and hit the submit button,
              our team will get in touch with you via email as soon as possible.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section> -->

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>General Medical Consultation </h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What should I bring to my appointment?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
                <li>A list of your current medications, including doses and frequency of use.</li>
                <li>Relevant medical records, such as test results or previous doctor's notes.</li>
                <li>A list of questions or concerns you would like to address during the appointment.</li>   
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I request a specific doctor?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              You can request a specific doctor if they are available, but it may not always be possible to see your preferred doctor.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What should I do if I need to cancel or reschedule my appointment?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              You should contact us at 03-7731 2727 / 012 646 8392 as soon as possible to cancel or reschedule your appointment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I ask for a referral to a specialist if needed?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, your doctor can provide you with a referral to a specialist if it is necessary.
            </ng-template>
          </ngb-panel>
         
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>Doctor Home Visit Services</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How long does it take for the doctor to arrive?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              If an appointment had been set earlier, the doctor will arrive at your preferred location based on the appointment time. However, if an urgent home visit is required, this will depend on the doctor's availibility.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How much does a doctor home visit cost?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The cost of a doctor home visit will vary depending on the service you choose, number of client receiving home visit service in the same location and urgency of the service needed. You can contact the service for more information on pricing.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Are doctor home visit services available 24/7?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Our doctors are only available from 9am to 9pm, including weekends and Public Holidays.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What if I need a test or procedure that can't be done at home?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              If you need a test or procedure that can't be done at home, the doctor can refer you to a laboratory or imaging center, or schedule an appointment for you with a specialist.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What if I need follow-up care?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The doctor can provide follow-up care, including teleconsultation or home visits. Referral to specialist can be done if necessary.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>Health Screening</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How often should I get a health screening?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The frequency of health screenings will depend on your age, gender, personal and family medical history, but it's generally recommended to have a health screening <b>at least once a year.</b>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What should I do if I have a positive result from a health screening?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              If you have a positive result from a health screening, worry not! Our doctors will customised a health plan and treatment for you to achieve your health goal 
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I request a specific type of health screening?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, you can request a specific type of health screening, such as a screening for a specific health condition. Your doctor can help you determine which tests are appropriate for you.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the best time to schedule a health screening?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The best time to schedule a health screening will depend on your personal circumstances and the type of screening, but it's generally recommended to schedule a screening in the morning, when blood tests will be most accurate.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I continue with my normal activities before a health screening?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, you can continue with your normal activities before a health screening. However, you may be advised to fast or avoid certain foods or medications before certain tests.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <!-- <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>Platelet-rich plasma (PRP) Therapy</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the recovery time after PRP therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The recovery time after PRP therapy will depend on the individual and the condition being treated, but most patients experience minimal discomfort and can return to normal activities within a few days.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Are there any side effects of PRP therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              As with any medical procedure, there are risks and potential side effects associated with PRP therapy. The most common side effects include pain, swelling, and bleeding at the injection site.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How long does it take for the effects of PRP therapy to be seen?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The effects of PRP therapy can take several weeks or months to be seen, and the individual's response to treatment will depend on the condition being treated and the patient's overall health.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can PRP therapy be combined with other treatments?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, PRP therapy can be combined with other treatments, such as physical therapy or surgery, to enhance the healing process and improve the overall outcome.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can anyone get PRP therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              PRP therapy is not suitable for everyone, and your doctor will determine if it's a good option for you based on your health history, age, and the condition being treated.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section> -->

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>Exilis Ultra 360</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is Exilis Ultra 360 painful?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Exilis Ultra 360 is generally considered painless, with some patients reporting a mild warming sensation during the treatment. Your doctor will discuss any potential discomfort with you before the treatment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How soon after treatment can I see the results of Exilis Ultra 360?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Some patients may see an improvement in their skin immediately after the first Exilis Ultra 360 treatment, while others may see more noticeable results after multiple treatments. Your doctor will discuss the expected timeline for results with you.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How long do the results of Exilis Ultra 360 last?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The results of Exilis Ultra 360 can vary depending on the individual, but many patients experience long-lasting results with a touch-up treatment recommended once a year.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Does Exilis Ultra 360 can be done on pregnant and lactating women?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Exilis Ultra 360 is generally not recommended for pregnant or lactating women. Pregnancy and lactation are times when the body undergoes significant changes, and it's best to avoid any non-essential medical procedures during these times.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I continue Exilis Ultra 360 treatment if I had filler/botox aesthetic procedures history?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, it is possible to have Exilis Ultra 360 treatments after having aesthetic procedures such as Botox or fillers. However, your doctor will need to assess the timing and effects of your previous procedures and determine if Exilis Ultra 360 is a safe and appropriate option for you. In some cases, it may be necessary to wait a certain amount of time after a Botox or filler treatment before having Exilis Ultra 360, to avoid interactions or negative effects.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>Pre-Exposure Prophylaxis (PrEP)</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How effective is PrEP at preventing HIV?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              When taken as directed, PrEP is highly effective at preventing HIV transmission. Clinical studies have shown that PrEP can reduce the risk of HIV infection by up to 99% in individuals who are at high risk.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How long does PrEP need to be taken?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              PrEP should be taken for as long as the individual is at risk of contracting HIV. If the risk changes, the individual should discuss the need for continuing or discontinuing PrEP with NADI doctors.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Does PrEP protect against other sexually transmitted infections (STIs)?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              No, PrEP is only effective at preventing HIV transmission and does not protect against other STIs. Patients should use condoms or other forms of protection to prevent the transmission of other STIs.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can PrEP be taken by people who are already HIV-positive?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              No, PrEP is only effective at preventing HIV transmission in individuals who are HIV-negative. It is not recommended for individuals who are already HIV-positive.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can PrEP be used as a replacement for condoms?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              No, PrEP should not be used as a replacement for condoms or other forms of protection during sex. PrEP is most effective when used in combination with other prevention methods, such as condoms.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>Post Exposure Prophylaxis (PEP)</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >When should I consider taking PEP?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              PEP should be taken as soon as possible after a potential exposure to HIV, ideally within 72 hours (3 days). The sooner you start PEP, the more effective it is likely to be.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How long should I take PEP?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              PEP is typically taken for a duration of 28 days (4 weeks). It is important to complete the full course of medication as prescribed by a healthcare professional.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Does PEP guarantee protection against HIV?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              While PEP significantly reduces the risk of HIV transmission, it is not 100% effective. It is important to remember that PEP should be used as a preventive measure and not as a substitute for safer sex practices.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I use PEP as a form of regular HIV prevention?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              No, PEP is not intended for regular or ongoing use. It is designed for emergency situations following a potential exposure to HIV. For regular HIV prevention, consider other strategies such as PrEP (Pre-Exposure Prophylaxis)
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Are there any side effects of PEP?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Like any medication, PEP may have potential side effects. Common side effects include nausea, fatigue, and headache. It is important to discuss potential side effects and any concerns with a healthcare provider.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>Emsella</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How many treatments are needed?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The recommended treatment protocol for Emsella is typically two treatments per week for three weeks, for a total of six treatments. However, the exact number of treatments needed may vary depending on the individual patient and their specific condition.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How fast will I see the result?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              You may observe improvement after a single session. The results will typically continue to improve over the next few weeks.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Is Emsella painful?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Most patients do not experience any pain during Emsella treatments. Some patients may feel a slight tingling or vibration sensation during the treatment, but this is typically not painful. You may resume daily activities immediately after the treatment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Are there any side effects of Emsella?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Emsella is a safe and non-invasive treatment, and most patients do not experience any side effects. However, some patients may experience mild discomfort or muscle soreness after the treatment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can Emsella be used for men?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Yes, Emsella can be used to treat urinary incontinence in both men and women.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-6">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How long do the effects of Emsella last?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The effects of Emsella can last for several months or even longer. However, patients may need periodic maintenance treatments to maintain the results over time.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>Ultra Femme 360</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question">
                Who is the right candidate for Ultra Femme 360?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The procedure is a great option for women who desire non-surgical improvement of their intimate health. The Ultra Femme 360 provides a safe and effective treatment that can work wonders comfortably, with no downtime.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question">
                How long is the treatment? How many do I need?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Intravaginal treatment takes 8 minutes. External treatment is based on your specific needs, and usually takes less than 12 minutes. Your provider will tailor a treatment plan for you. Recommended number of treatment is 3, scheduled once a week.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question">
                How does the procedure feel like? Is it painful?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              While the Ultra Femme360 homogeneously heats the treated area, you will feel a pleasant and comfortable heat. Following the treatment, you may notice mild redness and swelling in the treated area. This usually disappears within a few hours.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question">
                How fast will I see results? Is there any downtime?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Patients have reported improvements after a single treatment session. The results will typically contnue to improve over the next few months. With no downtime you may resume normal activity immediately after the treatment.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>Cellular Therapy</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Where do mesenchymal stem cells come from?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Mesenchymal stem cells (MSCs) can be found in various tissues in the body, including bone marrow, adipose tissue (fat), and umbilical cord blood.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How do I know whether I am suitable for cellular therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Cellular therapy may be an option for individuals who have certain medical conditions that have not responded to conventional treatment. Before undergoing cellular therapy, you should consult with your healthcare provider to determine if it is an appropriate option for your specific condition. 
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How often should I get Cellular Therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The frequency of cellular therapy treatments depends on the specific condition being treated and the type of cellular therapy being used. Cellular therapy treatments is determined on a case-by-case basis by your healthcare provider. 
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Any side effects of Cellular Therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Like any medical procedure, cellular therapy carries benefits and side effects. Some potential side effects of cellular therapy may include:

              <li>Pain or discomfort at the site of injection or infusion</li>
              <li>Swelling or inflammation</li>
              <li>Fever, chills, or flu-like symptoms</li>
              <li>Allergic reactions</li>
              <li>Infection</li>
              <li>Bleeding or bruising</li>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How does Cellular therapy help with anti-aging?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Cellular therapy involves cell-derived products into the body to stimulate cell renewal and regeneration. One of the potential benefits is its anti-inflammatory and antioxidant properties, which can help reduce the effects of oxidative stress and inflammation on body.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>IV Nutrition Therapy</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Does IV Therapy hurt?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Most patients do not experience significant pain during IV Therapy treatments. Some patients may feel a slight pinch or prick when the needle is inserted, but this is typically not painful.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can a pregnant / lactating mother gets IV Therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The use of IV therapy in pregnant women/lactating women should be evaluated on a case-by-case basis and typically is not recommended. Pregnant women/lactating women should always consult with their healthcare provider before undergoing any medical treatment, including IV therapy, to ensure that it is safe for them and their developing baby.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How often should I get IV Therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Nadi doctors recommend that clients receive IV drip therapy once a month, as each infusion contains a unique blend of specific vitamins or nutrients, but it is on case-to-case basic.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What is the difference between IV therapy and taking daily supplements?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Daily supplements have to be digested through the gastrointestinal system before being absorbed into the blood and cells, resulting in limited absorption (low bioavailability). In contrast, IV therapy is administered directly into the bloodstream to reach the cells (higher bioavailability), maximizing the therapeutic effects.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Any side effects of IV Therapy?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              While IV therapy is generally considered safe, like any medical treatment, it does come with potential side effects. Some of the common side effects of IV therapy may include:
              <li>Discomfort or pain at the injection site</li>
              <li>Bruising or swelling at the injection site</li>
              <li>Infection at the injection site</li>
              <li>Nausea & gastrointestinal</li>
              <li>Electrolyte imbalances</li>
              <li>Light-headedness</li>
              <li>In some rare cases, allergic reaction such as rashes, itchiness or difficulty breathing.</li>
              It is important to discuss any potential risks or concerns with your healthcare provider before undergoing IV therapy. If you experience any unexpected or concerning side effects during or after the treatment, it is important to seek medical attention immediately.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <!-- <h3>Faq</h3> -->
        <h2>S-Pen (Saxenda) </h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question"
                >What should I do if I miss a dose of S-Pen?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              If you miss a dose of S-Pen, take it as soon as you remember, and continue with your regular dosing schedule. If it is almost time for your next dose, skip the missed dose and take your next dose at the regular time. Do not take extra medication to make up for a missed dose.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question"
                >How much weight can I expect to lose with S-Pen?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The amount of weight loss with S-Pen varies by individual, but studies have shown that patients using S-Pen can lose up to 10% of their body weight. S-Pen is most effective when used in combination with a healthy diet and increased physical activity.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Who can take S-Pen?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              S-Pen is approved for adults with a body mass index (BMI) of 27 or greater who also have a weight-related medical condition such as type 2 diabetes or high blood pressure, or adults with a BMI of 30 or greater.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Any side effects of S-Pen?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The most common side effects of S-Pen are nausea, vomiting, diarrhea, constipation, stomach pain, and decreased appetite. Please follow the dosage as prescribed by NADI doctors.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question"
                >Can I take S-Pen if I am pregnant or breastfeeding?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              S-Pen is not recommended for use during pregnancy or breastfeeding, as the effects of the medication on the developing fetus or nursing infant are not known.Clients should talk to their healthcare provider if they are pregnant or breastfeeding and considering S-Pen.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

  <section class="section-faq py-5">
    <div class="container">
      <div class="title-area">
        <h2>Antenatal Scan</h2>
        <p>Here’s some of the common things people ask us, for your clarity.</p>
      </div>
      <div class="faq-list">
        <ngb-accordion [closeOthers]="true">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <span class="question">
                I'm pregnant! When should I go for my first ultrasound scan?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Congratulations! You can attend your first scan as early as 8 weeks, as the baby is seen after 6 weeks of pregnancy.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <span class="question">
                Is the ultrasound scan safe?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              There are no known risks to the baby or the mother from having an ultrasound scan. However, the gel might be cold initially and the doctor may need to press your tummy, should some parts of the baby is difficult to see.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <span class="question">
                What can I expect during an Ultrasound scan?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              The Ultrasound is a common , non invasive procedure that allows the doctor to get images of your baby and check on the progress of the baby. THe doctor will ask you to lie down and will apply some gel on your tummy which will help facilitate the scan.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <span class="question">
                What do I need to do before my Ultrasound scan appointment?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              You can wear loose fitting clothers; preferably  2-piece clothing, as it will be easier for the doctor to perform the scan without you feeling uncomfortable.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <span class="question">
                Do I need a full bladder?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              A full bladder is required for these scans when you are less than 19 weeks pregnant. This helps the doctor see your baby more clearly. 
              Drinking 500ml of water an hour before your scan can help fill your bladder in preparation for the scan.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-6">
            <ng-template ngbPanelTitle>
              <span class="question">
                How frequently should I come for an Ultrasound scan?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Usually Growth Scans are done to check the progress of your pregnancy. These are done once every 4 weeks upto the 28th week; then every 2 weeks  up to the 35th week; then weekly until birth.
              <br><br>
              Apart from Growth Scans; the ultrasound can be used for identifying important milestones:
              <ul>
                <li>Early 1st scan between 8 weeks to 12 weeks; this gives the best accuracy for the due date</li>
                <li>Dating Scan 12 weeks to better establish the due date based on measurement</li>
                <li>Check whether you're having more that one baby</li>
                <li>Nuchal Translucency scan (NT) to determine the likelihood of baby having any Trisomies (Down's Syndrome, Edward's Syndrome or Patau's Syndrome)</li>
                <li>Detecting position of your baby and the placenta- this may affect your mode of delivery</li>
                <li>Screening Anatomy Scan done between 18 weeks to 24 weeks helps the doctor identify if your baby has any anomalies or congenital disorders or major abnormalities, as well as the gender of your baby.</li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-7">
            <ng-template ngbPanelTitle>
              <span class="question">
                When can I do  a 3D4D/5HD scan?
                <div class="plus-minus">
                  <span class="plus">+</span>
                  <span class="minus">-</span>
                </div>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              These scan can be done as early as 20 weeks, but best done between 27 weeks to 32 weeks , as baby will be better developed chubbier and provide a better image
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </section>

</div>
