<div class="wrapper-health-screening">
    <header class="py-5 inner-header" style="background:url('../../../assets/images/clinic.png')no-repeat center center;">
      <div class="header-overlay"></div>
      <div class="container py-4 px-5">
         <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
               <div class="text-center">
                  <h1 class="fw_700 text-white">Platelet-rich plasma (PRP) Therapy	</h1>
                  <p class="fw-normal text-white-50 mb-0"><a class="text-white" href="index.html">Aesthetic Services</a> / Platelet-rich plasma (PRP) Therapy	</p>
               </div>
            </div>
         </div>
      </div>
    </header>