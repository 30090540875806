<header id="header">
  <div class="container head">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
      <a  routerLink="/"  class="logo-header">
        <img class="logo" src="../../../assets/images/nadi logo.png" >
        <div class="logo-title">Meditech</div>
      </a>

      <nav class="navbar navbar-expand-lg mx-auto">
          <div class="collapse navbar-collapse" id="navbarText">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <div routerLink="/" class="nav-link nav-link-ltr">
                  Home
                </div>
                <div class="nav-link nav-link-ltr" routerLink="/about-us" >
                  About Us
                </div>
                <div class="nav-item dropdown" 
                  (mouseover)="isServiceMenu = true" 
                  (mouseleave)="isServiceMenu = false" 
                  [class.nav-active]="isServiceMenu"
                  >
                  <a  class="dropdown-toggle nav-link pr-2" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services <span style="margin-left: 5px; margin-top: -5px;" class="chevron down"></span></a>
                  <div class="floating-menu">
                    <div class="row mega-menu">
                      <div class="col-12 col-md-4 mt-3">
                        <div>
                          <h6 class="menu-title">General</h6>
                          <div>
                            <h6 routerLink="/service-general" (click)="isServiceMenu = false" class="menu-link">Medical Consultation</h6>
                            <h6 routerLink="/service-teleconsultation" (click)="isServiceMenu = false" class="menu-link">Teleconsultation</h6>
                            <h6 routerLink="/service-doctor-home-visit" (click)="isServiceMenu = false" class="menu-link">Doctor Home Visit</h6>
                            <h6 routerLink="/service-health-screening" (click)="isServiceMenu = false" class="menu-link">Health Screening</h6>
                            <h6 routerLink="/service-vaccination" (click)="isServiceMenu = false" class="menu-link">Vaccination</h6>
                          </div>
                        </div>
                        <div class="mt-5 pt-2">
                          <h6 class="menu-title">Urinary Incontinence</h6>
                          <div>
                            <h6 routerLink="/service-urinary-incontinence" (click)="isServiceMenu = false" class="menu-link">Emsella</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-4 mt-3">
                        <div>
                          <h6 class="menu-title">Beauty</h6>
                          <div>
                            <h6 routerLink="/exilis-ultra" (click)="isServiceMenu = false" class="menu-link">Head-to-Toe Contouring</h6>
                            <h6 routerLink="/service-slimming" (click)="isServiceMenu = false" class="menu-link">Slimming</h6>
                          </div>
                        </div>
                        <div class="mt-5">
                          <h6 class="menu-title">Regenerative Medicine</h6>
                          <div>
                            <h6 routerLink="/service-cellular-therapy" (click)="isServiceMenu = false" class="menu-link">Cellular Therapy</h6>
                          </div>
                        </div>
                        <div class="mt-5">
                          <h6 class="menu-title">Sexual Health</h6>
                          <div>
                            <h6 routerLink="/service-vaginal-tightening" (click)="isServiceMenu = false" class="menu-link">Vaginal Tightening</h6>
                            <h6 routerLink="/service-sti-screening" (click)="isServiceMenu = false" class="menu-link">STI Test & Treatment</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-4 mt-3">
                        <h6 class="menu-title">Nutrition</h6>
                        <div>
                          <h6 routerLink="/service-revive-therapy" (click)="isServiceMenu = false" class="menu-link">IV Nutrition Therapy</h6>
                          <h6 routerLink="/service-nutrition-diet" (click)="isServiceMenu = false" class="menu-link">Nutrition & Diet Consultation</h6>
                        </div>
                      </div>
                    </div>
                    <!-- <ul>
                      <li><div routerLink="/service-general">General Medical Consultant</div></li>
                      <li><div routerLink="/service-doctor-home-visit">Doctor Home Visit</div></li>
                      <li><div routerLink="/service-health-screening">Health Screening</div></li>
                      <li><div routerLink="/service-aesthetic">Aesthetic</div></li>
                      <li><div routerLink="/service-sexual-health">Sexual Health</div></li>
                      <li><div routerLink="/service-urinary-incontinence">Urinary Incontinence</div></li>
                      <li><div routerLink="/service-wellness">Wellness</div></li>
                      <li><div routerLink="/service-slimming">Slimming</div></li>
                    </ul> -->
                  </div>
                </div>
                <div class="nav-link" routerLink="/about-clinic" >
                  Our Clinic
                </div>
                <div class="nav-link" routerLink="/our-team">
                    Our Team
                  </div>
                <div routerLink="/contact-us" class="nav-link">Contact Us</div>

              </ul>
              
          </div>
      </nav>
      <div class="nav-action">
        <div class="ml-2 mt-1 d-flex flex-row">
          <!-- <div class="button-action"
            (outsideClick)="isSearchBox = false" outsideClick    
          >
            <button (click)="toggleSearchBox()" class="btn btn-primary btn-search"> 
              <img class="icon" src="../../../assets/images/search-icon.png" alt="icon">
            </button>
            <div class="search-box" 
              [class.search-box-open]="isSearchBox"
            >
              <i class="bi bi-search mr-1"></i>
              <input type="text" class="form-control" placeholder="Search"
                [(ngModel)]="search"
                (keyup.enter)="submitSearch()"
              >
            </div>
           
          </div> -->
          <button class="btn btn-primary btn-search mx-3 show-lg"> 
            <a href="https://wa.me/+60126468392" target="_blank">
              <img class="icon" src="../../../assets/images/whatsapp-icon.png" alt="icon">
            </a>
          </button>
          <!-- <button class="btn btn-primary btn-search show-lg"> 
            <img class="icon" src="../../../assets/images/calendar-icon.png" alt="icon">
          </button> -->
        </div>
      </div>
      <!-- <div class="ml-auto">
        <div class="button-action"
          (outsideClick)="isSearchBox = false" outsideClick    
        >
          <button (click)="toggleSearchBox()" class="btn btn-primary btn-search"> 
              <i class="bi bi-search mr-1"></i>
          </button>
          <div class="search-box" 
            [class.search-box-open]="isSearchBox"
          >
            <i class="bi bi-search mr-1"></i>
            <input type="text" class="form-control" placeholder="Search"
              [(ngModel)]="search"
              (keyup.enter)="submitSearch()"
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</header>

<div
          (click)="toggleMenu()" 
          [className]="menuOpen ? 'button_container active':'button_container'" id="toggle"
          >
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>
    
        <div id="mySidenav" [className]="menuOpen ? 'sidenav open':'sidenav close'">
          <div routerLink="/" (click)="toggleMenu()" class="nav"></div>
          <div routerLink="/" class="nav" (click)="toggleMenu()">
            Home
          </div>
          <div class="nav" routerLink="/about-us" (click)="toggleMenu()">About Us</div>
          <div class="nav"
            (click)="collapse.toggle()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
          >
            Services
            <span [className]="isCollapsed ? 'chevron down':'chevron up'"></span>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="card card-menu p-0">
              <div class="card-body p-0">

                <!-- general -->
                <div routerLink="/service-general" (click)="toggleMenu()" class="nav">Medical consultation</div>
                <div routerLink="/service-teleconsultation" (click)="toggleMenu()" class="nav">Teleconsultation</div>
                <div routerLink="/service-doctor-home-visit" (click)="toggleMenu()" class="nav">Doctor Home Visit</div>
                <div routerLink="/service-health-screening" (click)="toggleMenu()" class="nav">Health screening</div>
                <div routerLink="/service-vaccination" (click)="toggleMenu()" class="nav">Vaccination</div>

                <!-- aesthetic -->
                <div routerLink="/exilis-ultra" (click)="toggleMenu()" class="nav">Head-to-Toe Contouring</div>
                <div routerLink="/service-slimming" (click)="toggleMenu()" class="nav">Slimming</div>

                <!-- sexual health -->
                <div routerLink="/service-sti-screening" (click)="toggleMenu()" class="nav">STI Screening & Treatment</div>
                <div routerLink="/service-vaginal-tightening" (click)="toggleMenu()" class="nav">Vaginal Tightening</div>

                <!-- urinary incontinence -->
                <div routerLink="/service-urinary-incontinence" (click)="toggleMenu()" class="nav">Emsella</div>

                <!-- regenerative medicine -->
                <div routerLink="/service-cellular-therapy" (click)="toggleMenu()" class="nav">Cellular Therapy</div>

                <!-- nutrition -->
                <div routerLink="/service-revive-therapy" (click)="toggleMenu()" class="nav">Revitalisation Therapy</div>
                <div routerLink="/service-nutrition-diet" (click)="toggleMenu()" class="nav">Nutrition & Diet Consultation</div>

              </div>
            </div>
          </div>
          <div class="nav" routerLink="/about-clinic" (click)="toggleMenu()">Our Clinic</div>
          <div class="nav" routerLink="/our-team" (click)="toggleMenu()">Our Team</div>
          <div class="nav" routerLink="/contact-us" (click)="toggleMenu()">Contact Us</div>
          <div class="nav" routerLink="/faq" (click)="toggleMenu()">FAQs</div>
          <div class="mt-3">
            <!-- <div class="button-action  text-center">
              <a class="btn btn-primary px-3 py-2" href="https://appt.link/meet-with-enpa-silan/example-meeting" target="_blank"> 
                  <i class="bi bi-calendar3 mr-1"></i> Book Appoinment
              </a>
            </div> -->
            <!-- <div class="d-flex justify-content-end pr-3">
              <button class="btn btn-primary btn-search mx-3"> 
                <img class="icon" src="../../../assets/images/whatsapp-icon.png" alt="icon">
              </button>
              <button class="btn btn-primary btn-search"> 
                <img class="icon" src="../../../assets/images/calendar-icon.png" alt="icon">
              </button>
            </div> -->
          </div>
        </div>

<!-- <nav class="navbar">
    <div class="px-50">
      <div class="d-flex justify-content-between w-100">
        <div class="">
          <img routerLink="/" class="logo" src="../../../assets/images/nadi logo.png" >
        </div>
        <div class="show-lg d-flex justify-content-end">
          <div class="nav">
            <div routerLink="/" class="nav-link">
              Home
            </div>
            <div class="nav-link" routerLink="/about-us" >
              About Us
            </div>
            <div class="nav-link" routerLink="/our-services" >
              Services
            </div>
            <div class="nav-link" routerLink="/about-clinic" >
              Our Clinic
            </div>
            <div class="nav-link" routerLink="/our-team">
                Our Team
              </div>
            <div routerLink="/contact-us" class="nav-link">Contact Us</div>

            <button class="btn btn-primary" (click)="openModal()"
            > <i class="bi bi-calendar2-heart mr-1"></i> Book Appoinment</button>
          </div>
        </div>
        
  
    
        <div
          (click)="toggleMenu()" 
          [className]="menuOpen ? 'button_container active':'button_container'" id="toggle"
          >
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>
    
        <div id="mySidenav" [className]="menuOpen ? 'sidenav open':'sidenav close'">
          <div routerLink="/" (click)="toggleMenu()" class="nav"></div>
          <div aria-controls="collapseExample" class="nav">Home</div>
          <div aria-controls="collapseExample" class="nav">About Us</div>
          <div aria-controls="collapseExample" class="nav">Our Services</div>
          <div aria-controls="collapseExample" class="nav">Clinic</div>
          <div aria-controls="collapseExample" class="nav">Our Team</div>
          <div aria-controls="collapseExample" class="nav">Contact Us</div>
          <button class="btn btn-primary" (click)="openModal()"
          > <i class="bi bi-calendar2-heart mr-1"></i> Book Appoinment</button>
        </div>
      </div>
      
    </div>
  </nav> -->

<!-- <div class="mega-menu-mobile" 
  [class.mega-menu-open]="isMegaMenu"
  (outsideClick)="isMegaMenu = false" 
  outsideClick     
>
  <div class="row mega-menu">
    <div class="col-12 col-md-4 mt-3">
      <h6 class="menu-title">General</h6>
      <div>
        <h6 routerLink="/service-general" (click)="isMegaMenu = false" class="menu-link">Medical consultation</h6>
        <h6 routerLink="/service-teleconsultation" (click)="isMegaMenu = false" class="menu-link">Teleconsultation</h6>
        <h6 routerLink="/service-doctor-home-visit" (click)="isMegaMenu = false" class="menu-link">Doctor Home Visit</h6>
        <h6 routerLink="/service-health-screening" (click)="isMegaMenu = false" class="menu-link">Health screening</h6>
        <h6 routerLink="/service-vaccination" (click)="isMegaMenu = false" class="menu-link">Vaccination</h6>
      </div>
    </div>
    <div class="col-12 col-md-4 mt-3">
      <h6 class="menu-title">Aesthetic</h6>
      <div>
        <h6 routerLink="/exilis-ultra" (click)="isMegaMenu = false" class="menu-link">Exilis Ultra 360</h6>
        <h6 routerLink="/service-slimming" (click)="isMegaMenu = false" class="menu-link">Slimming</h6>
      </div>
    </div>
    <div class="col-12 col-md-4 mt-3">
      <h6 class="menu-title">Sexual Health</h6>
      <div>
        <h6 class="menu-link">For Him</h6>
        <h6 class="menu-link">For Her</h6>
        <h6 routerLink="/service-sti-screening" (click)="isMegaMenu = false" class="menu-link">STI Screening & Treatment</h6>
        <h6 routerLink="/service-vaginal-tightening" (click)="isMegaMenu = false" class="menu-link">Vaginal Tightening</h6>
      </div>
    </div>
    <div class="col-12 col-md-4 mt-3">
      <h6 class="menu-title">Urinary Incontinence</h6>
      <div>
        <h6 routerLink="/service-urinary-incontinence" (click)="isMegaMenu = false" class="menu-link">Emsella</h6>
      </div>
    </div>
    <div class="col-12 col-md-4 mt-3">
      <h6 class="menu-title">Regenerative Medicine</h6>
      <div>
        <h6 routerLink="/service-cellular-therapy" (click)="isMegaMenu = false" class="menu-link">Cellular Therapy</h6>
      </div>
    </div>
    <div class="col-12 col-md-4 mt-3">
      <h6 class="menu-title">Nutrition</h6>
      <div>
        <h6 routerLink="/service-revive-therapy" (click)="isMegaMenu = false" class="menu-link">Revitalisation Therapy</h6>
        <h6 routerLink="/service-nutrition-diet" (click)="isMegaMenu = false" class="menu-link">Nutrition & Diet Consultation</h6>
      </div>
    </div>
  </div>
</div> -->