import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-service-urinary-incontinence',
  templateUrl: './service-urinary-incontinence.component.html',
  styleUrls: ['./service-urinary-incontinence.component.scss']
})
export class ServiceUrinaryIncontinenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.initClientSlider();
  }

  initClientSlider() {
    $('.testimonials-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }

}

