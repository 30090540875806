import { Component, OnInit } from '@angular/core';
import { doctorData } from './doctor';

@Component({
  selector: 'app-doctor-info',
  templateUrl: './doctor-info.component.html',
  styleUrls: ['./doctor-info.component.scss']
})
export class DoctorInfoComponent implements OnInit {

  img: any;
  name: any;
  title: any;
  desc: any;
  biography: any;
  biography2: any;
  biography3: any;
  special_interest: any;
  language: any;
  degree: any;
  year: any;
  institute: any;

  sortOptions = doctorData;

  constructor() { }

  ngOnInit(): void {
    this.img = sessionStorage.getItem('img');
    this.name = sessionStorage.getItem('name');
    this.title = sessionStorage.getItem('title');
    this.desc = sessionStorage.getItem('desc');
    this.biography = sessionStorage.getItem('biography');
    this.biography2 = sessionStorage.getItem('biography2');
    this.biography3 = sessionStorage.getItem('biography3');
    this.special_interest = sessionStorage.getItem('special_interest');
    this.language = sessionStorage.getItem('language');
    this.year = sessionStorage.getItem('year');
      this.degree = sessionStorage.getItem('degree');
      this.institute = sessionStorage.getItem('institute');
  }

  setDoctorData(i: any) {
    sessionStorage.setItem('img', this.sortOptions[i].image);
    sessionStorage.setItem('name', this.sortOptions[i].name);
    sessionStorage.setItem('title', this.sortOptions[i].title);
    sessionStorage.setItem('desc', this.sortOptions[i].desc);
    sessionStorage.setItem('biography', this.sortOptions[i].biography);
    sessionStorage.setItem('biography2', this.sortOptions[i].biography2);
    sessionStorage.setItem('biography3', this.sortOptions[i].biography3);
    sessionStorage.setItem('special_interest', this.sortOptions[i].special_interest);
    sessionStorage.setItem('language', this.sortOptions[i].language);
    sessionStorage.setItem('year', this.sortOptions[i].year);
    sessionStorage.setItem('degree', this.sortOptions[i].degree);
    sessionStorage.setItem('institute', this.sortOptions[i].institute);

    location.reload();
  }
}
