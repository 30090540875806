export const doctorData = [
  {  
    image: '../../../assets/images/Dr-Amos.png',
    name: 'Dr Amos Ng',
    title: 'Chief Medical Doctor',
    desc: 'Doctor of Medicine First Moscow State University ',
    biography: 'Dr Amos graduated from First Moscow State Medical University with degree of Medical Doctor in 2017. He did his internship in Hospital Melaka with various medical disciplines, then he served his compulsory service in Hospital Tengku Ampuan Rahimah.',
    biography2: 'During his service in Internal Medicine and Rehabilitation Medicine, he observed how important it is to treat the root cause of a health condition, diseases and help to reduce or prevent complication.',
    biography3: 'Dr Amos believes healing is not an overnight process, it is a daily cleansing of pain and he is more to happy to share his experience with patients.',
    special_interest:'Stroke rehabilitation, cognitive stimulation, advance wound care',
    language: 'Bahasa Malaysia, English and Chinese dialects.',
    degree: 'Medical Doctor',
    year: '2017',
    institute: 'First Moscow State Medical University'
  },
  { 
    image: '../../../assets/images/Dr-Edwina.png',
    name: 'Dr Edwina Tong',
    title: 'Medical Doctor',
    desc: 'Doctor of Medicine First Moscow State University',
    biography: 'Dr Edwina graduated from First Moscow State University with MD(Dean`s list award) in 2017. She did her internship in district hospital in Temerloh Pahang followed by 3 years of internal medicine training in Hospital Kuala Lumpur.',
    biography2: 'Dr Edwina further pursued her studies in internal medicine by completing part 2 written of MRCP. After 2 years of specialist training in internal medicine, Dr Edwina realized that conventional internal medicine only control the diseases. She decided to focus more on functional medicine which is to know the root cause of disease by systems based-approach and to treat the person instead of only treating the symptoms.',
    biography3: 'Dr Edwina firmly believes that the patient’s best interest comes first and persistently keep herself updated in medical practice.',
    special_interest:'Internal Medicine, Functional Medicine ',
    language: 'Bahasa Malaysia, English and Chinese dialects.',
    degree: 'Medical Doctor',
    year: '2017',
    institute: 'First Moscow State Medical University'
  },
  { 
    image: '../../../assets/images/Dr-Luqman.png',
    name: 'Dr Luqman',
    title: 'Medical Doctor',
    desc: 'Doctor of Medicine Al-Azhar University, Cairo',
    biography: 'Dr Luqman Alhakim completed his medical degree in Al-Azhar University, Cairo. He then served his compulsory service in Hospital Shah Alam and Hospital Sungai Buloh. During his rotations in internal medicine, he observed firsthand how metabolic diseases and transmissible infections can alter and affect a person`s life greatly, and later on also understand how these conditions are very treatable and have their complications reversed. Dr Luqman`s area of expertise includes cardiovascular health, diabetes and cholesterol management, sexually transmitted diseases, HIV infection, and male sexual health.',
    graduate: '',
    interest: '',
    language: ''
  },
  { 
    image: '../../../assets/images/Dr-Ashraf.png',
    name: 'Dr Ashraf',
    title: 'Medical Doctor',
    desc: 'Doctor of Medicine Universiti Sabah Malaysia',
    biography: 'Dr Ashraf graduated from the University Malaysia Sabah in degrees Medical Doctor in 2017 . He then served his compulsory service in Hospital Canselor Tuanku Muhriz, and Klinik Kesihatan Labuan.',
    biography2: 'Dr Ashraf learned the essential skills in attending patients including those with special needs and attentions.',
    biography3: 'Dr Ashraf strongly believes health does not always come from medicine. Most of the time it comes from peace of mind, heart and soul',
    special_interest:'Family Medicine, Infectious Disease',
    language: 'Bahasa Malaysia and English.',
    degree: 'Medical Doctor',
    year: '2017',
    institute: 'University Malaysia Sabah'
  },
  { 
    image: '../../../assets/images/Ashlee.png',
    name: 'Ashlee Chong',
    title: 'Senior Dietitian',
    desc: 'Bachelor Science in Dietetics (Hons) Universiti Putra Malaysia',
    biography: 'Ashlee Chong practiced as a senior clinical dietitian in National Heart Institute since 2013. She is specialized in providing individualized nutrition consultation for cases such as weight management, diabetes, heart disease, cancer, kidney disease, hyperlipidemia, hypertension, stroke and nutrition support which is evidenced based.',
    biography2: 'She is also in the Transplant team where they have multidisciplinary approach with surgeons and doctors to manage transplant patients. In addition, she is actively involved in various community and media programs in delivering talks, creating recipes and conducting cooking demonstrations too.',
    biography3: 'She always believe prevention is better than cure and let nutrition be your new medicine.',
    special_interest:'Weight management, diabetes management and preventive medicine',
    language: 'Bahasa Malaysia, English and Chinese dialects.',
    degree: 'Bachelor Science in Dietetics (Hons)',
    year: '2013',
    institute: 'Universiti Putra Malaysia'
  },
  { 
    image: '../../../assets/images/Mogan.png',
    name: 'Mogan Ram',
    title: 'Chief Pharmacist',
    desc: 'BPharm (Hons.) International Medical University (IMU)',
    biography: 'Mogan Ram completed his pharmacy degree with honours at IMU in 2011. He served his compulsory service as a Provisionally Registered Pharmacist (PRP) at University Malaya Medical Centre (UMMC), then as a Fully Registered Pharmacist (FRP) at Pharmacy Enforcement Division HQ. He continued his enforcement journey at the state level working in the Health Department of WPKL and Putrajaya.',
    biography2: 'In 2020, he was transferred to Hospital Putrajaya to overlook the operations in the Outpatient department. During his tenure as a Drug Enforcement Officer (DEO), he has equipped himself with many skills and knowledge related to enforcement and regulatory framework such as pharmacy licensing, product registration, online operations involving medicines and supplements and many other enforcement knowledge through his 8 years experience.',
    biography3: 'He is passionate to bring a change in the medical system practiced in Malaysia which is ethical, convenient and most importantly helpful for all Malaysians. He joined NADI in hopes to achieve this transformation and make healthcare easy for our people.',
    special_interest:'Any medine-related inquiries, counselling and procurement for best suited products for customers',
    language: 'Bahasa Melayu, English and Tamil.',
    degree: 'BPharm (Hons.)',
    year: '2011',
    institute: 'International Medical University (IMU)'
  }
]