<section class="wrapper-team">
    <header class="py-5 inner-header" style="background:url('../../../assets/images/our-team.png')no-repeat center center;">
      <div class="header-overlay"></div>
      <div class="container py-4 px-5">
         <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
               <div class="text-center">
                  <h1 class="fw_700 text-white">Our Team</h1>
                  <p class="fw-normal text-white-50 mb-0"><a class="text-white" href="index.html">Home</a> / Our Team</p>
               </div>
            </div>
         </div>
      </div>
      </header>
      <div class="bio-doctor h-100 py-5 relative">
          <div class="container px-50" data-aos="fade-up" data-aos-duration="800" data-aos-delay="0">
            <div class="row h-100">
              <div class="col-12 col-md-5 col-sm-12 relative">
                <div class="shadow-sm overflow-hidden rounded-3 mb-3">
                    <div class="text-center py-2">
                       <img class="card-img-top" src="{{ img }}" alt="{{ name }}">
                    </div>
               </div>
              </div>
              <div class="col-12 col-md-7 col-sm-12 align-items-center">
                <div>
                  <h6 class="text_blue_1 mt-5">BIOGRAPHY</h6>
                  <h1 class="fw_600 title fw-400 text-dark mt-3">
                    {{ name }}
                  </h1>
                  <h6 class="fw_400 mt-3 mb-4 text-justify">
                     {{title}}
                  </h6>
                  <h6 class="fw_400 mt-3 mb-4 text-justify">
                     {{biography}}
                  </h6>
                  <h6 class="fw_400 mt-3 mb-4 text-justify">
                     {{biography2}}
                  </h6>
                  <h6 class="fw_400 mt-3 mb-4 text-justify">
                     {{biography3}}
                  </h6>
                  <h6 class="fw_400 mt-3 mb-4 text-justify">
                     <b>Special Interest:</b> {{special_interest}}
                  </h6>
                </div>
                
              </div>
            </div>
          </div>
      </div>
      <div class="section-doctors py-5">
        <div class="container text-center">
           <h4 class="font-weight-bold text-left py-2">Related Doctors</h4>
           <div class="doctor-wrapper row">
               <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(2)">
                  <div class="team-inner">
                     <div class="team-photo">
                        <img src="../../../assets/images/home/Dr-Luqman.png">
                     </div>
                     <div class="team-info mt-3">
                        <div class="team-name">Dr Luqman</div>
                        <div class="team-post">Medical Doctor</div>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(0)">
                  <div class="team-inner">
                     <div class="team-photo">
                        <img src="../../../assets/images/home/Dr-Amos.png">
                     </div>
                     <div class="team-info mt-3">
                        <div class="team-name">Dr Amos Ng</div>
                        <div class="team-post">Chief Medical Doctor</div>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(1)">
                  <div class="team-inner">
                     <div class="team-photo">
                        <img src="../../../assets/images/home/Dr-Edwina.png">
                     </div>
                     <div class="team-info mt-3">
                        <div class="team-name">Dr Edwina Tong</div>
                        <div class="team-post">Medical Doctor</div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-4">
                    <div class="card" >
                       <div class="row px-3 pt-4">
                          <div class="col-md-4">
                             <div class="image-box">
                                <img src="../../../assets/images/home/Dr-Luqman.png" >
                             </div>
                          </div>
                          <div class="col-md-8">
                             <h5 class="font-weight-bold text-left">Dr Luqman</h5>
                             <p class="fs-12 mx-0 text-left">Medical Doctor</p>
                          </div>
                       </div>
                        <div class="card-body">
                          <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                          <div class="d-flex align-items-center justify-content-between">
                             <a href="" class="btn btn-primary w-100 px-4 py-3">GET APPOINMENT</a>
                          </div>
                       </div>
                        
                    </div>
                </div>
                <div class="col-md-4">
                 <div class="card" >
                    <div class="row px-3 pt-4">
                       <div class="col-md-4">
                          <div class="image-box">
                             <img src="../../../assets/images/home/Dr-Amos.png" >
                          </div>
                       </div>
                       <div class="col-md-8">
                          <h5 class="font-weight-bold text-left">Dr Amos Ng</h5>
                          <p class="fs-12 mx-0 text-left">Chief Medical Doctor</p>
                       </div>
                    </div>
                     <div class="card-body">
                       <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                       <div class="d-flex align-items-center justify-content-between">
                          <a href="" class="btn btn-primary w-100 px-4 py-3">GET APPOINMENT</a>
                       </div>
                    </div>
                 </div>
             </div>
             <div class="col-md-4">
              <div class="card" >
                 <div class="row px-3 pt-4">
                    <div class="col-md-4">
                       <div class="image-box">
                          <img src="../../../assets/images/home/Dr-Edwina.png" >
                       </div>
                    </div>
                    <div class="col-md-8">
                       <h5 class="font-weight-bold text-left">Dr Edwina Tong</h5>
                       <p class="fs-12 mx-0 text-left">Medical Doctor</p>
                    </div>
                 </div>
                  <div class="card-body">
                    <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <div class="d-flex align-items-center justify-content-between">
                       <a href="" class="btn btn-primary w-100 px-4 py-3">GET APPOINMENT</a>
                    </div>
                 </div>
                  
              </div>
            </div> 
            </div> -->
        </div>
  
    </div>
      <!-- <app-contact></app-contact> -->
  </section>
  