<div class="wrapper-about">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
       <div class="row align-items-center justify-content-center">
          <div class="col-lg-12">
             <div class="text-center">
                <h1 class="fw_500">About Us</h1>
                <p class="fw-normal"><a href="index.html">Home</a> / About Us</p>
             </div>
          </div>
       </div>
    </div>
  </header>
  
    <div class="p-3 px-4 pb-5">
        <img class="img-fluid hero-banner" src="../../../assets/images/new/about-us/about-banner-new.png" >
    </div>

    <div class="history-section py-5">
        <div>
            <div class="row">
                <div class="col-md-3 image-container">
                    <img class="image-left img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/new/about-us/history-1.png" >
                </div>
                <div class="col-md-6">
                    <div class="pl-5">
                        <p class="subtitle mb-2">Brief History</p>
                        <h2 class="title fw-500 mb-3">Get to know us more</h2>
                        <p class="description fs-6 fw-light text-muted mb-0 text-justify">The company's name "NADI" is derived from Sanskrit word that is translated as <b>"channel, flow or pulse"</b>. It refers to the network of channels through which energy travels through the body which represents the company's focus in being the healthcare provider who is closest to your heart. We believe in increasing accesibility and building a long term relationship with you to understand and treat you better.</p><br>
                        <p class="description fs-6 fw-light text-muted mb-0 text-justify">The company's primary focus is to provide convenience in helping you to avoid long hospital/clinic queue, receive medical services in the comfort of your home, easy access to doctors via teleconsultation and reducing the unneccasary trips to the doctor.</p><br>
                        <p class="description fs-6 fw-light text-muted mb-0 text-justify">NADI is dedicated to transform the way we approach primary healthcare, by combining a holistic approach with the latest advancements in regenerative medicine and aesthetic procedures. With a focus on preventive care, our mission is to provide personalized, cutting-edge solutions that not only address our patients' physical needs, but also promote overall wellness and rejuvenation.</p><br>
                      </div>
                </div>
                <div class="col-md-3 image-container">
                  <img class="image-right img-fluid rounded mb-5 mb-lg-0" src="../../../assets/images/new/about-us/history-2.png" >
                </div>
            </div>
        </div>
    </div>

    <div class="mission-section">
      <div class="container p-5">
        <div>
          <h2 class="text-center title">Our Mission & Vision</h2>
          <div class="col-12 col-lg-11 mx-auto row mt-4">
            <div class="col-12 col-lg-6 d-flex justify-content-center mb-3">
              <div class="card">
                <h4 class="card-title text-center vision-title">Our Vision</h4>
                <h6 class="card-subtitle text-center">We aim to elevate humanity, beautify life and to be a positive force to the community we serve, from your very own doorstep.</h6>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-center mb-3">
              <div class="card">
                <h4 class="card-title text-center mission-title">Our Mission</h4>
                <h6 class="card-subtitle text-center">
                  - Enhancing primary healthcare, through in-depth focus on preventive & regenerative medicine with the aid from modern advancement healthcare.
                  <br>
                  - Ensuring prompt, efficient and high-quality services at your convenience.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section-doctor meet-our-team h-100 relative">
      <div class="container">
        <div class="row align-items-center mb-4">
          <div class="col-md-8 col-12">
            <div class="title-area">
              <p class="subtitle mb-2">Our team</p>
              <h3 class="title">Meet our experts</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="row">
              <div class="col-md-6 my-2">
                <div class="doctor-links">
                  <div class="doctor-item"
                    (click)="chooseTeam('Dr Gopee','Chief Operating Officer','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam','../../../assets/images/new/team/dr-gopee.png')"
                    [class.doctor-item-active]="selectedTeamName == 'Dr Gopee'"
                  >
                    <div class="doctor-icon">
                      <img src="../../../assets/images/home/medical-doctor-icon.png">
                    </div>
                    <div class="doctor-info">
                      <div class="doctor-name">
                        Dr Gopee
                      </div>
                      <div class="doctor-position">
                        Chief Operating Officer
                      </div>
                    </div>
                    <div class="doctor-arrow">
                      <span class="chevron down"></span>
                    </div>
  
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2">
                <div class="doctor-links">
                  <div class="doctor-item"
                    (click)="chooseTeam('Dr Edwina Tong','Resident Doctor','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam','../../../assets/images/new/team/dr-edwina.png')"
                    [class.doctor-item-active]="selectedTeamName == 'Dr Edwina Tong'"
                  >
                    <div class="doctor-icon">
                      <img src="../../../assets/images/home/medical-doctor-icon.png">
                    </div>
                    <div class="doctor-info">
                      <div class="doctor-name">
                        Dr Edwina Tong
                      </div>
                      <div class="doctor-position">
                        Resident Doctor
                      </div>
                    </div>
                    <div class="doctor-arrow">
                      <span class="chevron down"></span>
                    </div>
  
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2">
                <div class="doctor-links">
                  <div class="doctor-item"
                    (click)="chooseTeam('Dr Amos Ng','Resident Doctor','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam', '../../../assets/images/new/team/dr-amos.png')"
                    [class.doctor-item-active]="selectedTeamName == 'Dr Amos Ng'"
                  >
                    <div class="doctor-icon">
                      <img src="../../../assets/images/home/chief-medical-doctor-icon.png">
                    </div>
                    <div class="doctor-info">
                      <div class="doctor-name">
                        Dr Amos Ng
                      </div>
                      <div class="doctor-position">
                        Resident Doctor
                      </div>
                    </div>
                    <div class="doctor-arrow">
                      <span class="chevron down"></span>
                    </div>
  
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2">
                <div class="doctor-links">
                  <div class="doctor-item"
                    (click)="chooseTeam('Dr Ashraf','Resident Doctor','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam', '../../../assets/images/new/team/dr-ashraf.png')"
                    [class.doctor-item-active]="selectedTeamName == 'Dr Ashraf'"
                  >
                    <div class="doctor-icon">
                      <img src="../../../assets/images/home/medical-doctor-icon.png">
                    </div>
                    <div class="doctor-info">
                      <div class="doctor-name">
                        Dr Ashraf
                      </div>
                      <div class="doctor-position">
                        Resident Doctor
                      </div>
                    </div>
                    <div class="doctor-arrow">
                      <span class="chevron down"></span>
                    </div>
  
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2">
                <div class="doctor-links">
                  <div class="doctor-item"
                    (click)="chooseTeam('Mogan Ram','Chief Pharmacist','Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate, totam', '../../../assets/images/new/team/morgan-ram.png')"
                    [class.doctor-item-active]="selectedTeamName == 'Mogan Ram'"
                  >
                    <div class="doctor-icon">
                      <img src="../../../assets/images/home/chief-pharmacist-icon.png">
                    </div>
                    <div class="doctor-info">
                      <div class="doctor-name">
                        Mogan Ram
                      </div>
                      <div class="doctor-position">
                        Chief Pharmacist
                      </div>
                    </div>
                    <div class="doctor-arrow">
                      <span class="chevron down"></span>
                    </div>
  
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2">
                <div class="doctor-links">
                  <div class="doctor-item"
                    (click)="chooseTeam('Jeanne Wong','Nutritionist','She is specialized in providing individualized nutrition consultation', '../../../assets/images/new/team/jeanne.png')"
                    [class.doctor-item-active]="selectedTeamName == 'Jeanne Wong'"
                  >
                    <div class="doctor-icon">
                      <img src="../../../assets/images/home/senior-dietitian-icon.png">
                    </div>
                    <div class="doctor-info">
                      <div class="doctor-name">
                        Jeanne Wong
                      </div>
                      <div class="doctor-position">
                        Nutritionist
                      </div>
                    </div>
                    <div class="doctor-arrow">
                      <span class="chevron down"></span>
                    </div>
  
                  </div>
                </div>
              </div>
            </div>
            
            <div class="team-info mt-3">
              <h5 class="doctor-name">{{ selectedTeamName }} | {{ selectedTeamJob }}</h5>
              <!-- <h6 class="doctor-description">{{ selectedTeamDescription }}</h6> -->
              <button class="btn btn-learn-more mt-3" routerLink="/our-team">
                <h6 class="text">Learn More</h6>
                <div class="arrow-icon mt-1">
                  <span class="chevron down"></span>
                </div>
              </button>
            </div>
  
          </div>
          <div class="col-12 col-lg-6">
            <div class="our-team-image">
              <img class="person" src="{{ selectedImage }}" alt="person"
                [class.morgan-image]="selectedTeamName == 'Mogan Ram'"
              >
              <img class="person-bg" src="../../../assets/images/new/about-us/person-bg.png" alt="bg">
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section-core-values p-5">
      <h2 class="title text-center">Our Core Values</h2>

      <div class="col-12 col-lg-8 mx-auto row mt-5">
        <div class="col-12 col-lg-6 d-flex justify-content-center mb-5">
          <div class="card border-0">
            <h4 class="card-title text-center">
              <span class="fw-bold">N</span>urturing
              <img class="icon icon-left" src="../../../assets/images/new/about-us/nurturing-icon.png" alt="icon">
            </h4>
            <h6 class="card-subtitle text-center">We embrace a nurturing approach in all our services and relationships, caring for our clients with compassion and empathy.</h6>
          </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center mb-5">
          <div class="card border-0">
            <h4 class="card-title text-center">
              <span class="fw-bold">A</span>ccessible
              <img class="icon icon-right" src="../../../assets/images/new/about-us/accessible-icon.png" alt="icon">
            </h4>
            <h6 class="card-subtitle text-center">We believe in making quality healthcare accessible to all, offering convenient and affordable solutions for optimal health and well-being.</h6>
          </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center mb-5">
          <div class="card border-0">
            <h4 class="card-title text-center">
              <span class="fw-bold">D</span>iversity
              <img class="icon icon-left" src="../../../assets/images/new/about-us/diversity-icon.png" alt="icon">
            </h4>
            <h6 class="card-subtitle text-center">We celebrate diversity and embrace inclusiveness, recognising and respecting the unique needs and differences of each clients</h6>
          </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center mb-5">
          <div class="card border-0">
            <h4 class="card-title text-center">
              <span class="fw-bold">I</span>nnovative
              <img class="icon icon-right" src="../../../assets/images/new/about-us/innovative-icon.png" alt="icon">
            </h4>
            <h6 class="card-subtitle text-center">We embrace innovation, staying at the forefront of advancement in healthcare and aesthetics to deliver the best possible outcomes for our clients.</h6>
          </div>
        </div>
      </div>
    </div>

    <!-- hide -->
    <div class="section-value px-50 bg-gray d-none">
      <div class="container">
          <div class="row">
              <div class="col-md-4">
                <p class="text_blue_1">OUR CORE VALUES</p>
              </div>
              <div class="col-md-8">
                <div class="row gx-5 row-cols-1 row-cols-md-2">
                  <div class="col mb-5 h-100">
                     <div class="feature bg-blue-primary bg-gradient text-white rounded-3 mb-3"><i class="fa-solid fa-n"></i></div>
                     <h5 class="fw-600">Nurturing</h5>
                     <p class="mb-0">We embrace a nurturing approach in all our services and relationships, caring for our clients with compassion and empathy.</p>
                  </div>
                  <div class="col mb-5 h-100">
                     <div class="feature bg-blue-primary bg-gradient text-white rounded-3 mb-3"><i class="fa-solid fa-a"></i></div>
                     <h5 class="fw-600">Accessible</h5>
                     <p class="mb-0">We believe in making quality healthcare accessible to all, offering convenient and affordable solutions for optimal health and well-being.</p>
                  </div>
                  <div class="col mb-5 mb-md-0 h-100">
                     <div class="feature bg-blue-primary bg-gradient text-white rounded-3 mb-3"><i class="fa-solid fa-d"></i></div>
                     <h5 class="fw-600">Diversity</h5>
                     <p class="mb-0">We celebrate diversity and embrace inclusiveness, recognising and respecting the unique needs and differences of each clients</p>
                  </div>
                  <div class="col h-100">
                     <div class="feature bg-blue-primary bg-gradient text-white rounded-3 mb-3"><i class="fa-solid fa-i"></i></div>
                     <h5 class="fw-600">Innovative</h5>
                     <p class="mb-0">We embrace innovation, staying at the forefront of advancement in healthcare and aesthetics to deliver the best possible outcomes for our clients.</p>
                  </div>
               </div>
              </div>
          </div>

      </div>

  </div>

  <!-- hide -->
    <div class="section-doctor h-100 relative d-none">
        <div class="container px-5">
          <div class="row align-items-center mb-4">
            <div class="col-md-8 col-12">
              <div class="title-area">
                <p class="text_blue_1">our team</p>
                <h1 class="title fw_600 text-dark mt-3">
                  Meet Our Doctors 
                </h1>
                <h6 class="fw_400 mt-3 desc">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde nisi placeat quisquam expedita perferendis. Minus placeat suscipit, ducimus, corporis illo, cupiditate est obcaecati non ipsum reiciendis ipsa veritatis quae dolorem!            </h6>
                  
              </div>
            </div>
            <div class="col-md-4 col-12 d-flex justify-content-end">
              <div class="control justify-content-center py-2">
                <div class="box">
                  <button class="slider-btn prev doctor-prev">
                    <span class="chevron left mx-3"></span>
                  </button>
                  <button class="slider-btn next doctor-next">
                    <span class="chevron right mx-3"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
    
          <div class="doctor-slider">
            <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(0)">
              <div class="team-inner">
                <div class="team-photo">
                  <img src="../../../assets/images/home/Dr-Amos.png">
                </div>
                <div class="team-info mt-3">
                  <div class="team-name">Dr Amos Ng</div>
                  <div class="team-post">Chief Medical Doctor</div>
                </div>
              </div>
              
            </div>
            <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(1)">
              <div class="team-inner">
                <div class="team-photo">
                  <img src="../../../assets/images/home/Dr-Edwina.png">
                </div>
                <div class="team-info mt-3">
                  <div class="team-name">Dr Edwina Tong</div>
                  <div class="team-post">Medical Doctor</div>
                </div>
              </div>
              
            </div>
            <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(2)">
              <div class="team-inner">
                <div class="team-photo">
                  <img src="../../../assets/images/home/Dr-Luqman.png">
                </div>
                <div class="team-info mt-3">
                  <div class="team-name">Dr Luqman</div>
                  <div class="team-post">Medical Doctor</div>
                </div>
              </div>
              
            </div>
            <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(3)">
              <div class="team-inner">
                <div class="team-photo">
                  <img src="../../../assets/images/home/Dr-Ashraf.png">
                </div>
                <div class="team-info mt-3">
                  <div class="team-name">Dr Ashraf</div>
                  <div class="team-post">Medical Doctor</div>
                </div>
              </div>
              
            </div>
            <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(4)">
              <div class="team-inner">
                <div class="team-photo">
                  <img src="../../../assets/images/home/Ashlee.png">
                </div>
                <div class="team-info mt-3">
                  <div class="team-name">Jeanne Wong</div>
                  <div class="team-post">Nutritionist</div>
                </div>
              </div>
              
            </div>
            <div class="team-item" routerLink="/doctor-info" (click)="setDoctorData(5)">
              <div class="team-inner">
                <div class="team-photo">
                  <img src="../../../assets/images/home/Mogan.png">
                </div>
                <div class="team-info mt-3">
                  <div class="team-name">Mogan Ram</div>
                  <div class="team-post">Chief Pharmacist</div>
                </div>
              </div>
              
            </div>
            <!-- <div *ngFor="let data of sortOptions">
                <div  class="card card-white card-doctor">
                  <div class="nav-link" routerLink="/our-team" (click)="changeTeam(data.image,data.name,data.title,data.desc,data.biography)">
                    <div class="img-doc">
                      <img class="img-fluid w-50" src="{{data.image}}" alt="">
                    </div>
                    <h5 class="name text-center mt-4">{{data.name}}</h5>
                    <h6 class="desc fw_400 text-center mt-3">{{data.title}}<br>
                      </h6>
                  </div>
                </div>
              </div> -->
              
            </div>
          
            
        </div>
    </div>

    <!-- <div class="team-section py-5 bg-gray">
        <div class="container p-5">
            <h1 class="text-center fw-700">Our Team</h1>
            <p class="text-center">Dedicated to quality and your success</p>

            <div class="row mt-5">
                <div class="col-md-3">
                    <div class="text-center">
                        <img class="img-round mb-4" src="../../../assets/images/about-us/ceo.png" >
                        <h5 class="fw-600">Ibbie Eckart</h5>
                        <div class="font-italic text-gray">Founder & CEO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="text-center">
                        <img class="img-round mb-4" src="../../../assets/images/about-us/cfo.png" >
                        <h5 class="fw-600">Arden Vasek</h5>
                        <div class="font-italic text-gray">CFO</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="text-center">
                        <img class="img-round mb-4" src="../../../assets/images/about-us/operation-manager.png" >
                        <h5 class="fw-600">Toribio Nerthus</h5>
                        <div class="font-italic text-gray">Operations Manager</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="text-center">
                        <img class="img-round mb-4" src="../../../assets/images/about-us/cto.png" >
                        <h5 class="fw-600">Malvina Cilla</h5>
                        <div class="font-italic text-gray">CTO</div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <app-contact></app-contact> -->
    
    
</div>
