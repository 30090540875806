import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-her',
  templateUrl: './for-her.component.html',
  styleUrls: ['./for-her.component.scss']
})
export class ForHerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
