<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Sexual Health For Him</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Sexual Health For Him
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/sexual-health.jpg"
          />
        </div>
        <div class="col-md-6">
          <div class="pl-5">
            <h1 class="fw-700 mb-3 text-black">Sexual Health</h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              Sexuality plays a vital role in shaping one's overall well-being
              and happiness. To foster a healthy sexual relationship, it is
              crucial to address any potential sexual health issues that may
              arise. By effectively treating these concerns, individuals can
              experience a profound sense of security, confidence, and
              fulfillment within their intimate relationships.
              <br /><br />
              At our facility, we offer a comprehensive range of services
              tailored to support your sexual health needs. From screening and
              prevention to treatments, our expertise is dedicated to helping
              you maintain a fulfilling and satisfying sexual life. Place your
              trust in us as we strive to provide the highest quality care."
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="history-section py-5">
    <div class="container p-5">
      <div class="row">
        <div class="col-md-6">
          <div class="pr-0">
            <h5 class="fw-700 mb-3 text-black font-berlingske">FOR HIM</h5>
            <h1 class="fw-700 mb-3 text-black">
              Sexually Transmitted Infections (STIs)
            </h1>
            <p class="fs-6 fw-light text-muted mb-0 text-justify desc">
              Sexuality plays a vital role in shaping one's overall well-being
              and happiness. To foster a healthy sexual relationship, it is
              crucial to address any potential sexual health issues that may
              arise. By effectively treating these concerns, individuals can
              experience a profound sense of security, confidence, and
              fulfillment within their intimate relationships.
              <br /><br />
              At our facility, we offer a comprehensive range of services
              tailored to support your sexual health needs. From screening and
              prevention to treatments, our expertise is dedicated to helping
              you maintain a fulfilling and satisfying sexual life. Place your
              trust in us as we strive to provide the highest quality care."
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <img
            class="img-fluid mb-5 mb-lg-0 nadi-border"
            src="../../../assets/images/service/man.jpg"
          />
        </div>
      </div>

      <div class="title-area">
        <h4 class="title fw_600 text-dark mt-5 font-berlingske">
          Enhance Your Body Awareness Through Screening
        </h4>
        <h6 class="fw_400 mt-3 desc">
          STIs Screening Options:
          <ul>
            <li style="list-style: auto">Chlamydia</li>
            <li style="list-style: auto">Gonorrhea</li>
            <li style="list-style: auto">HIV</li>
            <li style="list-style: auto">Herpatitis B and C</li>
            <li style="list-style: auto">Herpes</li>
          </ul>
          Screening methods may include blood tests, urine tests, and swab
          tests, depending on the type of infection.
        </h6>

        <h4 class="title fw_600 text-dark mt-5 font-berlingske">
          Pre-Exposure Prophylaxis (PrEP)
        </h4>
        <h6 class="fw_400 mt-3 desc">
          <ul class="pl-3">
            <li style="list-style: none">✔ Preventive medication for HIV</li>
            <li style="list-style: none">
              ✔ Works by blocking the virus, reducing the likelihood of an
              individual becoming infected with HIV
            </li>
            <li style="list-style: none">
              ✔ Aims to reduce the risk of HIV infection
            </li>
            <li style="list-style: none">✔ Taken on daily basic</li>
            <li style="list-style: none">
              ✔ Suitable for individuals engaging in unprotected sex, sharing
              needles, having multiple partners, or in a sexual relationship
              with a HIV-positive partner
            </li>
          </ul>
        </h6>

        <h4 class="title fw_600 text-dark mt-5 font-berlingske">
          Revitalize Your Sexuality: A Comprehensive Erectile Dysfunction (ED)
          Solution
        </h4>
        <h6 class="fw_400 mt-3 desc">
          Erectile Dysfunction (ED) can be a challenging and embarrassing issue
          for many men, but there is assistance available. At NADI, our team of
          sexual health expert doctors specializes in designing effective
          treatment programs to help men overcome ED and regain their
          confidence. Our program utilizes a safe and non-invasive method that
          improves blood flow to the penis, offering highly effective results in
          treating ED symptoms and restoring sexual function.
          <ul class="pl-3">
            <li style="list-style: none">✔ Improved blood flow</li>
            <li style="list-style: none">✔ Increased sexual desire</li>
            <li style="list-style: none">✔ Increased confidence</li>
            <li style="list-style: none">✔ Improved sexual performance</li>
          </ul>
        </h6>
      </div>
    </div>
  </div>
</div>
