<section class="wrapper-team">
  <header class="py-0 inner-header">
   <div class="header-overlay"></div>
   <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
         <div class="col-lg-12">
            <div class="text-center">
               <h1 class="fw_500">Our Team</h1>
               <p class="fw-normal"><a href="index.html">Home</a> / Our Team</p>
            </div>
         </div>
      </div>
   </div>
   </header>

   <div class="section-banner mx-3 mt-5 hide-md">
      <div class="px-5 mb-4 h-100 info">
         <div class="row h-100">
            <div class="col-lg-6 d-flex align-items-center">
               <div class="">
                  <h1 class="fw-700">
                     Meet our Team
                  </h1>
                  <p class="">
                     A team of highly skilled healthcare professionals, including experienced medical doctors, aesthetic experts, seasoned dietitian and pharmacist, work together to provide exceptional, evidence-based care to clients. Through close collaboration, cooperation, and effective communication, the team ensures a continuous and patient-centered approach to care, which eases clients' health conditions and promotes their overall well-being.
                  </p>
               </div>
            </div>
            <div class="col-md-6">
               <!-- <img class="img-fluid" src="../../../assets/images/clinic.jpg" alt=""> -->
            </div>
         </div>
      </div>
   </div>

   <div class="banner-top-tablet mx-3 mt-5">
      <img class="w-100" src="../../../assets/images/new/team/team-banner.png" alt="">
      <div class="mt-4">
         <h1 class="fw-700">
            Meet our Team
         </h1>
         <p class="">
            A team of highly skilled healthcare professionals, including experienced medical doctors, aesthetic experts, seasoned dietitian and pharmacist, work together to provide exceptional, evidence-based care to clients. Through close collaboration, cooperation, and effective communication, the team ensures a continuous and patient-centered approach to care, which eases clients' health conditions and promotes their overall well-being.
         </p>
      </div>
   </div>

   <div class="section-team">

      <img src="../../../assets/images/new/team/blue-a.png" alt="icon" class="blue-a-left">
      <img src="../../../assets/images/new/team/blue-a.png" alt="icon" class="blue-a-right">

      <div class="container mx-auto">
         <div class="row col-12 col-lg-11 mx-auto">

            <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
               <div class="card-team" (click)="flipCard(0)">
                  <div class="image-box" [class.flip-card]="flip[0]">
                     <img class="dr-edwina" src="../../../assets/images/new/team/dr-edwina.png" alt="doctor">
                     <!-- blue info -->
                     <div class="card-info p-3">
                        <img src="../../../assets/images/home/medical-doctor-icon.png" alt="icon">
                        <p class="text-center">
                           Dr. Edwina is a graduate of First Moscow State University. 
                           She has experience in various medical disciplines at the district 
                           hospital in Temerloh, Pahang, and completed three years of internal 
                           medicine training at Hospital Kuala Lumpur. She pursued further 
                           studies in internal medicine, completing part 2 written of MRCP, 
                           and then shifted her focus to functional medicine. Dr. Edwina has a 
                           special interest in internal and functional medicine.
                        </p>
                     </div>
                  </div>
                  <div class="name-box mt-4">
                     <h5 class="name text-center">Dr Edwina Tong</h5>
                     <h6 class="position text-center">Resident Doctor</h6>
                  </div>
               </div>
            </div>
   
            <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
               <div class="card-team card-up" (click)="flipCard(1)">
                  <div class="image-box" [class.flip-card]="flip[1]">
                     <img class="dr-gopee" src="../../../assets/images/new/team/dr-gopee.png" alt="doctor">
                     <!-- blue info -->
                     <div class="card-info p-3">
                        <img src="../../../assets/images/home/medical-doctor-icon.png" alt="icon">
                        <p class="text-center">
                           Dr. Gopee is a graduate of Melaka Manipal Medical College with over 15 
                           years of experience in medicine. He has been involved in setting up and 
                           managing numerous clinics nationwide. Driven by a passion for geroscience, 
                           his focus is on Anti-Aging and Rehabilitative Medicine. He emphasises 
                           customisation and an out-of-the-box approach in the healthcare industry. 
                           Dr. Gopee has a special interest in Regenerative and Anti-Aging Medicine, 
                           Preventive Medicine, Holistic Medicine, and Medicolegal Practice.
                        </p>
                     </div>
                  </div>
                  <div class="name-box mt-4">
                     <h5 class="name text-center">Dr Gopee</h5>
                     <h6 class="position text-center">Chief Operating Officer (COO)</h6>
                  </div>
               </div>
            </div>
   
            <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
               <div class="card-team" (click)="flipCard(2)">
                  <div class="image-box" [class.flip-card]="flip[2]">
                     <img class="dr-amos" src="../../../assets/images/new/team/dr-amos.png" alt="doctor">
                     <!-- blue info -->
                     <div class="card-info p-3">
                        <img src="../../../assets/images/home/medical-doctor-icon.png" alt="icon">
                        <p class="text-center">
                           Dr. Amos is a graduate of First Moscow State Medical University.
                           He has experience in various medical disciplines at Hospital Melaka 
                           and have served in Internal Medicine and Rehabilitation Medicine at 
                           Hospital Tengku Ampuan Rahimah. Driven by a passion for holistic healthcare, 
                           he pursued additional certifications in Chinese Traditional Medicine from 
                           Beijing and attained certification in Men's and Women's Health from Australia. 
                           His special interests include Medicine Rehabilitation, Advanced Wound Care, 
                           and Men's and Women's Health.
                        </p>
                     </div>
                  </div>
                  <div class="name-box mt-4">
                     <h5 class="name text-center">Dr Amos Ng</h5>
                     <h6 class="position text-center">Resident Doctor</h6>
                  </div>
               </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
               <div class="card-team" (click)="flipCard(3)">
                  <div class="image-box" [class.flip-card]="flip[3]">
                     <img class="dr-ashraf" src="../../../assets/images/new/team/dr-ashraf.png" alt="doctor">
                     <!-- blue info -->
                     <div class="card-info p-3">
                        <img src="../../../assets/images/home/medical-doctor-icon.png" alt="icon">
                        <p class="text-center">
                           Dr. Ashraf graduated from the University Malaysia Sabah with a degree in 
                           Medical Doctor in 2017. He completed their compulsory service at Hospital 
                           Canselor Tuanku Muhriz and gained experience in various medical disciplines 
                           at Klinik Kesihatan Labuan. Dr. Ashraf learned essential skills in attending 
                           to patients, including those with special needs. He emphasises the 
                           importance of peace of mind, heart, and soul for overall well-being 
                           and his special interest lies in Family Medicine and Infectious Disease.
                        </p>
                     </div>
                  </div>
                  <div class="name-box mt-4">
                     <h5 class="name text-center">Dr Ashraf</h5>
                     <h6 class="position text-center">Resident Doctor</h6>
                  </div>
               </div>
            </div>
   
            <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
               <div class="card-team card-up" (click)="flipCard(4)">
                  <div class="image-box" [class.flip-card]="flip[4]">
                     <img class="jeanne" src="../../../assets/images/new/team/jeanne.png" alt="doctor">
                     <!-- blue info -->
                     <div class="card-info p-3">
                        <img src="../../../assets/images/new/team/dietitian-icon.png" alt="icon">
                        <p class="text-center">
                           Jeanne graduated from Universiti Kebangsaan Malaysia in 2020 with a degree 
                           in Nutritional Science. She empowers individuals to make informed dietary 
                           choices and adopt healthier lifestyles in managing diabetes, hypertension, 
                           hyperlipidemia, weight management, etc. Jeanne specialises in developing 
                           personalized nutrition plans, conducting assessments, and providing 
                           evidence-based recommendations for addressing specific health concerns. 
                           Committed to staying up-to-date with the latest research and trends, 
                           she aims to promote optimal nutrition and long-term health outcomes.
                        </p>
                     </div>
                  </div>
                  <div class="name-box mt-4">
                     <h5 class="name text-center">Jeanne Wong</h5>
                     <h6 class="position text-center">Nutritionist</h6>
                  </div>
               </div>
            </div>
   
            <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
               <div class="card-team" (click)="flipCard(5)">
                  <div class="image-box" [class.flip-card]="flip[5]">
                     <img class="morgan" src="../../../assets/images/new/team/morgan-ram.png" alt="doctor">
                     <!-- blue info -->
                     <div class="card-info p-3">
                        <img src="../../../assets/images/home/chief-pharmacist-icon.png" alt="icon">
                        <p class="text-center">
                           He completed his pharmacy degree with honors at IMU in 2011. With over 12 
                           years of experience as a Hospital Pharmacist and Drug Enforcement Officer 
                           (DEO), he has extensive expertise in clinical pharmacy, licensing, product 
                           registration, online operations involving medicines and supplements, and 
                           other enforcement-related knowledge. He served as a Provisionally Registered 
                           Pharmacist (PRP) at University Malaya Medical Centre (UMMC) and as a Senior 
                           Pharmacist at Hospital Putrajaya. He is a fully Registered Pharmacist (FRP) 
                           at Pharmacy Enforcement Division HQ and has worked at the state level in the 
                           Health Department of WPKL and Putrajaya, focusing on enforcement.
                        </p>
                     </div>
                  </div>
                  <div class="name-box mt-4">
                     <h5 class="name text-center">Mogan Ram</h5>
                     <h6 class="position text-center">Chief Pharmacist</h6>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>

  <!-- <div class="section-doctor h-100 relative">
   <div class="container">
     <div class="row align-items-center mb-4">
       <div class="col-md-8 col-12">
         <div class="title-area">
           <h1 class="title fw_600 text-dark mt-3">
            Meet our Team
           </h1>
           <h6 class="fw_400 mt-3 desc">
            A team of highly skilled healthcare professionals, including experienced medical doctors, aesthetic experts, seasoned dietitian and pharmacist, work together to provide exceptional, evidence-based care to clients. Through close collaboration, cooperation, and effective communication, the team ensures a continuous and patient-centered approach to care, which eases clients' health conditions and promotes their overall well-being.
            </h6>
         </div>
       </div>
       
     </div>
     <div class="doctor-wrapper row">
      <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(0)">
         <div class="team-inner">
            <div class="team-photo">
               <img src="../../../assets/images/home/Dr-Amos.png">
            </div>
            <div class="team-info mt-3">
               <div class="team-name">Dr Amos Ng</div>
               <div class="team-post">Chief Medical Doctor</div>
            </div>
         </div>
      </div>
      <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(1)">
         <div class="team-inner">
            <div class="team-photo">
               <img src="../../../assets/images/home/Dr-Edwina.png">
            </div>
            <div class="team-info mt-3">
               <div class="team-name">Dr Edwina Tong</div>
               <div class="team-post">Medical Doctor</div>
            </div>
         </div>
      </div>
      <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(2)">
         <div class="team-inner">
            <div class="team-photo">
               <img src="../../../assets/images/home/Dr-Luqman.png">
            </div>
            <div class="team-info mt-3">
               <div class="team-name">Dr Luqman</div>
               <div class="team-post">Medical Doctor</div>
            </div>
         </div>
      </div>
      
      </div>
      <div class="doctor-wrapper row">
      <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(4)">
         <div class="team-inner">
            <div class="team-photo">
               <img src="../../../assets/images/home/Dr-Ashraf.png">
            </div>
            <div class="team-info mt-3">
               <div class="team-name">Dr Ashraf</div>
               <div class="team-post">Medical Doctor</div>
            </div>
         </div>
      </div>
      <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(5)">
         <div class="team-inner">
            <div class="team-photo">
               <img src="../../../assets/images/home/Ashlee.png">
            </div>
            <div class="team-info mt-3">
               <div class="team-name">Ashlee Chong</div>
               <div class="team-post">Senior Dietitian</div>
            </div>
         </div>
      </div>
      <div class="col-12 col-md-4 mt-3 team-item" routerLink="/doctor-info" (click)="setDoctorData(6)">
         <div class="team-inner">
            <div class="team-photo">
               <img src="../../../assets/images/home/Mogan.png">
            </div>
            <div class="team-info mt-3">
               <div class="team-name">Mogan Ram</div>
               <div class="team-post">Chief Pharmacist</div>
            </div>
         </div>
      </div>
      </div>
   </div>
   </div> -->
    <!-- <div class="bio-doctor h-100 py-5 relative">
        <div class="container px-50" data-aos="fade-up" data-aos-duration="800" data-aos-delay="0">
          <div class="row h-100">
            <div class="col-12 col-md-4 col-sm-12 relative">
              <div class="bg-blue-primary shadow-sm overflow-hidden rounded-3 mb-3">
                  <div class="text-center py-2">
                     <img class="card-img-top" src="{{img}}" alt="...">
                  </div>
                <div class="p-4">
                   <div>
                      <h5 class="fw-bold text-white mb-1">{{name}}</h5>
                      <p class="mb-0 text-white-50">{{title}}</p>
                   </div>
                </div>
             </div>
            </div>
            <div class="col-12 col-md-8 col-sm-12 align-items-center">
              <div>
                <h6 class="text_blue_1 mt-5">BIOGRAPHY</h6>
                <h1 class="fw_600 title fw-400 text-dark mt-3">
                  {{name}}
                </h1>
                <h6 class="fw_400 mt-3 mb-4 text-justify">
                  {{biography}}
                </h6>
                <h3 class="py-4">Education</h3>
                <div class="table-responsive bg-white rounded-3 shadow-sm p-3">
                  <table class="table table-borderless m-0">
                     <thead>
                        <tr>
                           <th class="col-3">Year</th>
                           <th class="col-3">Degree</th>
                           <th>Institute</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td class="text-muted">2006</td>
                           <td class="text-muted">MBBS, M.D</td>
                           <td class="text-muted">University of London</td>
                        </tr>
                        <tr>
                           <td class="text-muted">2010</td>
                           <td class="text-muted">M.D. of Medicine</td>
                           <td class="text-muted">Australia Medical College</td>
                        </tr>
                     </tbody>
                  </table>
                </div>
                <h3 class="py-4">Experienced</h3>
                <div class="table-responsive bg-white rounded-3 shadow-sm p-3">
                  <table class="table table-borderless m-0">
                     <thead>
                        <tr>
                           <th class="col-3">Year</th>
                           <th class="col-3">Department</th>
                           <th>Position</th>
                           <th>Hospital</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td class="text-muted">2007 - 2008</td>
                           <td class="text-muted">MBBS, M.D</td>
                           <td class="text-muted">Senior Prof.</td>
                           <td class="text-muted">ProHealth Medical Clinic</td>
                        </tr>
                        <tr>
                           <td class="text-muted">2010 - 2018</td>
                           <td class="text-muted">M.D. of Medicine</td>
                           <td class="text-muted">Associate Prof.</td>
                           <td class="text-muted">Australia Medical College</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
              </div>
              
            </div>
          </div>
        </div>
    </div> -->
   
    <!-- <app-contact></app-contact> -->
</section>
