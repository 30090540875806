import { Component, OnInit } from '@angular/core';
declare var $: any;
import { doctorData } from '../doctor-info/doctor';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  img: any;
  name: any;
  title: any;
  desc: any;
  biography: any;
  biography2: any;
  biography3: any;
  special_interest: any;
  language: any;
  degree: any;
  year: any;
  institute: any;


  sortOptions = doctorData;

  flip = [false, false, false, false, false, false];

  constructor() { }

  ngOnInit(): void {
    this.initTeamSlider();

    setTimeout(() => {
      this.img = sessionStorage.getItem('img');
      this.name = sessionStorage.getItem('name');
      this.title = sessionStorage.getItem('title');
      this.desc = sessionStorage.getItem('desc');
      this.biography = sessionStorage.getItem('biography');
      this.biography2 = sessionStorage.getItem('biography2');
      this.biography3 = sessionStorage.getItem('biography3');
      this.special_interest = sessionStorage.getItem('special_interest');
      this.language = sessionStorage.getItem('language');
      this.year = sessionStorage.getItem('year');
      this.degree = sessionStorage.getItem('degree');
      this.institute = sessionStorage.getItem('institute');
    }, 500);
  }

  initTeamSlider() {
    $('.doctor-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.doctor-prev'),
      nextArrow: $('.doctor-next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }

  setDoctorData(i: any) {
    sessionStorage.setItem('img', this.sortOptions[i].image);
    sessionStorage.setItem('name', this.sortOptions[i].name);
    sessionStorage.setItem('title', this.sortOptions[i].title);
    sessionStorage.setItem('desc', this.sortOptions[i].desc);
    sessionStorage.setItem('biography', this.sortOptions[i].biography);
    sessionStorage.setItem('biography2', this.sortOptions[i].biography2);
    sessionStorage.setItem('biography3', this.sortOptions[i].biography3);
    sessionStorage.setItem('special_interest', this.sortOptions[i].special_interest);
    sessionStorage.setItem('language', this.sortOptions[i].language);
    sessionStorage.setItem('year', this.sortOptions[i].year);
    sessionStorage.setItem('degree', this.sortOptions[i].degree);
    sessionStorage.setItem('institute', this.sortOptions[i].institute);
  }

  flipCard(index: number) {
    if(window.innerWidth < 900) {
      for(let i=0; i<this.flip.length; i++) {
        if(i != index) {
          this.flip[i] = false;
        }
      }
      this.flip[index] = !this.flip[index];
    }
  }
}
