<div class="wrapper-service">
  <header class="py-0 inner-header">
    <div class="header-overlay"></div>
    <div class="container py-4 px-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 class="fw_500">Nutrition & Diet Consultation</h1>
            <p class="fw-normal">
              <a href="index.html">Services</a> / Nutrition & Diet Consultation
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="section-main mx-3 mt-5 hide-sm">
    <div class="px-5 mb-4 h-100">
      <div class="row h-100 d-flex align-items-center">
        <div class="col-md-6 info">
          <div class="">
            <h1 class="fw-700 mt-5">
              Taking Nutrition <br> Beyond Ordinary
            </h1>
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </div>

  <div class="banner-top-mobile mx-3 mt-5 mb-5">
    <img class="w-100" src="../../../assets/images/nutrition/nutrition-banner.png" alt="">
    <div class="mt-4">
      <h1 class="fw-700">
        Taking Nutrition Beyond Ordinary
      </h1>
    </div>
  </div>
  
  <div class="container">
    <p class="text-center col-12 col-lg-8 mx-auto mt-3">
      Nadi's dietitians and nutritionists provides personalised nutrition and diet consultations to help clients achieve their health and wellness goals. The consultations typically involve:
    </p>

    <div class="row py-5">
      <div class="col-12 col-md-6">
        <img
          class="img-fluid mb-5 mb-lg-0 nadi-border"
          src="../../../assets/images/nutrition/food.png"
        />
      </div>
      <div class="col-12 col-md-6">
        <div>
          <div class="card card-info text-left mb-3">
            A thorough assessment of the client's health and medical history, lifestyle, and dietary habits.
          </div>
          <div class="card card-info text-left mb-3">
            Identification of nutritional deficiencies or imbalances, and recommendations for appropriate dietary modifications or supplements.
          </div>
          <div class="card card-info text-left mb-3">
            Development of personalised meal plans and dietary recommendations based on the client's goals and needs.
          </div>
          <div class="card card-info text-left mb-3">
            Ongoing support and follow-up to monitor progress and adjust the plan as needed.
          </div>

          <p class="description">
            Our dietitians and nutritionists may also provide education on healthy eating habits, food choices, and meal preparation techniques to help clients make sustainable lifestyle changes. Whether the client's goal is weight loss, improved gut health, managing a specific medical condition, or simply improving overall health and well-being, we work with each client to develop a plan that is tailored to your unique needs and preferences.
          </p>
          <div>
            <div class="button-action text-center mt-4">
              <a class="btn btn-primary px-3 py-2" href="https://wa.me/+60126468392" target="_blank"> 
                <i class="bi bi-calendar3 mr-1"></i> Book Appointment
              </a>
            </div>
            <h6 class="text-center mt-3 button-info">Book your appointment with a dietitian/nutritionist now.</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
