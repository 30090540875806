import { Component, OnInit } from '@angular/core';
declare var $: any;
import { doctorData } from '../doctor-info/doctor';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  sortOptions = doctorData;

  selectedTeamName = "Dr Gopee";
  selectedTeamJob = "Chief Operating Officer";
  selectedTeamDescription = "Chief Operating Officer";
  selectedImage = "../../../assets/images/new/team/dr-gopee.png"

  constructor() { }

  ngOnInit(): void {
    this.initTeamSlider();
  }

  initTeamSlider() {
    $('.doctor-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.doctor-prev'),
      nextArrow: $('.doctor-next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            centerPadding: '8%',
          }
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    });
  }

  setDoctorData(i: any) {
    sessionStorage.setItem('img', this.sortOptions[i].image);
    sessionStorage.setItem('name', this.sortOptions[i].name);
    sessionStorage.setItem('title', this.sortOptions[i].title);
    sessionStorage.setItem('desc', this.sortOptions[i].desc);
    sessionStorage.setItem('biography', this.sortOptions[i].biography);
  }

  chooseTeam(name: string, job: string, description: string, image: string) {
    this.selectedTeamName = name;
    this.selectedTeamJob = job;
    this.selectedTeamDescription = description;
    this.selectedImage = image;
  }
}
